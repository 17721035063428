import React from "react";
import "./Footer.css";
import Hola9logo from "../images/logotext.png";
import appstore from "../images/app-store.png";
import googleplay from "../images/google-play.png";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
// react icon import
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail, MdArrowDropDown, MdArrowRightAlt } from "react-icons/md";
// import {  } from "react-icons/md";
// import {  } from "react-icons/md";



// import Bottom from '../Bottom';

const mobfoot = {
  marginTop: "-100px",
};
const deskfoot = {};

export const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer text-white" style={{ background: "rgba(11, 11, 11, 1)" }}>
        <div className="container">
          <div className="footer__top">
            <div style={isMobile ? mobfoot : deskfoot}>

              <div className="row mt-5 row-container">
                <div className="col-3">
                  {/* Logo content */}
                  <div className="footer__logo__content">
                    {/* Your logo */}
                    <img src={Hola9logo} alt="Golo" className="footer__logo headings" />
                    <hr />
                    {/* Address and contact information */}
                    <p className="footer__contact__info">
                      Address Line 1,<br />
                      Address Line 2,<br />
                      District, State,<br />
                      Country, ZipCode<br />
                      <FaPhoneAlt /> +91 12345-67890<br />
                      <MdOutlineEmail /> mail@email.com
                    </p>
                    {/* App download links */}
                    <div className="footer__app__links">
                      <Link
                        title="App Store"
                        to="/updatingSoon"
                        className="banner-apps__download__iphone"
                      >
                        <h5 className="text-white"> See details <MdArrowRightAlt /></h5>
                      </Link>
                    </div>
                  </div>
                </div>



                <div className="col-3">
                  {/* Important links */}
                  <div className="footer__important__links">
                    {/* List of important links */}
                    <h5 className="footer__important__links__title headings">Important Links <i class="fa-solid fa-caret-down"></i></h5>
                    <ul className="footer__important__links__list">
                      <li><Link to="/listingPolicy/">Listing Policy</Link></li>
                      <li><Link to="/copyrightPolicy/">Copyright Policy</Link></li>
                      <li><Link to="/privacyPolicy/">Privacy Policy</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  {/* Services */}
                  <div className="footer__services">
                    {/* List of services */}
                    <h5 className="footer__services__title headings">Services <i class="fa-solid fa-caret-down"></i></h5>
                    <ul className="footer__services__list">
                      <li><Link to="#">Home</Link></li>
                      <li><Link to="#">How It Works</Link></li>
                      <li><Link to="#">Swapping Places</Link></li>
                      <li><Link to="#">Notifications</Link></li>
                      <li><Link to="#">Terms & Condition</Link></li>
                      <li><Link to="#">Help & Support</Link></li>
                      <li><Link to="#">Privacy Policy</Link></li>
                      <li><Link to="#">Career</Link></li>
                      <li><Link to="#">About</Link></li>
                      <li><Link to="/signup/">Signup</Link></li>
                      <li><Link to="/login/">Login</Link></li>
                    </ul>
                  </div>
                </div>

                <div className="col-3">
                  {/* Social media */}
                  <div className="footer__social__media">
                    {/* List of social media links */}
                    <h5 className="footer__social__media__title headings">Social Media <i class="fa-solid fa-caret-down"></i></h5>
                    <ul className="footer__social__media__list">
                      <li><a title="Facebook" target="_blank" href="https://www.facebook.com/Hola9-Technologies-103386172328157">Facebook</a></li>
                      <li><a title="LinkedIn" target="_blank" href="https://www.linkedin.com/company/hola9-classifieds-india-private-limited/mycompany/">LinkedIn</a></li>
                      <li><a title="Instagram" target="_blank" href="https://www.instagram.com/hola9.official/">Instagram</a></li>
                      <li><a title="Twitter(X)" target="_blank" href="https://twitter.com/?lang=en">Twitter(X)</a></li>
                      <li><a title="Reddit" target="_blank" href="https://www.reddit.com/?rdt=52007">Reddit</a></li>
                    </ul>
                  </div>
                </div>
              </div>



              {/* <div
                className="col-lg-3 mt-2  hidden-sm hidden-xs visible-md-block visible-lg-block"
                style={{ paddingTop: "30px" }}
              >
                <aside className="footer__top__nav footer__top__nav--contact">
                  <h3 className="footerFont text-white">
                    hello
                  </h3>

                </aside>
              </div> */}
            </div>
          </div>
          {/* .top-footer */}

          {/* .top-footer */}
        </div>
        {/* .container */}
        <div className="footer__bottom">
          <p className="footer__bottom__copyright ">
            {" "}
            <a className="footer__bottom__copyright" title="Hola 9 " to="/">
              {" "}
              2022 © RotateKey Classifieds India Private Limited
            </a>
            . All rights reserved.
          </p>
        </div>
      </footer>
      {/* <Bottom/> */}

    </>
  );
};

export default Footer;
