import React from 'react'
// import ReplayIcon from '@material-ui/icons/Replay';
import { Replay } from '@mui/icons-material';
// import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@mui/icons-material';
// import StarRateIcon from '@material-ui/icons/StarRate';
import { StarRate } from '@mui/icons-material';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { Favorite } from '@mui/icons-material';
// import FlashOnIcon from '@material-ui/icons/FlashOn';
import { FlashOn } from '@mui/icons-material';
import { IconButton } from '@material-ui/core';
import './SwipeButtons.css'

const SwipeButtons = () => {
    return (
        <div className='swipe__Button'>
            <IconButton className='swipeButton__repeat'>
                {/* <ReplayIcon fontSize='large' /> */}
                <Replay fontSize='large' />
            </IconButton>
            <IconButton className='swipeButton__left'>
                <Close fontSize='large' />
            </IconButton>
            <IconButton className='swipeButton__star'>
                <StarRate fontSize='large' />
            </IconButton>
            <IconButton className='swipeButton__right'>
                <Favorite fontSize='large' />
            </IconButton>
            <IconButton className='swipeButton__flashOn'>
                <FlashOn fontSize='large' />
            </IconButton>
        </div>
    )
}

export default SwipeButtons