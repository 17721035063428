let year = [];
var date = new Date().getFullYear();
for (let i = 1980; i <= date; i++) {
  year.push(i);
}
export const subCategoryInput = {
  Electronics: {
    "Refrigerators - Fridge": {
      "Door Style​": [
        " Freezer On Top ​",
        " Side By side​",
        " Single Door​",
        " Triple Door​",
        "Multi Door​",
      ],
      Condition: [" Brand New​", " Gently Used​", "Heavily used​", "Unboxed​"],
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Samsung​",
        " Sony​",
      ],
      "Capacity​": [
        "Upto 120L​",
        "120-200L​",
        "200-300L​",
        "300-400L​",
        "400L & Above ",
      ],
    },
    "Washing Machines": {
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " Intex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Sony​",
        "Lloyd​",
        "Others",
        "Videocon​",
        "Voltas​",
        "Whirlpool​",
      ],
      "Capacity​": [
        "4 to 6kg",
        "6 to 8kg​",
        "8 to 10kg​",
        "10 to 12kg​",
        "12 to 16kg",
        "16 to 20kg",
        "20 to 24kg",
        "Above 24kg",
      ],
      "Condition​": [" New​", "Good​", "Fair​", "Unboxed​"],
      "Loading​": ["Front Load​", "Top Load​"],
    },
    "Air Coolers​": {
      "Brand Name  ​": [
        " BMQ​",
        " Bajaj​",
        " Blue Star​",
        " Cello​",
        " Hindware​",
        " Hotstar​",
        " Kelvinator  ​",
        "Havells​",
        "Kenstar​",
        "Khaitan​",
        "Kiran​",
        "Kunstocom​",
        "Mccoy​",
        "Orient​",
        "Others",
      ],
      "Condition ​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      " Cooler Capacity​": ["11L-15L​", "16L-20L​", "21L-40L​", "40L & above"],
    },
    "Air Conditioners - AC": {
      "Brand Name​": [
        " Bosch​",
        " Buyerzone​",
        " D Pex​",
        " Intex​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        " Sony​",
        "Lloyd​",
        "Others​",
        "Videocon​",
        "Voltas​",
        "Whirlpool​",
      ],
      "AC Type​": [" Window​", " Split​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Tonnage​": ["1Ton​", "2.5Ton​", "5Ton​", "8Ton​", "8Ton & Above"],
    },
    "Water Heaters - Geysers": {
      "Brand Name ​": [
        " Apson​",
        " Arise​",
        " Bala​",
        " Blue Me​",
        " Havells​",
        " LG ​",
        " Onida​",
        " Panasonic​",
        "Bajaj ​",
        "Easy​",
        "Florex​",
        "Genius​",
        "Happy Home​",
        "Others​",
      ],
      "Heater Type​": [" Instant Water Heater​", " Storage Water Heater​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Heater Capacity​": [
        "11L-15L​",
        "16L-20L​",
        "21L-50L​",
        "50L-100L​",
        "100L & Above",
      ],
    },
    "Laptops Or (Desktops - Computers)": {
      "Brand Name ​": [
        " Hp​",
        " IBM​",
        " Lenovo​",
        " Samsung​",
        " Wipro​",
        " Zenith​",
        "Asus​",
        "Dell​",
        "Others​",
        "Panasonic​",
        "Sharp​",
      ],
      "Product Type​": [" Desktop​", " Laptop​"],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Monitor Or Printers Or Scanners": {
      "Peripheral Type​": [
        " Access Point​",
        " Battery​",
        " CPU​",
        " Cooling pad​",
        " Head Phones​",
        " Headset​",
        " Keyboard​",
        " Mouse​",
        " Network Card​",
        " Others​",
        " Printer​",
        " Ram​",
        " Router​",
        " Scanner​",
        " Speaker​",
        " USB Wireless Adaptor​",
        " USB Drive​",
        " Webcam​",
        "Data Card​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Microwave Ovens​": {
      "Brand Name ​": ["LG​", " Onida​", " Others​", " Panasonic​", "Samsung​"],
      "Oven Type​": [" Convection​", " Grill​", " Solo​"],
      "Condition ​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed​",
      ],
      "Capacity​": ["Upto 20L​", "21L-25L​", "26L-30L​", "31L & Above"],
    },

    "Water Purifiers": {
      "Brand Name​": [
        "Aquaguard",
        "Bala​",
        " Blue Me​",
        " Havells​",
        " LG ​",
        " Others​",
        " Panasonic​",
        " Kent​",
        "Livpure ​",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", " Unboxed"],
    },

    "Mixer - Grinder - Juicer": {
      "Brand Name": [
        " Activa​",
        " Aditi​",
        " Amazing Bullet ​",
        " Ameet​",
        " Anjani​",
        " Apex​",
        " Arise​",
        " Asian​",
        " BEC​",
        " BTL​",
        " Bala​",
        " Blue me ​",
        " Bluesky​",
        " Bonzeal​",
        " Borosil​",
        " Boss​",
        " Butterfly​",
        " Cpex​",
        " Others​",
      ],
      Condition: [" Brand New​", " Gently Used​", " Heavily Used​", " Unboxed"],
    },
    "Induction Cook Tops​": {
      "Brand Name": [
        " Greenchef​",
        " Havells​",
        " Hilex​",
        " Hindware​",
        " Hotstar ​",
        " Impex​",
        " Jaipan​",
        " Jindal​",
        " Kenwood​",
        " Longer​",
        " Nova​",
        " Orbit ​",
        " Oster​",
        " Others​",
        " Ovastar​",
        " Phillips​",
        " Pigeon​",
        "Orbon​",
      ],
      Condition: [" Brand New​", "Gently Used​", "Heavily Used​", "Unboxed"],
    },
    "Gas Stove": {
      "Brand Name": [
        " Apex​",
        " Longer​",
        " Nova​",
        " Orbit ​",
        " Oster​",
        " Others​",
        " Ovastar​",
        " Phillips​",
        " Pigeon​",
        " Prestige​",
        " Quba​",
        " Skyflame​",
        " Surya​",
        " Usha​",
        " Wonder Chef​",
        " Wonder World​",
        "Orbon​",
        "Skyline​",
      ],
      Condition: ["Brand New", "Gently Used​", "Heavily Used"],
    },
    Cameras: {
      "Brand Name ​": [
        " Casio​",
        " Flip ​",
        " Kodak​",
        " Olympus​",
        " Panasonic​",
        "Canon​",
        "Leica​",
        "Nikon​",
        "Others",
        "Samsung​",
        "Sony​",
      ],
      "Product Type​": [
        " Camcorders",
        "Binocular",
        "DigitalSLR​",
        "Film Cameras​",
        "Telescope​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
    "Camera Accessories": {
      "Accessories Type ​": [
        "Camera Bag​",
        "Filter​",
        "Flash​",
        "Lens Cap​",
        "Lens Cleaner​",
        "Memory Card​",
        "Others​",
        "Strap​",
        "Battery Charger​",
        "Lens​",
      ],
      Condtion: [" Brand New​", " Gently Used​", " Heavily Used​", " Unboxed"],
    },
    TV: {
      "Product Type​": ["TV​", "DVD Player​", "Other​"],
      "Condition​": ["Brand New​", "Gently Used​", "Heavily used​", "Unboxed​"],
      "Brand Name ​": [
        "BPL ​",
        "BenQ​",
        "LG​",
        "Onida​",
        "Panasonic​",
        "Phillips​",
        "Samsung​",
        "Sony​",
        "Videocon​",
        "Others",
      ],
      "Screen Size​": [
        "Upto 23​",
        "24-31​",
        "32-39​",
        "40-47​",
        "48-54​",
        "55 & Above​",
      ],
      "Resolution​": [
        "CRT​",
        "Full HD​",
        "HD Ready​",
        "LCD​",
        "LED​",
        "Other​",
        "Plasma​",
        "QLED",
        "Standard definition​",
        "Ultra Hd(4k)​",
        "WXGA​",
      ],
      // "Screen Size​":[" Upto 23​","24-31​","32-39​","40-47​","48-54​","55&Above​"],
      // "Resolution​":["Full HD​","HD Ready​","Standard definition​","Ultra Hd(4k)​","WXGA​"],
      "Display Technology​": [
        "CRT​",
        "LCD​",
        "LED​",
        "Other​",
        "Plasma​",
        "QLED",
      ],
    },
    "Video Games - Consoles": {
      "Brand Name ​": [
        "Nintendo wii​",
        "SonyPSP​",
        "Others​",
        "Microsoft Xbox",
        "Nintendo Game boy",
        "Nokia N-gage​",
        "PC games​",
        "Sony Play Station​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },

    "Music Systems - Home Theatre": {
      "Brand Name": [
        "Canon",
        "Casio​",
        "Flip ​",
        "Kodak​",
        "Leica​",
        "Nikon",
        "Olympus​",
        "Others",
        "Panasonic​",
        "Samsung​",
        "Sony​",
      ],
      "Speaker Type​": [
        "Blueshelf speakers​",
        "Bluetooth Speakers​",
        "Home Theatre systems​",
        "Outdoor Speakers​",
        "Over –Ear Head phones​",
        "Sound bar speakers​",
      ],
      "Condition​": [
        " Brand New​",
        " Gently Used​",
        " Heavily Used​",
        " Unboxed",
      ],
    },
  },

  Cars: {
    Cars: {
      "Brand Name": [
        "Ashok Leyland",
        "Aston Martin",
        "Audi",
        "BMW",
        "Bentley",
        "Bugatti",
        "Caterham",
        "Chevrolet",
        "DC",
        "Daewoo",
        "Datsun",
        "Eicher Polaris",
        "Ferrari",
        "Fiat",
        "Force Motors",
        "Ford",
        "Hindustan Motors",
        "Honda",
        "Hyundai",
        "ICML",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Land Rover",
        "Lexus",
        "MG Motors",
        "Mahindra",
        "Maruti Suzuki",
        "Maserati",
        "Mercedes Benz",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Porsche",
        "Premier",
        "Renault",
        "Rolls Royce",
        "San",
        "Skoda",
        "Ssangyong",
        "Tata",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      "Car Type ": ["New Car", "Used Car"],
      "Fuel Type ": ["Petrol", "Diesel", "CNG", "Electric", "LPG", "Hybrid"],
      "Year of Registration": year.map((ye) => ye.toString()),
      // [
      //   "2000",
      //   "2001",
      //   "2002",
      //   "2003",
      //   "2004",
      //   "2005",
      //   "2006",
      //   "2007",
      //   "2008",
      //   "2009",
      //   "2010",
      //   "2011",
      //   "2012",
      //   "2013",
      //   "2014",
      //   "2015",
      //   "2016",
      //   "2017",
      //   "2018",
      //   "2019",
      //   "2020",
      //   "2021",
      //   "2022",
      //   "2023",
      // ],
      "KM Driven ": [
        "0-5000",
        "5000-6000",
        "6000-8000",
        "8000-10000",
        "10000-13000",
        "13000-15000",
        "15000-18000",
        "18000-22000",
        "22000-25000",
        "25000-30000",
        "30000-35000",
        "35000-40000",
        "40000-45000",
        "45000-50000",
        "50000-55000",
        "55000-60000",
        "60000-65000",
        "65000-70000",
        "70000-75000",
        "75000-80000",
        "80000-85000",
        "85000-90000",
        "90000-95000",
        "95000 & Above",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
      Colour: [
        "Beige",
        "Black",
        "Blue",
        "Brown",
        "Cyan",
        "Gray",
        "Green",
        "Indigo",
        "Lime",
        "Magenta",
        "Maroon",
        "Navy Blue",
        "Olive",
        "Orange",
        "Peach",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "Tan",
        "Teal",
        "Turquoise",
        "Violet",
        "White",
        "Yellow",
      ],
    },

    "Spare Parts - Accessories": {
      "Product Type": [
        "CNG/LPG Kit",
        "Electrical Part",
        "Exterior Part",
        "Interior Accessory",
        "Lubricant",
        "Other Accessory",
        "Product",
        "Tyre/Rubber",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-700EquipmentSecurity00","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
    },
  },

  Bikes: {
    Bikes: {
      "Brand Name": [
        "Aprilia",
        "Ather Energy",
        "BMW",
        "Bajaj",
        "Benelli",
        "Ducati",
        "Harley Davidson",
        "Hero",
        "Honda",
        "Hyosung",
        "Indian",
        "Jawa",
        "KTM",
        "Kawasaki",
        "Kinetic Motors",
        "LML ( Piaggio)",
        "Lambretta",
        "Mahindra",
        "Moto Guzzi",
        "Raft",
        "Royal Enfield",
        "Suzuki",
        "TVS",
        "Triumph",
        "UM",
        "YO Bikes",
        "Yamaha",
        "Yezdi",
      ],
      "Bike Type ": ["New Bike", "Used Bike"],
      "Fuel Type ": ["Petrol", "Diesel", "Electric"],

      "Year of Registration": year.map((ye) => ye.toString()),
      // [
      //   "2000",
      //   "2001",
      //   "2002",
      //   "2003",
      //   "2004",
      //   "2005",
      //   "2006",
      //   "2007",
      //   "2008",
      //   "2009",
      //   "2010",
      //   "2011",
      //   "2012",
      //   "2013",
      //   "2014",
      //   "2015",
      //   "2016",
      //   "2017",
      //   "2018",
      //   "2019",
      //   "2020",
      //   "2021",
      //   "2022",
      //   "2023",
      // ],

      "KM Driven ": [
        "0-5000",
        "5000-6000",
        "6000-8000",
        "8000-10000",
        "10000-13000",
        "13000-15000",
        "15000-18000",
        "18000-22000",
        "22000-25000",
        "25000-30000",
        "30000-35000",
        "35000-40000",
        "40000-45000",
        "45000-50000",
        "50000-55000",
        "55000-60000",
        "60000-65000",
        "65000-70000",
        "70000-75000",
        "75000-80000",
        "80000-85000",
        "85000-90000",
        "90000-95000",
        "95000 & Above",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
      Colour: [
        "Beige",
        "Black",
        "Blue",
        "Brown",
        "Cyan",
        "Gray",
        "Green",
        "Indigo",
        "Lime",
        "Magenta",
        "Maroon",
        "Navy blue",
        "Olive",
        "Orange",
        "Peach",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "Tan",
        "Teal",
        "Turquoise",
        "Violet",
        "White",
        "Yellow",
      ],
    },

    "Spare Parts - Accessories": {
      "	Product Type": [
        "CNG/LPG Kit",
        "Electrical Part",
        "Exterior Part",
        "Interior Accessory",
        "Lubricant",
        "Other Accessory",
        "Security Equipment",
        "Tyre/Rubber Product",
      ],
      // "Price":["10000-15000","15000-20000","20000-30000","30000-40000","40000-50000","50000-60000","60000-70000","70000-80000","80000-90000","90000-100000","100000-150000","150000-200000","200000-250000","250000-300000","300000-350000","350000-400000","400000-450000","450000-500000","500000-550000","550000-600000","600000-650000","650000-700000","700000-750000","750000-800000","800000-850000","850000-900000","900000-950000","950000-1000000","10L-11L","11L-12L","12L-13L","13L-14L","14L-15L","15L-16L","16L-17L","17L-18L","18L-19L","19L-20L","21L-22L","22L-23L","23L-24L","24L-25L","25L-26L","26L-27L","27L-28L","28L-29L","29L-30L","30L-31L","31L-32L","32L-33L","33L-34L","34L-35L","35L-36L","36L-37L","38L-39L","39L-40L","40L-41L","41L-42L","42L-43L","43L-44L","44L-45L","45L-46L","46L-47L","47L-48L","48L-49L","49L-50L","50L-51L","51L-52L","52L-53L","53L-54L","54L-55L","55L-56L","57L-58L","59L-60L","60L-61L","61L-62L","62L-63L","64L-65L","65L-66L","66L-67L","67L-68L","68L-69L","70L-71L","71L-72L","73L-74L","74L-75L","75L-76L","76L-77L","77L-78L","78L-79L","79L-80L","80L-81L","81L-82L","82L-83L","83L-84L","84L-85L","85L-86L","86L-87L","87L-88L","89L-90L","90L-91L","91L-92L","92L-93L","93L-94L","94L-95L","95L-96L","96L-97L","97L-98L","98L-99L"],
    },
  },
  Furniture: {
    "Furniture For Home & Office": {
      "Furnishing Type": [
        "Bar Chairs & Stools",
        "Bar Units & Cabinets",
        "Bean Bag",
        "Bed Set",
        "Bedside Tables",
        "Benches",
        "Book Shelves",
        "Box",
        "Bunk Bed",
        "Cabinet",
        "Center Table",
        "Computer Tables",
        "Conference Table Dining Chairs",
        "Dining Table",
        "Diwans",
        "Door",
        "Dressing Table",
        "Inflatable Bed",
        "J-Template & Player Units",
        "Massage Chair",
        "Office Chair",
        "Office Table",
        "Outdoor Furniture Sets",
        "Plastic Chairs",
        "Recliner",
        "Rocking Chair",
        "Sofa Cum Bed",
        "Sofa Set",
        "Stool",
        "Study Table",
        "Swings",
        "TV Units",
        "Wardrobe",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },

    "Home Decor - Furnishings": {
      "Furnishing Type": [
        "Aquarium",
        "Bed Sheet",
        "Blanket/Quilt",
        "Candle",
        "Candle Stand",
        "Carpet/Rug/Mat",
        "Curtain ",
        "Digital Photo Frame",
        "Door Mat",
        "Key Holders",
        "Mattress",
        "Mirrors",
        "Modular Frames",
        "Modular Kitchens",
        "Photo Frames",
        "Pillow ",
        "Sofa Cover",
        "Sofa Curtains",
        "Stand ",
        "Steel Rack",
        "Table Lamp",
        "Wall Accents",
        "Wall Clock",
        "Wall Shelves",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
    Household: {
      "Product Type": [
        "Air Freshner",
        "Bath Tub",
        "Drying Stand",
        "Emergency  Light",
        "Garden Pose",
        "Garden Sprinkles",
        "Grass Trimmers",
        "Iron Board",
        "Iron Box",
        "Lamp Shade",
        "Pepper Spray",
        "Shoe Rack",
        "Step Ladders & Stools",
        "Storage Containers",
        "Torch",
        "Umbrella",
        "Water Cans",
        "Water Drums & Trunks",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
    Kitchenware: {
      "Product Type": [
        "Barbeque Grills",
        "Cooking Utensils",
        "Cutlery Sets",
        "Food Streamers",
        "Glassware Kitchen Items",
        "Kitchen Storage Container",
      ],
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },

    Painting: {
      Condition: ["Brand New", "Gently Used", "Heavily Used", "Unboxed"],
    },
  },
  Jobs: {
    "Full Time": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility Management",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",

        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
        "Any",
        "Other",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    "Part Time": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    Internships: {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
    "Work From Home": {
      "Min Salary": ["8k to 10k", "10k to 12k", "12k to 15k", "15k to 20k"],
      "Max Salary": [
        "12k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 40k",
        "40k to 50k",
        "50k to 70k",
        "70k to 90k",
        "90k to 1Lakh",
      ],
      "Min Experience": [
        "6month to 1Year",
        "1Year to 1.5Year",
        "1.5Year to 2Year",
      ],
      "Max Experience": [
        "1Year to 1.5Year",
        "1.5Year to 2Year",
        "2Year to 2.5Year",
        "2.5Year to 3Year",
        "3Year to 5Year",
      ],
      "Industry ": [
        "Advertising/PR/Events",
        "Agriculture/Dairy/Forestry/Fishing",
        "Airlines/Aviation/Aerospace",
        "Animation",
        "Any",
        "Architecture/Interior Design",
        "Automotive/Automobile/Ancillaries",
        "Banking/Accounting/Financial Services",
        "Beverages/Liquor",
        "Bio Technology & Life Sciences",
        "Cement/Concrete/Readymix",
        "Ceramics & Sanitary Ware",
        "Chemicals/Petrochemicals",
        "Construction & Engineering",
        "Consulting/Advisory Services",
        "Consumer Electronics/Durables/Appliances",
        "Courier/Freight/Transportation",
        "E-Learning",
        "Education",
        "Electrical/Switchgears",
        "Electronics Manufacturing",
        "Engineering & Design",
        "Engineering/Procurement/Construction",
        "Entertainment/Media/Publishing",
        "Environmental Service",
        "Escalators/Elevators",
        "FMCG​",
        "Facility management",
        "Fashion/Apparels",
        "Fertilizer/Pesticides",
        "Food & Packaged Food",
        "GLASS",
        "Gems & Jewellery",
        "General Trading/Import/Export",
        "Government/PSU/Defence",
        "Heat Ventilation Air Conditioning (HVAC)",
        "Hospitals/Healthcare/Diagnostics",
        "Hotels/Hospitality/Restaurant",
        "ISP",
        "IT/Computers - Hardware & Networking",
        "IT/Computers - Software",
        "ITES/BPO",
        "Insurance​",
        "Internet/E-commerce",
        "Iron/Steel",
        "KPO/Research/Analytics",
        "Law Enforcement/Security Services",
        "Leather",
        "Legal/Law Firm",
        "Machinery/Equipment Mfg.",
        "Market Research",
        "Medical Transcription",
        "Mining",
        "NGO/Social Services",
        "Non-Ferrous Metals (Aluminium/Zinc etc.)",
        "Office Equipment/Automation",
        "Oil/Gas/Petroleum",
        "Other",
        "Paints",
        "Paper",
        "Pharmaceutical",
        "Plastic/Rubber",
        "Power/Energy",
        "Printing/Packaging",
        "Public Relations (PR)",
        "Railways Specialty/Infrastructure",
        "Real Estate",
        "Recruitment/Staffing/RPO",
        "Retailing",
        "Semiconductor​",
        "Shipping/Marine Services",
        "Social Media",
        "Sugar",
        "Telecom",
        "Textiles/Yarn/Fabrics/Garments",
        "Travel/Tourism",
        "Tyres",
        "Wellness/Fitness/Sports",
        "Wood",
      ],
      Qualification: ["B.Tech", "MBA"],
    },
  },
  Pets: {
    "Pet Food": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Shop": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Clinics": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Training & Grooming​​": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
    "Pet Care-Accessories": {
      "Pet Type": ["Bird", "Cat", "Dog", "Fish", "Other Pets"],
    },
  },

  RealEstate: {
    "Rent-Residential": {
      "Property Type": [
        "Apartment",
        "Independent House/Villa",
        "Gated Community  Villa",
      ],
      " BHK Type": ["1RK​", "1BHK​", "2BHK​", "3BHK​", "4BHK​", "5BHK & Above"],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years​",
        "3 to 5 Years​",
        "5 to 10 Years​",
        "10 to 15 Years​",
        "15 Years & Above ",
      ],
      Facing: [
        "North",
        "South",
        "West",
        "East​",
        "North-East",
        "South-East",
        "North-West",
        "South-East",
      ],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Rent ": [
        "5k to 10k",
        "10k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 35k",
        "35k to 40k",
        "40k to 45k",
        "45k to 50k",
        "50k to 60k",
        "60k to 70k",
        "70k to 80k",
        "80k to 90k",
        "90k to 1Lac",
        "1Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Expected Deposit": [
        "0 to 50k",
        "50k to 1Lac",
        "1Lac to 1.5Lac",
        "1.5Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Rent Negotiable": ["Yes", "No"],
      Maintenance: ["Maintenance Extra", "Maintenance Included​"],
      "Available From Date": [],
      "Preffered Tenants ": [
        "Doesn’t Matter",
        "Family",
        "Bachelors",
        "Company",
      ],
      "Furnishing Type ": ["Fully Furnished", "Semi Furnished", "Unfurnished"],
      Parking: ["Car", "Bike", "Both Car And Bike", "None "],
      "Bath Rooms": ["Yes", "No"],
      Balcony: ["Yes", "No"],
      "Water Supply": ["Corporation", "Bore well", "Both"],
      "Gated Community": ["Yes", "No"],
      "Who Will Show The Property": [
        "Need Help",
        "I Will Show",
        "Neighbours",
        "Friends/Relatives",
        "Security",
        "Tenants",
        "Others",
      ],
      Lift: ["Yes", "No"],
      "Internet Services": ["Yes", "No"],
      "Air Conditioner": ["Yes", "No"],
      Intercom: ["Yes", "No"],
      "Swimming Pool": ["Yes", "No"],
      "Children Play Area": ["Yes", "No"],
      "Servant Room": ["Yes", "No"],
      "Shopping Center": ["Yes", "No"],
      "Gas Pipeline ": ["Yes", "No"],
      Park: ["Yes", "No"],
      "Rain water Harvesting": ["Yes", "No"],
      "Sewage Treatment Plant": ["Yes", "No"],
      "House Keeping": ["Yes", "No"],
      "Visitors Parking": ["Yes", "No"],
      "Power Backup": ["Yes", "No"],
    },
    "Buy-Residential": {
      "Property Type": [
        "Apartment",
        "Independent House/Villa",
        "Gated Community/Villa",
        "Standalone Building",
      ],
      "BHK Type": ["1RK", "1BHK", "2BHK", "3BHK", "4BHK", "5BHK & Above"],
      "Ownership Type": ["On Lease", "Self Owned"],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],

      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Floor Type": [
        "Vitrified Tiles",
        "Mosaic",
        "Marble /Granite",
        "Wooden",
        "Cement",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      Facing: [
        "North",
        "South",
        "East",
        "West",
        "North-East",
        "South-East",
        "North-West",
        "South-West",
      ],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Carpet Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Price": [
        "5Lac to 10Lac",
        "10Lac to 20Lac",
        "20Lac to 30Lac",
        "30Lac to 40Lac",
        "40Lac to 50Lac",
        "50Lac to 75Lac",
        "75Lac to 1Cr",
        "1Cr & Above",
      ],
      "Maintenance Cost/Month": [
        "1k to 10k",
        "10k to 11k",
        "11k to 12k",
        "12k to 13k",
        "13k to 14k",
        "14k to 15k",
        "15k & Above",
      ],
      "Currently Under Loan": ["Yes", "No"],
      "Kitchen Type ": ["Modular", "Covered Shelves", "Open Shelves"],
      "Available From Date": [],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      Parking: ["Car", "Bike", "Both", "None"],
      "Bath Rooms": ["Yes", "No"],
      Balcony: ["Yes", "No"],
      "Water Supply": ["Corporation", "Borewell", "Both"],
      "Gated Community": ["Yes", "No"],
      "Power Backup": ["Full", "Partial", "None"],
      "Who Will Show The Property ": [
        "Need Help",
        "I Will Show",
        "Neighbours",
        "Friends/Relatives",
        "Security",
        "Tenants",
        "Others",
      ],
      "available amenities": [
        "Lift",
        "Club House",
        "Internet Services",
        "Park",
        "Intercom",
        "Swimming Pool",
        "Children Play Area",
        "Servant Room",
        "Shopping Center",
        "Gas Pipeline ",
        "Sewage Treatment Plant",
        "House Keeping",
      ],
      "Do You Have Khata Certificate?": ["Yes", "No", "Dont Know"],
      "Do You Have Sales Deed Certificate?": ["Yes", "No", "Dont Know"],
      "Do You Have Occupancy Certificate?": ["Yes", "No", "Dont Know"],
      "Have You Paid Property Tax?": ["Yes", "No", "Dont Know"],
    },
    "PG-Hostel": {
      "Place Is Available For": ["Male", "Female", "Anyone"],
      "Preffered Guests": ["  Working Professional", "Student", "Both"],
      "Available From Date": [],
      "Food Included": ["Yes", "No"],
      "PG Rules": [
        "No Smoking ",
        "No Guardians Stay",
        "No Girls Stay",
        "No Drinking",
        "No Non Veg",
      ],
      "Gate Close Time": ["9pm", "10pm", "11pm", "12pm"],
      "Looking For": [
        "Single Room",
        "Double Room",
        "Triple Room",
        "Four Room",
        "Five Rooms & Above",
      ],
      "Expected Rent Per Person": [
        "5k to 7k",
        "7k to 9k",
        "9k to 11k",
        "11k to 13k",
        "13k to 15k",
        "15k to 20k",
        "20k to 25k",
      ],
      "Expected Deposit Per Person": [
        "Below 5k",
        "5k to 7k",
        "7k to 9k",
        "9k to 11k",
        "11k to 13k",
        "13k to 15k",
        "15k to 20k",
        "20k to 25k",
      ],
      "Room Amenities": [
        "Cupboard",
        "TV",
        "Bedding",
        "Geyser",
        "Ac",
        "Attached Bathroom",
      ],
      Laundry: ["Yes", "No"],
      "Room Cleaning": ["Yes", "No"],
      "Water Facility": ["Yes", "No"],
      "Warden Facility": ["Yes", "No"],
      Parking: ["Car", "Bike", "Both", "None"],
      "PG amenities": [
        "Cupboard",
        "Common TV",
        "Mess",
        "Lift",
        "Wi-Fi",
        "Cooking Allowed",
        "Power Backup",
        "Refrigerator",
      ],
    },
    "Rent-Commercial": {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Floor Type": [
        "Vitrified Tiles",
        "Mosaic",
        "Marble /Granite",
        "Wooden",
        "Cement",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 300 sqft",
        "300 to 600 sqft",
        "600 to 1000 sqft",
        "1000 to 1500 sqft",
        "1500 to 2000 sqft",
        "2000 to 2500 sqft",
        "2500 to 3000 sqft",
        "3000 to 4000 sqft",
        "4000 to 5000 sqft",
        "5000 sqft & Above",
      ],
      "Expected Rent": [
        "5k to 10k",
        "10k to 15k",
        "15k to 20k",
        "20k to 25k",
        "25k to 30k",
        "30k to 35k",
        "35k to 40k",
        "40k to 45k",
        "45k to 50k",
        "50k to 60k",
        "60k to 70k",
        "70k to 80k",
        "80k to 90k",
        "90k to 1Lac",
        "1Lac to 2Lac",
        "2Lac to 3Lac",
        "3Lac to 4Lac",
        "4Lac to 5Lac",
        "5Lac & Above",
      ],
      "Expected Deposit": [
        "50000 to 70000",
        "70000 to 90000",
        "90000 to 120000",
        "120000 to 150000",
        "150000 to 170000",
        "170000 to 200000",
      ],
      "Deposit Negotiable": ["Yes", "No"],
      "Rent Negotiable": ["Yes", "No"],
      Maintenance: ["Maintenance Extra", "Maintenance Included"],
      "Available From Date": [],
      "Lease Duration(Years)": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to 15 Years",
        "15 Years & Above",
      ],
      Bathrooms: ["Western", "Indian"],
      Balcony: ["Yes", "No"],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
    "Buy-Commercial": {
      "Property Type": [
        "Office Space ",
        "CO-Working",
        "Shop",
        "Showroom",
        "Godown/Warehouse",
        "Industrial Shed",
        "Industrial Building",
        "Other Business",
      ],
      "Property Age": [
        "Less Than A Year",
        "1 to 3 Years",
        "3 to 5 Years",
        "5 to 10 Years",
        "10 to  15 Years",
        "15 Years & Above",
      ],
      "Furnishing Type": ["Fully Furnished", "Semi-Furnished", "Unfurnished"],
      "Build Up Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      "Carpet Area": [
        "100 to 150 sqft",
        "151 to 200 sqft",
        "201 to 250 sqft",
        "251 to 300 sqft",
        "301 to 350 sqft",
        "351 to 400 sqft",
        "401 to 450 sqft",
      ],
      Floor: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Total Floors": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
      ],
      "Expected Price": [
        "200000 to 500000",
        "500000 to 1000000",
        "1000000 to 2000000",
        "2000000 to 2500000",
        "2500000 to 3000000",
      ],
      "Price Negotiable ": ["Yes", "No"],
      "Ownership Type": ["On Lease", "Self Owned"],
      "Available From Date": [],
      "Water Storage Facility": ["Yes", "No"],
      Washrooms: ["Shared", "Private", "No Washrooms"],
      Lift: ["Common", "Private", "None"],
      "Power Backup": ["Full", "DG Backup", "Need To Arrange"],
      Parking: ["None", "Public And Reserved", "Reserved", "Public"],
      Security: ["Yes", "No"],
      Wifi: ["Yes", "No"],
    },
  },

  //Mobiles and Tablets main category abd sub category
  Mobiles: {
    "Mobile Phones": {
      "Brand Name": [
        "Apple",
        "Blackberry",
        "Blueberry",
        "Centric",
        "Creo",
        "HTC",
        "Lava",
        "MI",
        "Micromax",
        "Nokia",
        "One Plus",
        "Oppo",
        "Samsung",
        "Sony",
        "Others",
      ],
      "Year of Purchase": [
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
      ],
      "Physical Condition": ["Excellent", "Good", "Fair"],
      Model: ["Excellent", "Good", "Fair"], //Text box,
    },
    Accessories: {
      "Accessory Type": [
        "Anti Radiation  Stickers",
        "Battery",
        "Cables",
        "Car Accessory",
        "Cases/Cover",
        "Charger",
        "Earphones",
        "Head phones",
        "Memory Card",
        "Mobile Body Panels",
        "Mobile Flashes",

        "Power Banks",
        "Screen Guard",
        "Selfie Sticks",
        "Sim Adaptor",
        "Speaker",
        "Others",
      ],

      "Brand Name": [
        "Boat",
        "Iphone",
        "Mivi",
        "Noise",
        "Nokia",
        "One Plus",
        "Oppo",
        "Other",
        "Samsung",
        "Sony",
        "Vivo",
        "Xiaomi",
        "pTron",
      ], //Textbox,
    },
    Tablets: {
      "Brand Name": [
        "Apple",
        "Blackberry",
        "Bluberry",
        "Centric",
        "Creo",
        "Google",
        "HTC",
        "Hp",
        "Lava",
        "MI",
        "Micromax",
        "Oppo",
        "Others",
        "Samsung",
        "Sony",
      ],
      Condition: ["Brand New", "Used"],
    },
  },

  Services: {
    "Home Repair": {
      Types: ["Carpenter", "Electrician", "Plumber", "Waterfroofing"],
    },

    "Appliance Repair": {
      Types: [
        "Air Conditioner",
        "Washing Machine",
        "Refrigerator",
        "Air Cooler Repair",
        "Geyser Repair",
      ],
    },

    "Packers and Movers": {
      Types: ["Within City", "Between Cities", "International"],
    },
    "Pest Control": [],
    "Home Cleaning": {
      Types: [
        "Full Home Deep Cleaning",
        "Basic Home Cleaning",
        "Bathroom Cleaning",
        "Sofa-Set Cleaning",
        "Kitchen Cleaning",
        "Water Tank Cleaning",
        "Sanitation Cleaning ",
      ],
    },
    "Computer Repair": [],
    "Mobile & Tablet Repair": {
      Types: ["Mobile Repair", "Tablet Repair", "Iphone-Ipad Repair"],
    },
  },
  //State
  // stateMain: {
  //   "Andhra Pradesh": [
  //     "kurnool",
  //     "Visakhapatnam",
  //     "Vijayawada",
  //     "Guntur",
  //     "Nellore",
  //     "Kurnool",
  //     "Kakinada",
  //     "Rajahmundry",
  //     "Kadapa",
  //     "Tirupati",
  //     "Anantapuram",
  //     "Vizianagaram",
  //     "Eluru",
  //     "Nandyal",
  //     "Ongole",
  //     "Adoni",
  //     "Madanapalle",
  //     "Machilipatnam",
  //     "Tenali",
  //     "Proddatur",
  //     "Chittoor",
  //     "Hindupur",
  //     "Srikakulam",
  //     "Bhimavaram",
  //     "Tadepalligudem",
  //     "Guntakal",
  //     "Dharmavaram",
  //     "Gudivada",
  //     "Narasaraopet",
  //     "Kadiri",
  //     "Tadipatri",
  //     "Mangalagiri",
  //     "Chilakaluripet",
  //   ],
  //   Kerala: [
  //     "Alappuzha",
  //     "Ernakulam",
  //     "Idukki",
  //     "Kannur",
  //     "Kasaragod",
  //     "Kollam",
  //     "Kottayam",
  //     "Kozhikode",
  //     "Malappuram",
  //     "Palakkad",
  //     "Pathanamthitta",
  //     "Thiruvananthapuram",
  //     "Thrissur",
  //     "Wayanad",
  //   ],
  //   "West Bengal": [
  //     "Alipurduar",
  //     "Bankura",
  //     "Birbhum",
  //     "Cooch Behar",
  //     "Dakshina Dinajpur",
  //     "Darjeeling",
  //     "Hooghly",
  //     "Howrah",
  //     "Jalpaiguri",
  //     "Jhargram",
  //     "Kalimpong",
  //     "Kolkata",
  //     "Malda",
  //     "Murshidabad",
  //     "Nadia",
  //     "North 24 Parganas",
  //     "Paschim Bardhaman",
  //     "Paschim Medinipur",
  //     "Purba Bardhaman",
  //     "Purba Medinipur",
  //     "Purulia",
  //     "South 24 Parganas",
  //     "Uttar Dinajpur",
  //   ],

  //   "Tamil Nadu": [
  //     "Ariyalur",
  //     "Chengalpattu",
  //     "Chennai",
  //     "Coimbatore",
  //     "Cuddalore",
  //     "Dharmapuri",
  //     "Dindigul",
  //     "Erode",
  //     "Kallakurichi",
  //     "Kancheepuram",
  //     "Karur",
  //     "Krishnagiri",
  //     "Madurai",
  //     "Mayiladuthurai",
  //     "Nagapattinam",
  //     "Kanyakumari",
  //     "Namakkal",
  //     "Perambalur",
  //     "Pudukottai",
  //     "Ramanathapuram",
  //     "Ramanathapuram",
  //     "Ranipet",
  //     "Salem",
  //     "Sivagangai",
  //     "Tenkasi",
  //     "Thanjavur",
  //     "Theni",
  //     "Thiruvallur",
  //     "Thiruvarur",
  //     "Tuticorin",
  //     "Trichirappalli",
  //     "Thirunelveli",
  //     "Tirupathur",
  //     "Tiruppur",
  //     "Tiruvannamalai",
  //     "The Nilgiris",
  //     "Vellore",
  //     "Viluppuram",
  //     "Virudhunagar",
  //   ],
  //   Maharashtra: [
  //     "Ahmednagar",
  //     "Akola",
  //     "Amravati",
  //     "Aurangabad",
  //     "Beed",
  //     "Bhandara",
  //     "Buldhana",
  //     "Chandrapur",
  //     "Dhule",
  //     "Gadchiroli",
  //     "Gondia",
  //     "Hingoli",
  //     "Jalgaon",
  //     "Jalna",
  //     "Kolhapur",
  //     "Latur",
  //     "Mumbai City",
  //     "Mumbai Suburban",
  //     "Nagpur",
  //     "Nanded",
  //     "Nandurbar",
  //     "Nashik",
  //     "Osmanabad",
  //     "Palghar",
  //     "Parbhani",
  //     "Pune",
  //     "Raigad",
  //     "Ratnagiri",
  //     "Sangli",
  //     "Satara",
  //     "Sindhudurg",
  //     "Solapur",
  //     "Thane",
  //     "Wardha	",
  //     "Washim",
  //     "Yavatmal",
  //   ],

  //   Karnataka: [
  //     "Bagalkot",
  //     "Bengaluru Urban",
  //     "Bengaluru Rural",
  //     "Belagavi",
  //     "Ballari",
  //     "Bidar",
  //     "Vijayapur",
  //     "Chamarajanagar",
  //     "Chikballapur",
  //     "Chikkamagaluru",
  //     "Chitradurga",
  //     "Dakshina Kannada",
  //     "Davanagere",
  //     "Dharwad",
  //     "Gadag",
  //     "Kalaburagi",
  //     "Hassan",
  //     "Haveri",
  //     "Kodagu",
  //     "Kolar",
  //     "Koppal",
  //     "Mandya",
  //     "Mysuru",
  //     "Raichur",
  //     "Ramanagara",
  //     "Shivamogga",
  //     "Tumkur",
  //     "Udupi",
  //     "Uttara Kannada",
  //     "Yadgir",
  //   ],
  //   Ladakh: ["Leh", "Kargil"],
  //   "Andaman & Nicobar Islands": [
  //     "Nicobar",
  //     "North and Middle Andaman",
  //     "South Andaman",
  //     "Port Blair",
  //     "Nicobar Islands",
  //     "Little Andaman",
  //   ],
  //   "Dadra & Nagar Haveli": ["Daman", "Diu", "Dadra and Nagar Haveli"],
  //   Delhi: [
  //     "CentralDelhi",
  //     "East Delhi",
  //     "New Delhi",
  //     "North Delhi",
  //     "North East Delhi",
  //     "North West Delhi",
  //     "Shahdara",
  //     "South Delhi",
  //     "SouthEastDelhi",
  //     "South West Delhi",
  //     "West Delhi",
  //   ],
  //   Chandigarh: ["Chandigarh"],
  //   "Jammu & Kashmir": [
  //     "Kathua",
  //     "Jammu",
  //     "Samba",
  //     "Udhampur",
  //     "Reasi",
  //     "Rajouri",
  //     "Poonch",
  //     "Doda",
  //     "Ramban",
  //     "Kishtwar",
  //     "Anantnag",
  //     "Kulgam",
  //     "Pulwama",
  //     "Shopian",
  //     "Budgam",
  //     "Srinagar",
  //     "Ganderbal",
  //     "Bandipora",
  //     "Baramulla",
  //     "Kupwara",
  //   ],
  //   Lakshadweep: ["kavaratti", "Andrott", "Minicoy", "Amini"],
  //   Pondicherry: ["Karaikal", "Mahé", "Puducherry", "Yanam"],
  //   Goa: ["North Goa", "South Goa"],
  //   "Uttar Pradesh": [
  //     "Agra",
  //     "Aligarh",
  //     "PrayagRaj",
  //     "Ambedkar Nagar",
  //     "Amroha",
  //     "Auraiya",
  //     "Azamgarh",
  //     "Badaun",
  //     "Bahraich",
  //     "Ballia",
  //     "Balrampur",
  //     "Banda District",
  //     "Barabanki",
  //     "Bareilly",
  //     "Basti",
  //     "Bijnor",
  //     "Bulandshahr",
  //     "Chandauli",
  //     "Chitrakoot",
  //     "Deoria",
  //     "Etah",
  //     "Etawah",
  //     "Faizabad",
  //     "Farrukhabad",
  //     "Fatehpur",
  //     "Firozabad",
  //     "Gautam Buddha Nagar",
  //     "Ghaziabad",
  //     "Ghazipur",
  //     "Gonda",
  //     "Gorakhpur",
  //     "Hamirpur",
  //     "Hapur District",
  //     "Hardoi",
  //     "Hathras",
  //     "Jaunpur District",
  //     "Jhansi",
  //     "Kannauj",
  //     "Kanpur Dehat",
  //     "Kanpur Nagar",
  //     "Kasganj",
  //     "Kaushambi",
  //     "Kushinagar",
  //     "Lakhimpur Kheri",
  //     "Lalitpur",
  //     "Lucknow",
  //     "Maharajganj",
  //     "Mahoba",
  //     "Mainpuri",
  //     "Mathura",
  //     "Mau",
  //     "Meerut",
  //     "Mirzapur",
  //     "Moradabad",
  //     "Muzaffarnagar",
  //     "Pilibhit",
  //     "Pratapgarh",
  //     "Rae Bareli",
  //     "Rampur",
  //     "Saharanpur",
  //     "Sant Kabir Nagar",
  //     "Sant Ravidas Nagar",
  //     "Sambhal",
  //     "Shahjahanpur",
  //     "Shamli",
  //     "Shravasti",
  //     "Siddharthnagar",
  //     "Sitapur",
  //     "Sonbhadra",
  //     "Sultanpur",
  //     "Unnao,Varanasi",
  //     "Allahabad",
  //     "Amethi",
  //     "Bagpat",
  //   ],
  //   Tripura: ["Dhalai", "North Tripura", "North Tripura", "West Tripura"],
  //   Mizoram: [
  //     "Aizawl",
  //     "Aizawl",
  //     "Aizawl",
  //     "Khawzawl",
  //     "Kolasib",
  //     "Lawngtlai",
  //     "Lunglei",
  //     "Mamit",
  //     "Saiha",
  //     "Saitual",
  //     "Serchhip",
  //   ],
  //   Nagaland: [
  //     "Chümoukedima",
  //     "Dimapur",
  //     "Kiphire",
  //     "Kohima",
  //     "Longleng",
  //     "Mokokchung",
  //     "mon",
  //     "Niuland",
  //     "Noklak",
  //     "Peren",
  //     "Phek",
  //     "Shamator",
  //     "Tuensang",
  //     "Tseminyü",
  //     "Wokha",
  //     "Zünheboto",
  //   ],
  //   Meghalaya: [
  //     "North Garo Hills",
  //     "East Garo Hills",
  //     "South Garo Hills",
  //     "West Garo Hills",
  //     "South West Garo Hills",
  //     "West Jaintia Hills",
  //     "East Jaintia Hills",
  //     "East Khasi Hills",
  //     "East Khasi Hills",
  //     "South West Khasi Hills",
  //     "Eastern West Khasi Hills",
  //     "Ri-Bhoi",
  //   ],
  //   Manipur: [
  //     "Bishnupur",
  //     "Chandel",
  //     "Churachandpur",
  //     "Imphal East",
  //     "Jiribam",
  //     "Kakching",
  //     "Kakching",
  //     "Kangpokpi (Sadar Hills)",
  //     "Noney",
  //     "Pherzawl",
  //     "Senapati",
  //     "Tamenglong",
  //     "Tengnoupal",
  //     "Thoubal",
  //     "Ukhrul",
  //   ],
  //   Bihar: [
  //     "Araria",
  //     "Arwal",
  //     "Aurangabad",
  //     "Banka",
  //     "Begusarai",
  //     "Begusarai",
  //     "Bhojpur",
  //     "Buxar",
  //     "Darbhanga",
  //     "East Champaran",
  //     "Gaya",
  //     "Gopalganj",
  //     "Jamui",
  //     "Jehanabad",
  //     "Khagaria",
  //     "Kaimur",
  //     "Katihar",
  //     "Lakhisarai",
  //     "Madhubani",
  //     "Munger",
  //     "Madhepura",
  //     "Muzaffarpur",
  //     "Nalanda",
  //     "Nawada",
  //     "Patna",
  //     "Purnia",
  //     "	Rohtas",
  //     "Saharsa",
  //     "Samastipur",
  //     "Sheohar",
  //     "Sheikhpura",
  //     "Saran",
  //     "Sitamarhi",
  //     "Supaul",
  //     "Siwan",
  //     "Siwan",
  //     "Vaishali",
  //     "West Champaran",
  //   ],
  //   "Madhya Pradesh": [
  //     "Bhopal",
  //     "Raisen",
  //     "Rajgarh",
  //     "Sehore",
  //     "Vidisha",
  //     "Morena",
  //     "Sheopur",
  //     "Bhind",
  //     "Gwalior",
  //     "Ashoknagar",
  //     "Shivpuri",
  //     "Datia",
  //     "Guna",
  //     "Alirajpur",
  //     "Barwani",
  //     "Burhanpur",
  //     "Indore",
  //     "Dhar",
  //     "Jhabua",
  //     "Khandwa",
  //     "Khargone",
  //     "Balaghat",
  //     "Chhindwara",
  //     "Jabalpur",
  //     "Katni",
  //     "Mandla",
  //     "Narsinghpur",
  //     "Seoni",
  //     "Dindori",
  //     "Betul",
  //     "Harda",
  //     "Narmadapuram",
  //     "Rewa",
  //     "Satna",
  //     "Sidhi",
  //     "Singrauli",
  //     "Chhatarpur",
  //     "Damoh",
  //     "Panna",
  //     "Sagar",
  //     "Tikamgarh",
  //     "Niwari",
  //     "Anuppur",
  //     "Shahdol",
  //     "Umaria",
  //     "Agar Malwa",
  //     "Dewas",
  //     "Mandsaur",
  //     "Neemuch",
  //     "Ratlam",
  //     "Shajapur",
  //     "Ujjain",
  //   ],
  //   Punjab: [
  //     "Amritsar",
  //     "Barnala",
  //     "Bathinda",
  //     "Faridkot",
  //     "Fatehgarh Sahib",
  //     "Firozpur",
  //     "Fazilka",
  //     "Gurdaspur",
  //     "Hoshiarpur",
  //     "Jalandhar",
  //     "Kapurthala",
  //     "Ludhiana",
  //     "Malerkotla",
  //     "Mansa",
  //     "Moga",
  //     "Sri Muktsar Sahib",
  //     "Pathankot",
  //     "Patiala",
  //     "Rupnagar",
  //     "Sahibzada Ajit Singh Nagar",
  //     "Sangrur",
  //     "Shahid Bhagat Singh Nagar",
  //     "Tarn Taran",
  //   ],
  //   Sikkim: [
  //     "Gangtok",
  //     "Mangan",
  //     "Namchi",
  //     "Gyalshing or Geyzing",
  //     "Pakyong",
  //     "Pakyong",
  //   ],
  //   Rajasthan: [
  //     "Ajmer",
  //     "Alwar",
  //     "Banswara",
  //     "Baran",
  //     "Barmere",
  //     "Bharatpur",
  //     "Bhilwara",
  //     "Bikaner",
  //     "Bundi",
  //     "Chittogarh",
  //     "smart",
  //     "Dausa",
  //     "Dholpur",
  //   ],
  //   Odisha: [
  //     "Cuttack",
  //     "Jagatsinghpur",
  //     "Kendrapara",
  //     "Jajpur",
  //     "Puri",
  //     "Khordha",
  //     "Nayagarh",
  //     "Balasore",
  //     "Bhadrak",
  //     "Mayurbhanj",
  //   ],
  //   Uttarkhand: [
  //     "Almora",
  //     "Bageshwar",
  //     "Chamoli",
  //     "Champawat",
  //     "Dehradun",
  //     "Haridwar",
  //     "Nainital",
  //     "Pauri Garhwal",
  //     "Pithoragarh",
  //     "Rudraprayag",
  //     "Tehri Garhwal",
  //     "Udham Singh Nagar",
  //     "Uttarkashi",
  //   ],
  //   Haryana: [
  //     "Gurugram",
  //     "Karnal",
  //     "Sonipat",
  //     "Panipat",
  //     "Faridabad",
  //     "Rohtak",
  //     "Hisar",
  //     "Ambala",
  //     "Panchkula",
  //     "Jind",
  //     "Yamuna Nagar",
  //     "Sirsa",
  //     "Rewari",
  //     "Kaithal",
  //     "Jhajjar",
  //     "Thanesar",
  //     "Narnaul",
  //     "Kurukshetra",
  //     "Palwal",
  //     "Bhiwani",
  //   ],
  //   "Himachal Pradesh": [
  //     "Shimla",
  //     "Kangra",
  //     "Solan",
  //     "Mandi",
  //     "Sirmaur",
  //     "Chamba",
  //     "Una",
  //     "Kullu",
  //     "Hamirpur",
  //     "Bilaspur",
  //     "Dharamsala",
  //     "Palampur",
  //     "Baddi",
  //     "Nahan",
  //     "Paonta Sahib",
  //     "Sundarnagar",
  //     "Yol Cantonment",
  //     "Nalagarh",
  //     "Nurpur",
  //     "Baijnath Paprola",
  //     "Santokhgarh",
  //     "Santokhgarh",
  //     "Shamshi",
  //     "Parwanoo",
  //     "Manali",
  //     "Tira Sujanpur",
  //     "Ghumarwin",
  //     "Dalhousie",
  //     "Rohru",
  //     "Nagrota Bagwan",
  //     "Rampur",
  //     "Jawalamukhi",
  //     "Jogindernagar",
  //     "Dera Gopipur",
  //     "Sarkaghat",
  //     "Jhakhri",
  //     "Indora",
  //     "Bhuntar",
  //     "Nadaun",
  //     "Theog",
  //     "Kasauli Cantonment",
  //     "Gagret",
  //     "Chuari Khas",
  //     "Daulatpur",
  //     "Sabathu Cantonment",
  //     "Dalhousie Cantonment",
  //     "Rajgarh",
  //     "Arki",
  //     "Dagshai Cantonment",
  //     "Seoni",
  //     "Talai",
  //     "Jutogh Cantonment",
  //     "Chaupal",
  //     "Rewalsar",
  //     "Bakloh Cantonment",
  //     "Jubbal",
  //     "Bhota",
  //     "Banjar",
  //     "Naina Devi",
  //     "Kotkhai",
  //     "Narkanda",
  //   ],
  //   Gujarat: [
  //     "Ahmedabad",
  //     "Surat",
  //     "Vadodara",
  //     "Rajkot",
  //     "Bhavnagar",
  //     "Jamnagar",
  //     "Gandhinagar",
  //     "Junagadh",
  //     "Gandhidham",
  //     "Anand",
  //     "Navsari",
  //     "Morbi",
  //     "Nadiad",
  //     "Surendranagar",
  //     "Bharuch",
  //     "Mehsana",
  //     "Bhuj",
  //     "Porbandar",
  //     "Palanpur",
  //     "Valsad",
  //     "Vapi",
  //     "Gondal",
  //     "Veraval",
  //     "Godhra",
  //     "Patan",
  //     "Kalol",
  //     "Dahod",
  //     "Botad",
  //     "Amreli",
  //     "Deesa",
  //     "Jetpur",
  //   ],
  //   Jharkhand: [
  //     "Ranchi",
  //     "Dhanbad",
  //     "Jamshedpur",
  //     "Hazaribagh",
  //     "Bokaro",
  //     "Deoghar",
  //     "Giridih",
  //     "Palamu",
  //     "Ramgarh",
  //     "Bokaro",
  //     "Dhanbad",
  //     "Koderma",
  //   ],
  //   Chattisgarh: [
  //     "Durg",
  //     "Janjgir",
  //     "Rajnandgaon",
  //     "Raipur",
  //     "Dantewada",
  //     "Balod",
  //     "Baloda Bazar",
  //     "Balrampur",
  //     "Bastar",
  //     "Bemetara",
  //     "Bijapur",
  //     "Bilaspur",
  //     "Dhamtari",
  //     "Gariaband",
  //     "Gaurella-Pendra-Marwahi",
  //     "Jashpur",
  //     "Kabirdham",
  //     "Kanker",
  //     "Kondagaon",
  //     "Khairagarh",
  //     "Korba",
  //     "Koriya",
  //     "Mahasamund",
  //     "Manendragarh",
  //     "Mohla",
  //     "Mungeli",
  //     "Narayanpur",
  //     "Raigarh",
  //     "Sarangarh",
  //     "Sakti",
  //     "Sukma",
  //     "Surajpur",
  //     "Surguja",
  //   ],
  //   Assam: [
  //     "Baksa",
  //     "Barpeta",
  //     "Biswanath",
  //     "Bongaigaon",
  //     "Bajali",
  //     "Cachar",
  //     "Charaideo",
  //     "Chirang",
  //     "Darrang",
  //     "Dhemaji",
  //     "Dhubri",
  //     "Dibrugarh",
  //     "Dima Hasao",
  //     "Goalpara",
  //     "Golaghat",
  //     "Hailakandi",
  //     "Hojai",
  //     "Jorhat",
  //     "Kamrup Metropolitan",
  //     "Kamrup",
  //     "Karbi Anglong",
  //     "Karimganj",
  //     "Kokrajhar",
  //     "Lakhimpur",
  //     "Majuli",
  //     "Morigaon",
  //     "Nagaon",
  //     "Nalbari",
  //     "Sivasagar",
  //     "Sonitpur",
  //     "South Salmara-Mankachar",
  //     "Tinsukia",
  //     "Tamulpur",
  //     "Udalguri",
  //     "West Karbi Anglong",
  //   ],
  //   "Arunachal Pradesh": [
  //     "Tawang",
  //     "West Kameng",
  //     "Pakke-Kessang",
  //     "East Kameng",
  //     "Kurung Kumey",
  //     "Itanagar",
  //     "Papum Pare",
  //     "Lower Subansiri",
  //     "Kra Daadi",
  //     "Upper Subansiri",
  //     "Kamle",
  //     "Shi Yomi",
  //     "West Siang",
  //     "Lower Siang",
  //     "Lepa Rada",
  //     "Siang",
  //     "Upper Siang",
  //     "East Siang",
  //     "Dibang Valley",
  //     "Lower Dibang Valley",
  //     "Lohit",
  //     "Namsai",
  //     "Anjaw",
  //     "Changlang",
  //     "Tirap",
  //     "Longding",
  //   ],
  //   Telangana: [
  //     "Adilabad",
  //     "Asifabad",
  //     "Mancherial",
  //     "Nirmal",
  //     "Nizamabad",
  //     "Jagitial",
  //     "Peddapalli",
  //     "Kamareddy",
  //     "Rajanna Sircilla",
  //     "Karimnagar",
  //     "Jayashankar",
  //     "Sangareddy",
  //     "Medak",
  //     "Siddipet",
  //     "Jangaon",
  //     "Hanamkonda",
  //     "Warangal",
  //     "Mulugu",
  //     "Bhadradri",
  //     "Khammam",
  //     "Mahabubabad",
  //     "Suryapet",
  //     "Nalgonda",
  //     "Yadadri",
  //     "Medchal",
  //     "Malkajgiri",
  //     "Hyderabad",
  //     "Ranga Reddy",
  //     "Vikarabad",
  //     "Narayanpet",
  //     "Mahabubnagar",
  //     "Nagarkurnool",
  //     "Wanaparthy",
  //     "Jogulamba",
  //   ],

  //   "Daman & Diu": [
  //     "Bhimpore",
  //     "Dadhel",
  //     "Daman Municipal Council",
  //     "Diu Municipal Council",
  //     "Dunetha",
  //     "Kachigam",
  //     "Kadaiya",
  //     "Marwad",
  //   ],
  // },
};
