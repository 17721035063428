import React, { useEffect, useState } from 'react'
import TinderCard from 'react-tinder-card';
// import database from './firebaseconfig.js';
import './TinderCard.css';
import butterfly from './butterfly.jpeg';
import MatchHeader from './MatchHeader';
import ChatScreen from './ChatScreen';
import Chats from './Chats'
import Chat from './Chat'
import SwipeButtons from './SwipeButtons';

function TinderCards() {

    const [people, setPeople] = useState([]);

    useEffect(() => {
        // this is where the code run ...
        // const unSubscribe = database.collection('people').onSnapshot(snapshot => (
        //     setPeople(snapshot.docs.map(doc => doc.data()))
        // ));
        // console.log("Hellp world")

        // return () => {
        //     // cleanUp
        //     unSubscribe()
        // }
    }, [])

    const data = [
        {
            name: 'Rahul',
            image: butterfly
        },
        {
            name: 'Mohan',
            image: butterfly
        },
        {
            name: 'Sohan',
            image: butterfly
        },
        {
            name: 'Karam',
            image: butterfly
        },
        {
            name: 'Manish',
            image: butterfly
        },
    ]
    //console.log(people);

    // Bad
    // const people =[];
    // people.push('sashen', 'hasindu')

    // Good
    // setPeople([...people, 'sashen', 'hasindu'])

    return (
        <div className='my-4'>
            <MatchHeader />
            <div className='card__container'>
                {data.map(person => (
                    // <div className='tindercardContainer'>
                    <TinderCard
                        className='swipe'
                        key={person.name}
                        preventSwipe={['up', 'down']}
                    >
                        <div
                            style={{ backgroundImage: `url(${person?.image})` }}
                            className='card'
                        >
                            <h3>{person.name}</h3>
                            {/* <h3>Hello world</h3> */}
                        </div>
                    </TinderCard>
                    // </div>

                ))}
                <SwipeButtons />
            </div>
            {/* </MatchHeader> */}

            {/* <ChatScreen /> */}
            {/* <Chats /> */}

        </div>
    )
}

export default TinderCards