import React from 'react'


function Header() {
  return (
    <div className='container d-none d-md-block' style={{height:"3.6rem"}}>
      {/* height: "58px" */}
      <ul className='header-list mb-2 d-flex justify-content-between  ' style={{}}>
        <li>100+ House Swapping Options Available, check it fast!</li>
        {/* <div className='d-flex'> */}
        <ul className='d-flex gap-0 gap-lg-2 flex-column flex-lg-row'>
          <li>email.id@domain.com</li>
          <li>+91 12345-67890</li>
        </ul>

        {/* </div> */}
      </ul>
    </div>
  )
}

export default Header