import React from 'react'
import "./Career.css";
import bannertop from "../../images/about_bannner.jpeg";
import image1 from "../../images/1.png"
import image2 from "../../images/2.png"
import image3 from "../../images/3.png"
import image4 from "../../images/4.png"
import image5 from "../../images/5.png"
import image31 from "../../images/section31.png"
import image32 from "../../images/section32.jpg"
import image33 from "../../images/section33.png"
import imgturnkey from "../../images/imgTurnketbox.png"


function Career() {
  return (
    <>
      {/* section 1 */}
      <div className="banner-top" style={{ backgroundImage: `url(${bannertop})` }}>
        <div className="banner-content">
          <p className='turn-key'><span>Turn keys:</span> How does it work?</p>
          <p className='from-reg'>"From Registration to Adventure - A Seamless Experience Awaits You</p>
        </div>
      </div>

      {/* Section 2 */}
      <div className='section-2-mainbox'>
        <div className='how-to-begin-box'>
          <p>How to begin your journey?</p>
        </div>
        <div className='steps-to-house'>
          <p>Steps to House Swapping</p>
        </div>

        <div className='middle-main-box container'>
          <div className='row row-1'>
            <div className='col-3 column-1'>
              <img src={image1} />
            </div>
            <div className='col-9 column-2'>
              <h2>Registration and Verification</h2>
              <p>- Sign up for free with basic details.</p>
              <p>- Complete our identity verification process for a secure and trusted community.</p>
            </div>
          </div>
          <div className='row row-1'>
            <div className='col-3 column-1'>
              <img src={image2} />
            </div>
            <div className='col-9 column-2'>
              <h2>Create your Profile</h2>
              <p>- Browse through a diverse range of properties from around the world.</p>
              <p>- Use customizable search filters to narrow down your options.</p>
              <p>- In fact our AI schema will assist you to shortlist your desired home.</p>
            </div>
          </div>
          <div className='row row-1 mt-md-0 mt-5'>
            <div className='col-3 column-1'>
              <img src={image3} />
            </div>
            <div className='col-9 column-2'>
              <h2>Explore Listings</h2>
              <p>- Sign up for free with basic details.</p>
              <p>- Complete our identity verification process for a secure and trusted community.</p>
            </div>
          </div>
          <div className='row row-1 mt-sm-0'>
            <div className='col-3 column-1'>
              <img src={image4} />
            </div>
            <div className='col-9 column-2'>
              <h2>Swapping</h2>
              <p>- Get lots of Exchanging Home option by our AI based filter.</p>
              <p>- Discuss potential swaps and get to know your exchange partners.</p>
            </div>
          </div>
          <div className='row row-1'>
            <div className='col-3 column-1'>
              <img src={image5} />
            </div>
            <div className='col-9 column-2'>
              <h2>Initiate Contact and Confirm</h2>
              <p>- Connect with homeowners through secure messaging.</p>
              <p>- Finalize the details and terms of your house swap.</p>
              <p>- Enjoy a seamless and secure exchange experience.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Section -3 */}
      <div className='section-3-container'>
  <p className='uniqueness'>Uniqueness of Us</p>
  <div className='row row-box-1'>
    <div className='col-3 col-md-3'>
      <hr className='hr-left' />
    </div>
    <div className='col-6 col-md-6 why_turn-box'>
      <p className='why_turn'>Why <span>T</span>urn <span>K</span>eys?</p>
    </div>
    <div className='col-3 col-md-3'>
      <hr className='hr-right' />
    </div>
  </div>

  <div className="row row-cards justify-content-center">
    <div className="col-sm-10 col-md-4">
      <div className="card equal-height-card">
        <img className="card-img-top equal-height-img" src={image31} alt="Card 1" />
        <div className="card-body">
          <h5 className="card-title">Exploring Virtual Tour</h5>
          <p className="card-text">At Turn Keys, we understand that seeing is believing. That's why we've introduced a cutting-edge feature that lets you explore properties like never before. When you find a listing that catches your eye, take your experience to the next level by requesting a virtual tour</p>
        </div>
      </div>
    </div>

    <div className="col-sm-10 col-md-4">
      <div className="card equal-height-card">
        <img className="card-img-top equal-height-img" src={image32} alt="Card 2" />
        <div className="card-body">
          <h5 className="card-title">Secure and Trusted</h5>
          <p className="card-text">Ensure a trusted community with our identity verification process. Communicate securely with fellow homeowners through our encrypted messaging system. Experience safe and secure financial transactions with our reliable payment systems.</p>
        </div>
      </div>
    </div>

    <div className="col-sm-10 col-md-4">
      <div className="card equal-height-card">
        <img className="card-img-top equal-height-img" src={image33} alt="Card 3" />
        <div className="card-body">
          <h5 className="card-title">User-Friendly Experience</h5>
          <p className="card-text">Sign up effortlessly with our quick and straightforward registration process. Navigate our platform seamlessly with an intuitive interface. Begin your house-swapping journey with a hassle-free onboarding experience.</p>
        </div>
      </div>
    </div>
  </div>
  
</div>
<div className='imgTurnKeyBox'>
      <img src={imgturnkey}/>
    </div>

    </>
  )
}

export default Career



// import React, { useState, useEffect } from "react";
// import { BsFillCircleFill } from "react-icons/bs";
// import { isMobile } from "react-device-detect";
// import "./Career.css";
// import { Button, Modal } from "antd";
// import CareerForm from "./CareerForm";
// import { localUrl } from "../../env";
// import Spiner from "../../Spiner";
// const mobileStyle = {};
// const desktopStyle = {
//   borderLeft: "3px solid rgb(6 14 131 / 35%)",
//   height: "350px",
// };

// const mob = {};
// const desk = { margin: "20px" };
// const mobdes = {};
// const deskdes = {
//   height: "70px",
// };
// const Career = () => {
//   const [open, setOpen] = useState(false);
//   const [jobdata, setJobData] = useState([]);
//   const [jobId, setJobId] = useState([]);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     setLoading(true);
//     var requestOptions = {
//       method: "GET",
//       redirect: "follow",
//     };
//     fetch(localUrl + "user/jobsRequired", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         setLoading(false);
//         setJobData(result);
//         console.log(result, "gg");
//       })
//       .catch((error) => console.log("error", error));
//     setLoading(false);
//   }, []);
//   return (
//     <>
//       {loading && <Spiner />}
//       <div className="row border p-3">
//         <div className="col-lg-2"></div>
//         <div className="col-lg-8 mt-3 ">
//           <h3
//             style={{ textAlign: "center", fontWeight: "bold", color: "black" }}
//           >
//             Join Our Team
//           </h3>
//           <p
//             className="fs-18"
//             style={{ textAlign: "center", fontWeight: "bold" }}
//           >
//             RotateKey is a leading digital platform for local service businesses in
//             India. RotateKey focuses on expert services clustered around Home, Life
//             and Self and where the user's need is customized. Using technology
//             and domain intelligence.
//           </p>
//         </div>
//       </div>
//       <div className="row  p-3">
//         <div className="col-lg-1"></div>
//         <div className="col-lg-5">
//           <h3>Our Culture</h3>
//           <p>
//             We foster an inclusive workplace environment where employees are
//             supported and encouraged to be their authentic selves and do their
//             best work.
//             <br />
//             Further, all employees are respected and given the opportunity to
//             grow and thrive. From onboarding and training, to conference
//             participation and continuing educational opportunities, development
//             and subject matter expertise is highly valued.
//           </p>
//           <b>Be a part of one of the best Indian software companies.</b>
//           <p>
//             RotateKey is proud of our talented workforce. We show gratitude to our
//             employees by organizing regular outings, like cricket and
//             volleyball. We also cater special luncheons and recognize
//             achievements with promotions, raises and bonuses!
//           </p>
//         </div>

//         <div className="col-lg-5" style={isMobile ? mobileStyle : desktopStyle}>
//           <div className="mx-3">
//             {" "}
//             <h3>EMPLOYEE BENEFITS</h3>
//             <p>
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Fast-paced environment in a leading organization that’s
//               growing exponentially &nbsp;
//               <br /> <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Competitive compensation packages with additional earnings
//               potential &nbsp; <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Comprehensive benefits package &nbsp; <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Opportunities to drive new ideas and concepts &nbsp;
//               <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Strong technical knowledge and expertise &nbsp;
//               <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Robust growth &nbsp;
//               <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Flexible work hours &nbsp;
//               <br />
//               <BsFillCircleFill style={{ fontSize: "10px" }} />
//               &nbsp;Extensive training curriculum
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="row border bgColor p-5">
//         <div className="col-lg-8 " style={{ textAlign: "center" }}>
//           <h3 style={{ fontWeight: "bold", color: "white" }}>
//             Saturdays are our walk-in interview days
//           </h3>
//         </div>
//         <div className="col-lg-4 ">
//           {/* <h4 className="text-white">View Location</h4> */}
//         </div>
//       </div>
//       <div className="row  p-2 " style={isMobile ? mob : desk}>
//         {jobdata?.map((jobsdata) => {
//           return (
//             <div className="col-lg-6">
//               <div className="card">
//                 <h4 className="card-header text-capitalize ">
//                   {jobsdata?.fields?.title}
//                 </h4>
//                 <h5 className="pl-4 mt-2 mb-1 ">
//                   No.Of Openings - <b>{jobsdata.fields.no_of_openings}</b>
//                 </h5>

//                 <div className="card-body">
//                   {/* <h5 className="card-title">Special title treatment</h5> */}
//                   <p className="fs-14">
//                     <BsFillCircleFill style={{ fontSize: "10px" }} />{" "}
//                     <b>Job Responsibility </b> :{" "}
//                     {jobsdata.fields.job_responsiblity}
//                     <br />
//                     <BsFillCircleFill style={{ fontSize: "10px" }} />{" "}
//                     <b>Technical Skills </b>: {jobsdata.fields.technical_skills}
//                     <br />
//                     <BsFillCircleFill style={{ fontSize: "10px" }} />{" "}
//                     <b>Qualification </b>:{" "}
//                     {jobsdata.fields.Preferred_qualification}
//                   </p>
//                   <b> Description</b>
//                   <p className="fs-14" style={isMobile ? mobdes : deskdes}>
//                     {" "}
//                     <div className="d-lg-none hidden-md visible-xs-block visible-sm-block ">
//                       {jobsdata.fields.description.slice(0, 100)}...
//                     </div>
//                     <div className="hidden-sm hidden-xs visible-md-block visible-lg-block">
//                       {jobsdata.fields.description.slice(0, 220)}...
//                     </div>
//                   </p>
//                   <div className="d-flex justify-content-between">
//                     <button
//                       className="careerBtn"
//                       onClick={() => {
//                         setOpen(true);
//                         setJobId(jobsdata);
//                       }}
//                     >
//                       APPLY NOW
//                     </button>
//                     <p> {jobsdata.fields.created_at}</p>
//                   </div>
//                   <Modal
//                     title="Apply For The Position"
//                     centered
//                     open={open}
//                     onOk={() => setOpen(false)}
//                     onCancel={() => setOpen(false)}
//                     width={1000}
//                     style={{ marginTop: "70px" }}
//                   >
//                     <CareerForm jobs={jobId} />
//                   </Modal>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </>
//   );
// };

// export default Career;


