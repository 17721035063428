import React, { useContext, createContext, useEffect, useState } from "react";
import "./Header.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FaPlus } from "react-icons/fa";
import Hola9logo from "../images/nav_logo.png";
import TopHeader from "./TopHeader/topHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { location1 } from "../env";
import Mobileheader from "./TopHeader/Mobileheader";
import { RiMapPinLine } from "react-icons/ri";
import { url } from "../env";
import { decrypt } from "./encryptDecrypt/encryptDecrypt";
import { FaSearchLocation } from "react-icons/fa";
import { useGeolocated } from "react-geolocated";
// import { ClipLoader } from "react-spinners";
import axios from "axios";
import { UserContext } from "../../App";
import wishlistSlice, {
  postWishlit,
  removeAllWishlist,
  wishlistGet,
  wishlistUserData,
} from "../../store/wishlistSlice";
import { useDispatch, useSelector } from "react-redux";
import { removeUserData, UserProfile } from "../../store/userIdSlice";
import { typeOf } from "react-is";
// import { wishlistUserData } from "../../services/UserServices";
import { Button, Modal, Space, Tooltip } from "antd";
import user from "../../component/images/user.png";
import { userProfileDataApi } from "../../store/UserProfileDetailsSlice";
import { BsBell } from "react-icons/bs";
import { getUserProfileData } from "../../store/UserProfileDetailsSlice";

import { ExclamationCircleFilled } from "@ant-design/icons";

const ReachableContext = createContext(null);
const UnreachableContext = createContext(null);

const config = {
  title: "Please Allow Location",
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Reachable: ${name}!`}
      </ReachableContext.Consumer>
      <br />
      <UnreachableContext.Consumer>
        {(name) => `Unreachable: ${name}!`}
      </UnreachableContext.Consumer>
    </>
  ),
};
const Header = (props) => {
  const userProfileDetails = useSelector((state) => state.userProfileData);

  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const user1 = useContext(UserContext);
  const [loginTemp, setloginTemp] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    removeAll();
    var myItem = localStorage.getItem("currentLocation");
    localStorage.clear();
    localStorage.setItem("currentLocation", myItem);
    // window.location.reload()
    updateLastLoginTime();
    navigate("/");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  if (props == "true") {
    setloginTemp(true);
  }

  // useEffect(()=>{
  //   window.location.reload()

  // },[])
  var CryptoJS = require("crypto-js");
  const LocationList = location1;
  const [location, setLocation] = useState(null);
  const coords = useGeolocated();
  // const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const [locationFlag, setLocationFlag] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [decryptedData, setdecryptedData] = useState(null);
  const [loaderProfile, setloaderProfile] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const userData = useSelector((state) => state.userIdSlice);
  const profileD = useSelector((state) => state.userProfileData);
  const dispatch = useDispatch();

  const toggleNav = () => {
    setOpenNav(!openNav);
  }
  console.log("user data", userData);
  // ads was not getting visible after a day that why we i commented this api
  // useEffect(() => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(url + "api/adsapi/jobEveryDAYRun", requestOptions)
  //     .then((response) => response.text())
  //     .then((result) => console.log(result))
  //     .catch((error) => console.log("error", error));
  // }, []);
  useEffect(() => {
    console.log(
      localStorage.getItem("currentLocation"),
      typeOf(localStorage.getItem("currentLocation"))
    );
    if (localStorage.getItem("access_token") != null) {
      if (userData.data == null) {
        dispatch(UserProfile(localStorage.getItem("access_token")));
      } else {
        dispatch(wishlistGet(userData?.data?.id));
      }
    }
  }, [userData]);
  useEffect(() => {
    if (
      localStorage.getItem("access_token") != null &&
      wishlistData?.data.length > 0
    ) {
      if (Array.isArray(wishlistData?.data)) {
        dispatch(postWishlit(wishlistData?.data, userData?.data?.id));
      }
    }
  }, [wishlistData?.data]);
  useEffect(() => {
    if (
      localStorage.getItem("currentLocation") &&
      !localStorage.getItem("lat")
    ) {
      setLocationFlag(true);
      const options = {
        method: "GET",
        url: "https://google-maps-geocoding.p.rapidapi.com/geocode/json",
        params: {
          address: localStorage.getItem("currentLocation"),
          language: "en",
        },
        headers: {
          "X-RapidAPI-Key":
            "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
          "X-RapidAPI-Host": "google-maps-geocoding.p.rapidapi.com",
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);

          console.log(
            "lat long ",
            response.data.results[0].geometry.location.lat,
            response.data.results[0].geometry.location.lng
          );
          localStorage.setItem(
            "lat",
            response.data.results[0].geometry.location.lat
          );
          localStorage.setItem(
            "long",
            response.data.results[0].geometry.location.lng
          );
        })
        .catch(function (error) {
          console.error(error);
        });
      // var bytes = CryptoJS.AES.decrypt(localStorage.getItem("currentLocation"), 'my-secret-key@123');
      // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setdecryptedData("bengaluru");
    }
  }, [locationData]);

  // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem("currentLocation")), 'my-secret-key@123').toString();
  // let valueone=JSON.parse((CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123')).toString(CryptoJS.enc.Utf8))

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [idUser, setIDUser] = useState();
  // const [success, setSuccess] = useState("");
  // const [error, setError] = useState("");

  const removeAll = () => {
    dispatch(removeAllWishlist());
    dispatch(removeUserData());
    // dispatch(getUserProfileData(data));
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      setloaderProfile(true);
      // let result=decrypt("userdata")
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(url + "api/user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.errors?.code) {
            alert("token expire");
            localStorage.removeItem("access_token");
          } else {
            setName(result.name);
            setEmail(result.email);
            setIDUser(result.id);
            user1.setUserID(result.id);
            dispatch(userProfileDataApi(result.id));
          }
        })
        .catch((error) => console.log("error", error));
      setloaderProfile(true);
    }
  }, [loginTemp, user1.loginRef]);

  const updateLastLoginTime = () => {
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("user", idUser);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/lastLoginTime", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
      })
      .catch((error) => console.log("error", error));
  };

  const getLocationSearchMethod = () => {
    if (coords?.coords) {
      localStorage.setItem("lat", coords.coords.latitude);
      localStorage.setItem("long", coords.coords.longitude);
      // setLat(coords.coords.latitude)
      // setLong(coords.coords.longitude)
      const url =
        "https://address-from-to-latitude-longitude.p.rapidapi.com/geolocationapi?lat=" +
        coords.coords.latitude +
        "&lng=" +
        coords.coords.longitude;

      const options = {
        method: "GET",
        headers: {
          "X-RapidAPI-Key":
            "fa6911a29amsh90c021191193a8cp14400cjsn0df4fd15aa65",
          "X-RapidAPI-Host":
            "address-from-to-latitude-longitude.p.rapidapi.com",
        },
      };
      // setLoading(true)
      fetch(url, options)
        .then((res) => res.json())
        .then((json) => {
          localStorage.setItem("currentLocation", json.Results[0].city);
          // localStorage.setItem("currentLocation",CryptoJS.AES.encrypt(JSON.stringify({'city':'bengaluru'}), 'my-secret-key@123').toString())
          window.location.reload();

          // setState(json.Results[0].region)
          // setCity(json.Results[0].city)
          // setLocality(json.Results[0].address)
          // setZipcode(json.Results[0].postalcode)
          // setLoading(false)
          // setError(null)
        })
        .catch((err) => console.log("Failed to get location.."));
      // setLoading(false)
    } else {
      modal.info(config);
    }
    // if(coords){

    // }
  };
  //Start Of Wishlist Count
  // var [wishlistmain, setWishlist] = useState([]);
  // useEffect(() => {

  //   if (localStorage.getItem("access_token") != null) {
  //     let barererToken = "Bearer " + localStorage.getItem("access_token");
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", barererToken);
  //     var formdata = new FormData();
  //     formdata.append("wishlist", JSON.parse(decrypt("wishlist")));

  //     var requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: 'follow'
  //     };

  //     fetch("  http://192.168.0.103:8000/api/user/wishlist/", requestOptions)
  //       .then(response => response.json())
  //       .then(result => {
  //         setWishlist(result)
  //       })

  //       .catch(error => console.log('error', error));
  //   }

  // }, [])
  // End of wishlist Count  Done
  // const [toggle, setToggle] = useState(false);

  // const info = () => {
  //   Modal.info({
  //     title: "Hola9 ",
  //     content: (
  //       <div>
  //                   <p>There is no new notification</p>       {" "}
  //       </div>
  //     ),
  //     onOk() {},
  //   });
  // };

  const [isModalVisible, setIsModalVisible] = useState(false);

  // const shownModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleeOk = () => {
    setIsModalVisible(false);
  };

  const handleeCancel = () => {
    setIsModalVisible(false);
  };

  // logout modal
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: "Logout",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to Logout ?",
      okText: "Exit",
      onOk: handleOk,
      onCancel: handleCancel,
    });
  };

  const { pathname } = useLocation();
  // console.log(path);
  return (
    <>
      {/* <TopHeader /> */}
      {/* <Mobileheader /> */}
      {/* <div style={style}>
      <ClipLoader color={"#123abc"} loading={loaderProfile} />
    </div> */}
      <nav className=" navbar sticky-top navbar-expand-lg navbar-light hidden-sm  hidden-xs visible-md-block visible-lg-block"
        style={{ height: "5rem", background: "white" }}
      >
        {/* <div className=" p-0 "> */}
        <div className=" container-fluid d-flex ">
          <Link title="Logo" to="/" className="site__brand__logo">
            <img
              src={Hola9logo}
              style={{ width: "90px", height: "40px" }}
              alt="Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            // aria-expanded="false"
            aria-expanded={`${openNav === true ? true : false}`}
            onClick={toggleNav}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* <div className="nav_ul " > */}
          <div
            className={`collapse navbar-collapse me-auto mx-auto py-2 nav_ul ${openNav ? "d-block" : "d-none"} `}
            id="navbarSupportedContent"
            data-toggle="collapse"
            data-target=".navbar-toggler"
            // style={openNav ? openNavstyle : null}
            style={{ backgroundColor: "white" }}
          >
            <ul
              className="navbar-nav bg-white "
              style={{ margin: "auto" }}
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${pathname === '/' ? "active" : null} thisoneoneon`}
                  aria-current="page"
                  to="/"
                  title="Home"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${pathname === "/career/" ? "active" : null}`} title="Careers" to={"/career/"}>
                  How it Works?
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${pathname === "/contact/" ? "active" : null}`} title="Contact Us" to="/contact/">
                  Swapping Place
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${pathname === "/blogs-listing/" ? "active" : null}`} title="Blog" to="/blogs-listing/">
                  Notification
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${pathname === "/aboutUs/" ? "active" : null}`} to="/aboutUs/" title="About Us">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${pathname === "/match/" ? "active" : null}`} to="/match/" title="Match">
                  Match
                </Link>
              </li>
              {
                localStorage.getItem("access_token") &&
                <li className="nav-item">
                  <Link className={`nav-link ${pathname === "/Dashboard/" ? "active" : null}`} to="/dashboard/" title="Dashboard">
                    Dashboard
                  </Link>
                </li>
              }
            </ul>
            {
              localStorage.getItem("access_token") ?
                <>
                  <Link title="Logo" to="/login/" className="login-register">
                    <button className="btn btn-success" style={{ width: "8rem" }}>Logout</button>
                  </Link>
                </>
                :
                <>
                  <Link title="Logo" to="/signup/" className="login-register bg-white">
                    <button className="btn btn-success" style={{ width: "12rem" }}>login/register</button>
                  </Link>
                </>

            }

          </div>



        </div>
        {/* </div> */}
      </nav>
    </>
  );
};

export const callingloginCheck = () => {
  console.log("callinglogin systeem ");
};

export default Header;


