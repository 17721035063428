export const location1 = [
  "Adilabad",
  "Agra",
  "Ahmedabad",
  "Ahmednagar",
  "Aizawl",
  "Ajitgarh",
  "Mohali",
  "Ajmer",
  "Akola",
  "Alappuzha",
  "Aligarh",
  "Alirajpur",
  "Allahabad",
  "Almora",
  "Alwar",
  "Ambala",
  "Ambedkar",
  "Nagar",
  "Amravati",
  "Amreli",
  "district",
  "Amritsar",
  "Anand",
  "Anantapur",
  "Anantnag",
  "Angul",
  "Anjaw",
  "Anuppur",
  "Araria",
  "Ariyalur",
  "Arwal",
  "Ashok",
  "Nagar",
  "Auraiya",
  "Aurangabad",
  "Aurangabad",
  "Azamgarh",
  "Badgam",
  "Bagalkot",
  "Bageshwar",
  "Bagpat",
  "Bahraich",
  "Baksa",
  "Balaghat",
  "Balangir",
  "Balasore",
  "Ballia",
  "Balrampur",
  "Banaskantha",
  "Banda",
  "Bandipora",
  "Bengaluru",
  "Rural",
  "Urban",
  "Banka",
  "Bankura",
  "Banswara",
  "Barabanki",
  "Baramulla",
  "Baran",
  "Bardhaman",
  "Bareilly",
  "Bargarh",
  "(Baragarh)",
  "Barmer",
  "Barnala",
  "Barpeta",
  "Barwani",
  "Bastar",
  "Basti",
  "Bathinda",
  "Beed",
  "Begusarai",
  "Belgaum",
  "Bellary",
  "Betul",
  "Bhadrak",
  "Bhagalpur",
  "Bhandara",
  "Bharatpur",
  "Bharuch",
  "Bhavnagar",
  "Bhilwara",
  "Bhind",
  "Bhiwani",
  "Bhojpur",
  "Bhopal",
  "Bidar",
  "Bijapur",
  "Bijapur",
  "Bijnor",
  "Bikaner",
  "Bilaspur",
  "Bilaspur",
  "Birbhum",
  "Bishnupur",
  "Bokaro",
  "Bongaigaon",
  "Boudh",
  "(Bauda)",
  "Budaun",
  "Bulandshahr",
  "Buldhana",
  "Bundi",
  "Burhanpur",
  "Buxar",
  "Cachar",
  "Central",
  "Chamarajnagar",
  "Chamba",
  "Chamoli",
  "Champawat",
  "Champhai",
  "Chandauli",
  "Chandel",
  "Chandigarh",
  "Chandrapur",
  "Changlang",
  "Chatra",
  "Chennai",
  "Chhatarpur",
  "Chhatrapati",
  "Shahuji",
  "Maharaj",
  "Nagar",
  "Chhindwara",
  "Chikkaballapur",
  "Chikkamagaluru",
  "Chirang",
  "Chitradurga",
  "Chitrakoot",
  "Chittoor",
  "Chittorgarh",
  "Churachandpur",
  "Churu",
  "Coimbatore",
  "Cooch",
  "Behar",
  "Cuddalore",
  "Cuttack",
  "Dadra",
  "and",
  "Nagar",
  "Haveli",
  "Dahod",
  "Dakshin",
  "Dinajpur",
  "Dakshina",
  "Kannada",
  "Daman",
  "Damoh",
  "Dantewada",
  "Darbhanga",
  "Darjeeling",
  "Darrang",
  "Datia",
  "Dausa",
  "Davanagere",
  "Debagarh",
  "(Deogarh)",
  "Dehradun",
  "Deoghar",
  "Deoria",
  "Dewas",
  "Dhalai",
  "Dhamtari",
  "Dhanbad",
  "Dhar",
  "Dharmapuri",
  "Dharwad",
  "Dhemaji",
  "Dhenkanal",
  "Dholpur",
  "Dhubri",
  "Dhule",
  "Dibang",
  "Valley",
  "Dibrugarh",
  "Dima",
  "Hasao",
  "Dimapur",
  "Dindigul",
  "Dindori",
  "Diu",
  "Doda",
  "Dumka",
  "Dungapur",
  "Durg",
  "East",
  "Champaran",
  "East",
  "East",
  "Garo",
  "Hills",
  "East",
  "Khasi",
  "Hills",
  "East",
  "Siang",
  "East",
  "Sikkim",
  "East",
  "Singhbhum",
  "Eluru",
  "Ernakulam",
  "Erode",
  "Etah",
  "Etawah",
  "Faizabad",
  "Faridabad",
  "Faridkot",
  "Farrukhabad",
  "Fatehabad",
  "Fatehgarh",
  "Sahib",
  "Fatehpur",
  "Fazilka",
  "Firozabad",
  "Firozpur",
  "Gadag",
  "Gadchiroli",
  "Gajapati",
  "Ganderbal",
  "Gandhinagar",
  "Ganganagar",
  "Ganjam",
  "Garhwa",
  "Gautam",
  "Buddh",
  "Nagar",
  "Gaya",
  "Ghaziabad",
  "Ghazipur",
  "Giridih",
  "Goalpara",
  "Godda",
  "Golaghat",
  "Gonda",
  "Gondia",
  "Gopalganj",
  "Gorakhpur",
  "Gulbarga",
  "Gumla",
  "Guna",
  "Guntur",
  "Gurdaspur",
  "Gurgaon",
  "Gwalior",
  "Hailakandi",
  "Hamirpur",
  "Hanumangarh",
  "Harda",
  "Hardoi",
  "Haridwar",
  "Hassan",
  "Haveri",
  "district",
  "Hazaribag",
  "Hingoli",
  "Hissar",
  "Hooghly",
  "Hoshangabad",
  "Hoshiarpur",
  "Howrah",
  "Hyderabad",
  "Idukki",
  "Imphal",
  "East",
  "Imphal",
  "West",
  "Indore",
  "Jabalpur",
  "Jagatsinghpur",
  "Jaintia",
  "Hills",
  "Jaipur",
  "Jaisalmer",
  "Jajpur",
  "Jalandhar",
  "Jalaun",
  "Jalgaon",
  "Jalna",
  "Jalore",
  "Jalpaiguri",
  "Jammu",
  "Jamnagar",
  "Jamtara",
  "Jamui",
  "Janjgir-Champa",
  "Jashpur",
  "Jaunpur",
  "district",
  "Jehanabad",
  "Jhabua",
  "Jhajjar",
  "Jhalawar",
  "Jhansi",
  "Jharsuguda",
  "Jhunjhunu",
  "Jind",
  "Jodhpur",
  "Jorhat",
  "Junagadh",
  "Jyotiba",
  "Phule",
  "Nagar",
  "Kabirdham",
  "(formerly",
  "Kawardha)",
  "Kadapa",
  "Kaimur",
  "Kaithal",
  "Kakinada",
  "Kalahandi",
  "Kamrup",
  "Metropolitan",
  "Kanchipuram",
  "Kandhamal",
  "Kangra",
  "Kanker",
  "Kannauj",
  "Kannur",
  "Kanpur",
  "Kanshi",
  "Ram",
  "Nagar",
  "Kanyakumari",
  "Kapurthala",
  "Karaikal",
  "Karauli",
  "Karbi",
  "Anglong",
  "Kargil",
  "Karimganj",
  "Karimnagar",
  "Karnal",
  "Karur",
  "Kasaragod",
  "Kathua",
  "Katihar",
  "Katni",
  "Kaushambi",
  "Kendrapara",
  "Kendujhar",
  "(Keonjhar)",
  "Khagaria",
  "Khammam",
  "Khandwa",
  "(East",
  "Nimar)",
  "Khargone",
  "(West",
  "Nimar)",
  "Kheda",
  "Khordha",
  "Khowai",
  "Khunti",
  "Kinnaur",
  "Kishanganj",
  "Kishtwar",
  "Kodagu",
  "Koderma",
  "Kohima",
  "Kokrajhar",
  "Kolar",
  "Kolasib",
  "Kolhapur",
  "Kolkata",
  "Kollam",
  "Koppal",
  "Koraput",
  "Korba",
  "Koriya",
  "Kota",
  "Kottayam",
  "Kozhikode",
  "Krishna",
  "Kulgam",
  "Kullu",
  "Kupwara",
  "Kurnool",
  "Kurukshetra",
  "Kurung",
  "Kumey",
  "Kushinagar",
  "Kutch",
  "Lahaul",
  "and",
  "Spiti",
  "Lakhimpur",
  "Lakhimpur",
  "Kheri",
  "Lakhisarai",
  "Lalitpur",
  "Latehar",
  "Latur",
  "Lawngtlai",
  "Leh",
  "Lohardaga",
  "Lohit",
  "Lower",
  "Dibang",
  "Valley",
  "Lower",
  "Subansiri",
  "Lucknow",
  "Ludhiana",
  "Lunglei",
  "Madhepura",
  "Madhubani",
  "Madurai",
  "Mahamaya",
  "Nagar",
  "Maharajganj",
  "Mahasamund",
  "Mahbubnagar",
  "Mahe",
  "Mahendragarh",
  "Mahoba",
  "Mainpuri",
  "Malappuram",
  "Maldah",
  "Malkangiri",
  "Mamit",
  "Mandi",
  "Mandla",
  "Mandsaur",
  "Mandya",
  "Mansa",
  "Marigaon",
  "Mathura",
  "Mau",
  "Mayurbhanj",
  "Medak",
  "Meerut",
  "Mehsana",
  "Mewat",
  "Mirzapur",
  "Moga",
  "Mokokchung",
  "Mon",
  "Moradabad",
  "Morena",
  "Mumbai",
  "City",
  "suburban",
  "Munger",
  "Murshidabad",
  "Muzaffarnagar",
  "Muzaffarpur",
  "Mysore",
  "Nabarangpur",
  "Nadia",
  "Nagaon",
  "Nagapattinam",
  "Nagaur",
  "Nagpur",
  "Nainital",
  "Nalanda",
  "Nalbari",
  "Nalgonda",
  "Namakkal",
  "Nanded",
  "Nandurbar",
  "Narayanpur",
  "Narmada",
  "Narsinghpur",
  "Nashik",
  "Navsari",
  "Nawada",
  "Nawanshahr",
  "Nayagarh",
  "Neemuch",
  "Nellore",
  "New",
  "Nilgiris",
  "Nizamabad",
  "North",
  "Noida",
  "24",
  "Parganas",
  "North",
  "North",
  "East",
  "North",
  "Goa",
  "North",
  "Sikkim",
  "North",
  "Tripura",
  "North",
  "West",
  "Nuapada",
  "Ongole",
  "Osmanabad",
  "Pakur",
  "Palakkad",
  "Palamu",
  "Pali",
  "Palwal",
  "Panchkula",
  "Panchmahal",
  "Panchsheel",
  "Nagar",
  "district",
  "(Hapur)",
  "Panipat",
  "Panna",
  "Papum",
  "Pare",
  "Parbhani",
  "Paschim",
  "Medinipur",
  "Patan",
  "Pathanamthitta",
  "Pathankot",
  "Patiala",
  "Patna",
  "Pauri",
  "Garhwal",
  "Perambalur",
  "Phek",
  "Pilibhit",
  "Pithoragarh",
  "Pondicherry",
  "Poonch",
  "Porbandar",
  "Pratapgarh",
  "Pratapgarh",
  "Pudukkottai",
  "Pulwama",
  "Pune",
  "Purba",
  "Medinipur",
  "Puri",
  "Purnia",
  "Purulia",
  "Raebareli",
  "Raichur",
  "Raigad",
  "Raigarh",
  "Raipur",
  "Raisen",
  "Rajauri",
  "Rajgarh",
  "Rajkot",
  "Rajnandgaon",
  "Rajsamand",
  "Ramabai",
  "Nagar",
  "(Kanpur",
  "Dehat)",
  "Ramanagara",
  "Ramanathapuram",
  "Ramban",
  "Ramgarh",
  "Rampur",
  "Ranchi",
  "Ratlam",
  "Ratnagiri",
  "Rayagada",
  "Reasi",
  "Rewa",
  "Rewari",
  "Ri",
  "Bhoi",
  "Rohtak",
  "Rohtas",
  "Rudraprayag",
  "Rupnagar",
  "Sabarkantha",
  "Sagar",
  "Saharanpur",
  "Saharsa",
  "Sahibganj",
  "Saiha",
  "Salem",
  "Samastipur",
  "Samba",
  "Sambalpur",
  "Sangli",
  "Sangrur",
  "Sant",
  "Kabir",
  "Nagar",
  "Sant",
  "Ravidas",
  "Nagar",
  "Saran",
  "Satara",
  "Satna",
  "Sawai",
  "Madhopur",
  "Sehore",
  "Senapati",
  "Seoni",
  "Seraikela",
  "Kharsawan",
  "Serchhip",
  "Shahdol",
  "Shahjahanpur",
  "Shajapur",
  "Shamli",
  "Sheikhpura",
  "Sheohar",
  "Sheopur",
  "Shimla",
  "Shimoga",
  "Shivpuri",
  "Shopian",
  "Shravasti",
  "Sibsagar",
  "Siddharthnagar",
  "Sidhi",
  "Sikar",
  "Simdega",
  "Sindhudurg",
  "Singrauli",
  "Sirmaur",
  "Sirohi",
  "Sirsa",
  "Sitamarhi",
  "Sitapur",
  "Sivaganga",
  "Siwan",
  "Solan",
  "Solapur",
  "Sonbhadra",
  "Sonipat",
  "Sonitpur",
  "South",
  "24",
  "Parganas",
  "South",
  "South",
  "Garo",
  "Hills",
  "South",
  "Goa",
  "South",
  "Sikkim",
  "South",
  "Tripura",
  "South",
  "West",
  "Sri",
  "Muktsar",
  "Sahib",
  "Srikakulam",
  "Srinagar",
  "Subarnapur",
  "(Sonepur)",
  "Sultanpur",
  "Sundergarh",
  "Supaul",
  "Surat",
  "Surendranagar",
  "Surguja",
  "Tamenglong",
  "Tarn",
  "Taran",
  "Tawang",
  "Tehri",
  "Garhwal",
  "Thane",
  "Thanjavur",
  "The",
  "Dangs",
  "Theni",
  "Thiruvananthapuram",
  "Thoothukudi",
  "Thoubal",
  "Thrissur",
  "Tikamgarh",
  "Tinsukia",
  "Tirap",
  "Tiruchirappalli",
  "Tirunelveli",
  "Tirupur",
  "Tiruvallur",
  "Tiruvannamalai",
  "Tiruvarur",
  "Tonk",
  "Tuensang",
  "Tumkur",
  "Udaipur",
  "Udalguri",
  "Udham",
  "Singh",
  "Nagar",
  "Udhampur",
  "Udupi",
  "Ujjain",
  "Ukhrul",
  "Umaria",
  "Una",
  "Unnao",
  "Upper",
  "Siang",
  "Upper",
  "Subansiri",
  "Uttar",
  "Dinajpur",
  "Uttara",
  "Kannada",
  "Uttarkashi",
  "Vadodara",
  "Vaishali",
  "Valsad",
  "Varanasi",
  "Vellore",
  "Vidisha",
  "Viluppuram",
  "Virudhunagar",
  "Visakhapatnam",
  "Vizianagaram",
  "Vyara",
  "Warangal",
  "Wardha",
  "Washim",
  "Wayanad",
  "West",
  "Champaran",
  "West",
  "Delhi",
  "West",
  "Garo",
  "Hills",
  "West",
  "Kameng",
  "West",
  "Khasi",
  "Hills",
  "West",
  "Siang",
  "West",
  "Sikkim",
  "West",
  "Singhbhum",
  "West",
  "Tripura",
  "Wokha",
  "Yadgir",
  "Yamuna",
  "Nagar",
  "Yanam",
  "Yavatmal",
  "Zunheboto",
];

export const location2 = [
  { value: "Adilabad" },
  { value: "Agra" },
  { value: "Ahmedabad" },
  { value: "Ahmednagar" },
  { value: "Aizawl" },
  { value: "Ajitgarh" },
  { value: "Mohali" },
  { value: "Ajmer" },
  { value: "Akola" },
  { value: "Alappuzha" },
  { value: "Aligarh" },
  { value: "Alirajpur" },
  { value: "Allahabad" },
  { value: "Almora" },
  { value: "Alwar" },
  { value: "Ambala" },
  { value: "Ambedkar" },
  { value: "Amravati" },
  { value: "Amreli" },
  { value: "district" },
  { value: "Amritsar" },
  { value: "Anand" },
  { value: "Anantapur" },
  { value: "Anantnag" },
  { value: "Angul" },
  { value: "Anjaw" },
  { value: "Anuppur" },
  { value: "Araria" },
  { value: "Ariyalur" },
  { value: "Arwal" },
  { value: "Ashok" },
  { value: "Auraiya" },
  { value: "Aurangabad" },
  { value: "Azamgarh" },
  { value: "Badgam" },
  { value: "Bagalkot" },
  { value: "Bageshwar" },
  { value: "Bagpat" },
  { value: "Bahraich" },
  { value: "Baksa" },
  { value: "Balaghat" },
  { value: "Balangir" },
  { value: "Balasore" },
  { value: "Ballia" },
  { value: "Balrampur" },
  { value: "Banaskantha" },
  { value: "Banda" },
  { value: "Bandipora" },
  { value: "Bengaluru" },
  { value: "Rural" },
  { value: "Urban" },
  { value: "Banka" },
  { value: "Bankura" },
  { value: "Banswara" },
  { value: "Barabanki" },
  { value: "Baramulla" },
  { value: "Baran" },
  { value: "Bardhaman" },
  { value: "Bareilly" },
  { value: "Bargarh" },
  { value: "Baragarh" },
  { value: "Barmer" },
  { value: "Barnala" },
  { value: "Barpeta" },
  { value: "Barwani" },
  { value: "Bastar" },
  { value: "Basti" },
  { value: "Bathinda" },
  { value: "Beed" },
  { value: "Begusarai" },
  { value: "Belgaum" },
  { value: "Bellary" },
  { value: "Betul" },
  { value: "Bhadrak" },
  { value: "Bhagalpur" },
  { value: "Bhandara" },
  { value: "Bharatpur" },
  { value: "Bharuch" },
  { value: "Bhavnagar" },
  { value: "Bhilwara" },
  { value: "Bhind" },
  { value: "Bhiwani" },
  { value: "Bhojpur" },
  { value: "Bhopal" },
  { value: "Bidar" },
  { value: "Bijapur" },
  { value: "Bijnor" },
  { value: "Bikaner" },
  { value: "Bilaspur" },
  { value: "Birbhum" },
  { value: "Bishnupur" },
  { value: "Bokaro" },
  { value: "Bongaigaon" },
  { value: "Boudh" },
  { value: "(Bauda)" },
  { value: "Budaun" },
  { value: "Bulandshahr" },
  { value: "Buldhana" },
  { value: "Bundi" },
  { value: "Burhanpur" },
  { value: "Buxar" },
  { value: "Cachar" },
  { value: "Central" },
  { value: "Chamarajnagar" },
  { value: "Chamba" },
  { value: "Chamoli" },
  { value: "Champawat" },
  { value: "Champhai" },
  { value: "Chandauli" },
  { value: "Chandel" },
  { value: "Chandigarh" },
  { value: "Chandrapur" },
  { value: "Changlang" },
  { value: "Chatra" },
  { value: "Chennai" },
  { value: "Chhatarpur" },
  { value: "Chhatrapati" },
  { value: "Shahuji" },
  { value: "Maharaj" },
  { value: "Chhindwara" },
  { value: "Chikkaballapur" },
  { value: "Chikkamagaluru" },
  { value: "Chirang" },
  { value: "Chitradurga" },
  { value: "Chitrakoot" },
  { value: "Chittoor" },
  { value: "Chittorgarh" },
  { value: "Churachandpur" },
  { value: "Churu" },
  { value: "Coimbatore" },
  { value: "Cooch" },
  { value: "Behar" },
  { value: "Cuddalore" },
  { value: "Cuttack" },
  { value: "Dadra" },
  { value: "and" },
  { value: "Haveli" },
  { value: "Dahod" },
  { value: "Dakshin" },
  { value: "Dinajpur" },
  { value: "Dakshina" },
  { value: "Kannada" },
  { value: "Daman" },
  { value: "Damoh" },
  { value: "Dantewada" },
  { value: "Darbhanga" },
  { value: "Darjeeling" },
  { value: "Darrang" },
  { value: "Datia" },
  { value: "Dausa" },
  { value: "Davanagere" },
  { value: "Debagarh" },
  { value: "(Deogarh)" },
  { value: "Dehradun" },
  { value: "Deoghar" },
  { value: "Deoria" },
  { value: "Dewas" },
  { value: "Dhalai" },
  { value: "Dhamtari" },
  { value: "Dhanbad" },
  { value: "Dhar" },
  { value: "Dharmapuri" },
  { value: "Dharwad" },
  { value: "Dhemaji" },
  { value: "Dhenkanal" },
  { value: "Dholpur" },
  { value: "Dhubri" },
  { value: "Dhule" },
  // { value: "Dibang" },
  { value: "Valley" },
  { value: "Dibrugarh" },
  { value: "Dima" },
  { value: "Hasao" },
  { value: "Dimapur" },
  { value: "Dindigul" },
  { value: "Dindori" },
  { value: "Diu" },
  { value: "Doda" },
  { value: "Dumka" },
  { value: "Dungapur" },
  { value: "Durg" },
  { value: "Champaran" },
  { value: "Garo" },
  { value: "Khasi" },
  { value: "Hills" },
  { value: "Siang" },
  { value: "Sikkim" },
  { value: "Singhbhum" },
  { value: "Eluru" },
  { value: "Ernakulam" },
  { value: "Erode" },
  { value: "Etah" },
  { value: "Etawah" },
  { value: "Faizabad" },
  { value: "Faridabad" },
  { value: "Faridkot" },
  { value: "Farrukhabad" },
  { value: "Fatehabad" },
  { value: "Fatehgarh" },
  { value: "Sahib" },
  { value: "Fatehpur" },
  { value: "Fazilka" },
  { value: "Firozabad" },
  { value: "Firozpur" },
  { value: "Gadag" },
  { value: "Gadchiroli" },
  { value: "Gajapati" },
  { value: "Ganderbal" },
  { value: "Gandhinagar" },
  { value: "Ganganagar" },
  { value: "Ganjam" },
  { value: "Garhwa" },
  { value: "Gautam" },
  { value: "Buddh" },
  { value: "Gaya" },
  { value: "Ghaziabad" },
  { value: "Ghazipur" },
  { value: "Giridih" },
  { value: "Goalpara" },
  { value: "Godda" },
  { value: "Golaghat" },
  { value: "Gonda" },
  { value: "Gondia" },
  { value: "Gopalganj" },
  { value: "Gorakhpur" },
  { value: "Gulbarga" },
  { value: "Gumla" },
  { value: "Guna" },
  { value: "Guntur" },
  { value: "Gurdaspur" },
  { value: "Gurgaon" },
  { value: "Gwalior" },
  { value: "Hailakandi" },
  { value: "Hamirpur" },
  { value: "Hanumangarh" },
  { value: "Harda" },
  { value: "Hardoi" },
  { value: "Haridwar" },
  { value: "Hassan" },
  { value: "Haveri" },
  { value: "district" },
  { value: "Hazaribag" },
  { value: "Hingoli" },
  { value: "Hissar" },
  { value: "Hooghly" },
  { value: "Hoshangabad" },
  { value: "Hoshiarpur" },
  { value: "Howrah" },
  { value: "Hyderabad" },
  { value: "Idukki" },
  { value: "Imphal" },
  { value: "West" },
  { value: "Indore" },
  { value: "Jabalpur" },
  { value: "Jagatsinghpur" },
  { value: "Jaintia" },
  { value: "Jaipur" },
  { value: "Jaisalmer" },
  { value: "Jajpur" },
  { value: "Jalandhar" },
  { value: "Jalaun" },
  { value: "Jalgaon" },
  { value: "Jalna" },
  { value: "Jalore" },
  { value: "Jalpaiguri" },
  { value: "Jammu" },
  { value: "Jamnagar" },
  { value: "Jamtara" },
  { value: "Jamui" },
  { value: "Janjgir-Champa" },
  { value: "Jashpur" },
  { value: "Jaunpur" },
  { value: "district" },
  { value: "Jehanabad" },
  { value: "Jhabua" },
  { value: "Jhajjar" },
  { value: "Jhalawar" },
  { value: "Jhansi" },
  { value: "Jharsuguda" },
  { value: "Jhunjhunu" },
  { value: "Jind" },
  { value: "Jodhpur" },
  { value: "Jorhat" },
  { value: "Junagadh" },
  { value: "Jyotiba" },
  { value: "Phule" },
  { value: "Kabirdham" },
  { value: "(formerly" },
  { value: "Kawardha)" },
  { value: "Kadapa" },
  { value: "Kaimur" },
  { value: "Kaithal" },
  { value: "Kakinada" },
  { value: "Kalahandi" },
  { value: "Kamrup" },
  { value: "Metropolitan" },
  { value: "Kanchipuram" },
  { value: "Kandhamal" },
  { value: "Kangra" },
  { value: "Kanker" },
  { value: "Kannauj" },
  { value: "Kannur" },
  { value: "Kanpur" },
  { value: "Kanshi" },
  { value: "Ram" },
  { value: "Kanyakumari" },
  { value: "Kapurthala" },
  { value: "Karaikal" },
  { value: "Karauli" },
  { value: "Karbi" },
  { value: "Anglong" },
  { value: "Kargil" },
  { value: "Karimganj" },
  { value: "Karimnagar" },
  { value: "Karnal" },
  { value: "Karur" },
  { value: "Kasaragod" },
  { value: "Kathua" },
  { value: "Katihar" },
  { value: "Katni" },
  { value: "Kaushambi" },
  { value: "Kendrapara" },
  { value: "Kendujhar" },
  { value: "(Keonjhar)" },
  { value: "Khagaria" },
  { value: "Khammam" },
  { value: "Khandwa" },
  { value: "Nimar" },
  { value: "Khargone" },
  { value: "(West-Nimar)" },
  { value: "Kheda" },
  { value: "Khordha" },
  { value: "Khowai" },
  { value: "Khunti" },
  { value: "Kinnaur" },
  { value: "Kishanganj" },
  { value: "Kishtwar" },
  { value: "Kodagu" },
  { value: "Koderma" },
  { value: "Kohima" },
  { value: "Kokrajhar" },
  { value: "Kolar" },
  { value: "Kolasib" },
  { value: "Kolhapur" },
  { value: "Kolkata" },
  { value: "Kollam" },
  { value: "Koppal" },
  { value: "Koraput" },
  { value: "Korba" },
  { value: "Koriya" },
  { value: "Kota" },
  { value: "Kottayam" },
  { value: "Kozhikode" },
  { value: "Krishna" },
  { value: "Kulgam" },
  { value: "Kullu" },
  { value: "Kupwara" },
  { value: "Kurnool" },
  { value: "Kurukshetra" },
  { value: "Kurung" },
  { value: "Kumey" },
  { value: "Kushinagar" },
  { value: "Kutch" },
  { value: "Lahaul" },
  { value: "and" },
  { value: "Spiti" },
  { value: "Lakhimpur" },
  { value: "Kheri" },
  { value: "Lakhisarai" },
  { value: "Lalitpur" },
  { value: "Latehar" },
  { value: "Latur" },
  { value: "Lawngtlai" },
  { value: "Leh" },
  { value: "Lohardaga" },
  { value: "Lohit" },
  { value: "Dibang" },
  { value: "Valley" },
  { value: "Lower" },
  { value: "Subansiri" },
  { value: "Lucknow" },
  { value: "Ludhiana" },
  { value: "Lunglei" },
  { value: "Madhepura" },
  { value: "Madhubani" },
  { value: "Madurai" },
  { value: "Mahamaya" },
  { value: "Maharajganj" },
  { value: "Mahasamund" },
  { value: "Mahbubnagar" },
  { value: "Mahe" },
  { value: "Mahendragarh" },
  { value: "Mahoba" },
  { value: "Mainpuri" },
  { value: "Malappuram" },
  { value: "Maldah" },
  { value: "Malkangiri" },
  { value: "Mamit" },
  { value: "Mandi" },
  { value: "Mandla" },
  { value: "Mandsaur" },
  { value: "Mandya" },
  { value: "Mansa" },
  { value: "Marigaon" },
  { value: "Mathura" },
  { value: "Mau" },
  { value: "Mayurbhanj" },
  { value: "Medak" },
  { value: "Meerut" },
  { value: "Mehsana" },
  { value: "Mewat" },
  { value: "Mirzapur" },
  { value: "Moga" },
  { value: "Mokokchung" },
  { value: "Mon" },
  { value: "Moradabad" },
  { value: "Morena" },
  { value: "Mumbai" },
  { value: "City" },
  { value: "suburban" },
  { value: "Munger" },
  { value: "Murshidabad" },
  { value: "Muzaffarnagar" },
  { value: "Muzaffarpur" },
  { value: "Mysore" },
  { value: "Nabarangpur" },
  { value: "Nadia" },
  { value: "Nagaon" },
  { value: "Nagapattinam" },
  { value: "Nagaur" },
  { value: "Nagpur" },
  { value: "Nainital" },
  { value: "Nalanda" },
  { value: "Nalbari" },
  { value: "Nalgonda" },
  { value: "Namakkal" },
  { value: "Nanded" },
  { value: "Nandurbar" },
  { value: "Narayanpur" },
  { value: "Narmada" },
  { value: "Narsinghpur" },
  { value: "Nashik" },
  { value: "Navsari" },
  { value: "Nawada" },
  { value: "Nawanshahr" },
  { value: "Nayagarh" },
  { value: "Neemuch" },
  { value: "Nellore" },
  { value: "New" },
  { value: "Nilgiris" },
  { value: "Nizamabad" },
  { value: "Noida" },
  { value: "24" },
  { value: "Parganas" },
  { value: "North" },
  { value: "Goa" },
  { value: "Sikkim" },
  { value: "Tripura" },
  { value: "Nuapada" },
  { value: "Ongole" },
  { value: "Osmanabad" },
  { value: "Pakur" },
  { value: "Palakkad" },
  { value: "Palamu" },
  { value: "Pali" },
  { value: "Palwal" },
  { value: "Panchkula" },
  { value: "Panchmahal" },
  { value: "Panchsheel" },
  { value: "(district-Hapur)" },
  { value: "Panipat" },
  { value: "Panna" },
  { value: "Papum" },
  { value: "Pare" },
  { value: "Parbhani" },
  { value: "Paschim" },
  { value: "Medinipur" },
  { value: "Patan" },
  { value: "Pathanamthitta" },
  { value: "Pathankot" },
  { value: "Patiala" },
  { value: "Patna" },
  { value: "Pauri" },
  { value: "Garhwal" },
  { value: "Perambalur" },
  { value: "Phek" },
  { value: "Pilibhit" },
  { value: "Pithoragarh" },
  { value: "Pondicherry" },
  { value: "Poonch" },
  { value: "Porbandar" },
  { value: "Pratapgarh" },
  { value: "Pudukkottai" },
  { value: "Pulwama" },
  { value: "Pune" },
  { value: "Purba" },
  { value: "Medinipur" },
  { value: "Puri" },
  { value: "Purnia" },
  { value: "Purulia" },
  { value: "Raebareli" },
  { value: "Raichur" },
  { value: "Raigad" },
  { value: "Raigarh" },
  { value: "Raipur" },
  { value: "Raisen" },
  { value: "Rajauri" },
  { value: "Rajgarh" },
  { value: "Rajkot" },
  { value: "Rajnandgaon" },
  { value: "Rajsamand" },
  { value: "Ramabai" },
  { value: "(Nagar-Kanpur" },
  { value: "Dehat)" },
  { value: "Ramanagara" },
  { value: "Ramanathapuram" },
  { value: "Ramban" },
  { value: "Ramgarh" },
  { value: "Rampur" },
  { value: "Ranchi" },
  { value: "Ratlam" },
  { value: "Ratnagiri" },
  { value: "Rayagada" },
  { value: "Reasi" },
  { value: "Rewa" },
  { value: "Rewari" },
  { value: "Ri" },
  { value: "Bhoi" },
  { value: "Rohtak" },
  { value: "Rohtas" },
  { value: "Rudraprayag" },
  { value: "Rupnagar" },
  { value: "Sabarkantha" },
  { value: "Sagar" },
  { value: "Saharanpur" },
  { value: "Saharsa" },
  { value: "Sahibganj" },
  { value: "Saiha" },
  { value: "Salem" },
  { value: "Samastipur" },
  { value: "Samba" },
  { value: "Sambalpur" },
  { value: "Sangli" },
  { value: "Sangrur" },
  { value: "Sant" },
  { value: "Kabir" },
  { value: "Ravidas" },
  { value: "Saran" },
  { value: "Satara" },
  { value: "Satna" },
  { value: "Sawai" },
  { value: "Madhopur" },
  { value: "Sehore" },
  { value: "Senapati" },
  { value: "Seoni" },
  { value: "Seraikela" },
  { value: "Kharsawan" },
  { value: "Serchhip" },
  { value: "Shahdol" },
  { value: "Shahjahanpur" },
  { value: "Shajapur" },
  { value: "Shamli" },
  { value: "Sheikhpura" },
  { value: "Sheohar" },
  { value: "Sheopur" },
  { value: "Shimla" },
  { value: "Shimoga" },
  { value: "Shivpuri" },
  { value: "Shopian" },
  { value: "Shravasti" },
  { value: "Sibsagar" },
  { value: "Siddharthnagar" },
  { value: "Sidhi" },
  { value: "Sikar" },
  { value: "Simdega" },
  { value: "Sindhudurg" },
  { value: "Singrauli" },
  { value: "Sirmaur" },
  { value: "Sirohi" },
  { value: "Sirsa" },
  { value: "Sitamarhi" },
  { value: "Sitapur" },
  { value: "Sivaganga" },
  { value: "Siwan" },
  { value: "Solan" },
  { value: "Solapur" },
  { value: "Sonbhadra" },
  { value: "Sonipat" },
  { value: "Sonitpur" },
  { value: "24" },
  { value: "Parganas" },
  { value: "Garo" },
  { value: "Goa" },
  { value: "Sikkim" },
  { value: "South" },
  { value: "Tripura" },
  { value: "West" },
  { value: "Sri" },
  { value: "Muktsar" },
  { value: "Nagar" },
  { value: "Sahib" },
  { value: "Srikakulam" },
  { value: "Srinagar" },
  { value: "(Subarnapur-Sonepur)" },
  { value: "Sultanpur" },
  { value: "Sundergarh" },
  { value: "Supaul" },
  { value: "Surat" },
  { value: "Surendranagar" },
  { value: "Surguja" },
  { value: "Tamenglong" },
  { value: "Tarn" },
  { value: "Taran" },
  { value: "Tawang" },
  { value: "Tehri" },
  { value: "Garhwal" },
  { value: "Thane" },
  { value: "Thanjavur" },
  { value: "The" },
  { value: "Dangs" },
  { value: "Theni" },
  { value: "Thiruvananthapuram" },
  { value: "Thoothukudi" },
  { value: "Thoubal" },
  { value: "Thrissur" },
  { value: "Tikamgarh" },
  { value: "Tinsukia" },
  { value: "Tirap" },
  { value: "Tiruchirappalli" },
  { value: "Tirunelveli" },
  { value: "Tirupur" },
  { value: "Tiruvallur" },
  { value: "Tiruvannamalai" },
  { value: "Tiruvarur" },
  { value: "Tonk" },
  { value: "Tuensang" },
  { value: "Tumkur" },
  { value: "Udaipur" },
  { value: "Udalguri" },
  { value: "Udham" },
  { value: "Singh" },
  { value: "Udhampur" },
  { value: "Udupi" },
  { value: "Ujjain" },
  { value: "Ukhrul" },
  { value: "Umaria" },
  { value: "Una" },
  { value: "Unnao" },
  { value: "Upper" },
  { value: "Siang" },
  { value: "Subansiri" },
  { value: "Uttar" },
  { value: "Dinajpur" },
  { value: "Uttara" },
  { value: "Kannada" },
  { value: "Uttarkashi" },
  { value: "Vadodara" },
  { value: "Vaishali" },
  { value: "Valsad" },
  { value: "Varanasi" },
  { value: "Vellore" },
  { value: "Vidisha" },
  { value: "Viluppuram" },
  { value: "Virudhunagar" },
  { value: "Visakhapatnam" },
  { value: "Vizianagaram" },
  { value: "Vyara" },
  { value: "Warangal" },
  { value: "Wardha" },
  { value: "Washim" },
  { value: "Wayanad" },
  { value: "Champaran" },
  { value: "Delhi" },
  { value: "Garo" },
  { value: "Kameng" },
  { value: "Khasi" },
  { value: "Siang" },
  { value: "Sikkim" },
  { value: "Singhbhum" },
  { value: "West" },
  { value: "Tripura" },
  { value: "Wokha" },
  { value: "Yadgir" },
  { value: "Yamuna" },
  { value: "Yanam" },
  { value: "Yavatmal" },
  { value: "Zunheboto" },
];

export const category1 = [
  "Electronics",
  "Cars",
  "Furniture",
  "Travels",
  "Household",
  "Mobile & Laptops",
  "Travelling",
  "Mobiles",
  "RealEstate",
  "Services",
  // "Jobs",
  "Cars",
  "Bikes",
  "Health",
  "Photography",
];
//for prod
// export const localUrl = "https://hola9backend.pythonanywhere.com/api/"
// export const url = "https://hola9backend.pythonanywhere.com/"
const listby = ["Owner", "Dealer"]; // f,m.c,bi.e.  r-agent owner - job - freelancer employyer pets -mdealer individaul

// for local
export const localUrl = "https://keybackend.gravitonweb.com/api/";
export const url = "https://keybackend.gravitonweb.com/";

// export const localUrl = "http://139.59.26.221:8000/api/"

// export const url = "http://139.59.26.221:8000/"

// export const localUrl = "http://139.59.26.221:8000/api/";
// export const url = "http://139.59.26.221:8000/";

// export const localUrl = "http://192.168.1.17:8000/api/";
// export const url =  "http://192.168.1.17:8000/api/";

export const state = ["West Bengal", "Goa"];
export const cityConst = ["Bengaluru", "Pune", "Mumbai"];
export const access_token = localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : null;
export const subcategoryType1 = ["Residential", "Commercial"];
export const subcategoryType2 = ["Buy", "Rent"];

// Sub Categoty Fridge
export const fridgeDoorStyle = [
  "Multi Door​",
  "Side By side​",
  "Single Door",
  "Triple Door​",
  "Freezer On Top",
];
export const FridgeCondition = [
  "Brand New",
  "Gently Used",
  "Heavily used",
  "Unboxed",
];
export const FridgeBrandName = [
  "Bosch",
  "BuyerZone",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Others",
];
export const FridgeCapacity = [
  "Upto 120L",
  "120L-200L",
  "200L-300L",
  "300L-400L",
  "400L & Above",
];

// Subcategory Washing Machines
export const WashingMachineBrand = [
  "Bosch",
  "BuyerZone",
  "Whirlpool",
  "Lioyd",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Voltas",
  "Videcon",
  "Others",
];
export const WashingMachineCapacity = [
  "Upto 5.9kg",
  "6kg -6.9kg",
  "7kg-7.9kg",
  "8kgs & Above",
];
export const WashingMachineCondition = ["Brand New", "Fair", "Good", "Unboxed"];
export const WashingmachineLoad = ["Front Load", "Top Load"];

// Subcategory Air Coolers​
export const AirCoolerBrand = [
  "Bajaj",
  "BMQ",
  "Whirlpool",
  "Cello",
  "Blue Star",
  "Havells",
  "Hindware",
  "Hotstar",
  "Kelvinator ",
  "Kenstar",
  "Kiran",
  "Voltas",
  "Khaitan",
  "Kunstomcon",
  "Orient",
  "Mccoy",
  "Others",
];
export const AirCoolerCondition = [
  "Brand New",
  "Gently Used",
  "Heavily used",
  "Unboxed",
];
export const AirCoolerCapacity = [
  "11L-15L",
  "16L-20L",
  "200L-300L",
  "21L-40L",
  "400L & Above",
];

// AirConditioner
export const ACBrand = [
  "Bosch",
  "BuyerZone",
  "Whirlpool",
  "Lioyd",
  "D Pex",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "L.G",
  "Intex",
  "Voltas",
  "Videcon",
  "Others",
];
export const ACType = ["Window", "Split"];
export const ACCondition = [
  "Brand New",
  "Gently Used​",
  "Heavily used",
  "Unboxed",
];
export const ACTonnage = [
  "1Ton",
  "1.25Ton",
  "1.5Ton",
  "1.8Ton",
  "2.0Ton & Above",
];

// Water Heaters / Geysers​
export const GyserBrand = [
  "Havells",
  "Apson",
  "Arise",
  "Bajaj",
  "Onida​",
  "Panasonic",
  "Bala",
  "LG ",
  "Blue Me ",
  "Eazy",
  "Florex",
  "Genius",
  "Happy Home​",
  "Others",
];
export const HeaterType = ["InstantWater Heatere", "Storage Water Heater​"];
export const GyserCondition = [
  "Brand New",
  "Gently Used​",
  "Heavily used",
  "Unboxed​",
];
export const HeaterCapacity = [
  "11L-15L​",
  "16L-20L​",
  "21L-50L​",
  "50L-100L",
  "100L &Above​",
];
// Laptops
export const LaptopBrand = [
  "IBM",
  "Lenovo",
  "Hp",
  "Dell",
  "Asus",
  "Panasonic",
  "Samsung",
  "Realmi",
  "Zenith",
  "Sharp",
  "Others",
];
export const ProductType = ["Desktop", "Laptop"];
export const LaptopCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Accessories
export const PeripheralsType = [
  "Battery",
  "Head Phones",
  "Mouse",
  "Speaker",
  "Usb Drive",
  "Access Point",
  "CPU",
  "Cooling pad",
  "Data Card",
  "Headset",
  "Keyboard",
  "Network Card",
  "Printer",
  "Ram​",
  "Router​",
  "Scanner​",
  "Webcam​",
  "USB Wireless Adaptor​",
  "Others",
];
export const PeripheralCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Water Purifiers
export const WPBrand = [
  "Havells",
  "Aquaguard",
  "kent",
  "Livpure",
  "Others",
  "Panasonic",
  "Bala",
  "LG",
  "Blue Me",
  "panasonic",
];
export const WPCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

//Microwave Ovens
export const MOvenBrand = ["LG", "Onida", "Panasonic", "Samsung", "Others"];
export const MOvenType = ["Convection", "Grill", "Solo"];
export const MOvenCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
export const MOvenCapacity = [
  "Upto 20L​",
  "21L-25L​​",
  "21L-50L​",
  ".26L-30L​",
  "31L & Above​​",
];

// Mixer / Grinder / Juicer
export const GrinderBrand = [
  "Activa",
  "Asian",
  "Aditi",
  "Amazing Bullet",
  "Ameet",
  "Anjani",
  "Apex",
  "Arise",
  "BEC",
  "BTL",
  "Bala",
  "Blue me",
  "Bluesky",
  "Bonzeal",
  "Boss",
  "Borosil",
  "Butterfly",
  "Cpex",
  "Others",
];
export const GrinderCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Induction Cook Tops
export const InductionBrand = [
  "Greenchef",
  "Havells",
  "Hindware",
  "Hotstar",
  "Hilex",
  "Jaipan",
  "Jindal",
  "Kenwood",
  "Longer",
  "Nova",
  "Orbit",
  "Orbon",
  "Oster",
  "Ovastar",
  "Phillips",
  "pigeon",
  "Others",
];
export const InductionCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Gas Stove​
export const GasStoveBrand = [
  "Skyline",
  "Usha",
  "Skyflame",
  "Wonder World",
  "Wonder Chef",
  "Surya",
  "Quba",
  "Prestige",
  "Apex",
  "Longer",
  "Nova",
  "Orbit",
  "Orbon",
  "oster",
  "Ovastar",
  "Other",
  "Phillips",
  "pigeon",
  "Others",
];
export const GasStoveCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// Cameras – Digicams or Digital SLR OR Digital Point & Shoot Cameras
export const CameraBrand = [
  "Canon",
  "Nikon",
  "Panasonic",
  "Samsung",
  "Sony",
  "Olympus",
  "Flip",
  "Casio",
  "Leica",
  "Others",
];
export const CameraProductType = [
  "Camcorders",
  "Binoculars",
  "Telescopes",
  "Digital SLR",
  "Film Cameras",
];
export const CameraCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

//Camera Accessories
export const CamAccessories = [
  "Battery Charger",
  "Camera Bag",
  "Filter",
  "Lens",
  "Lens Cleaner",
  "Flash",
  "Lens Cap",
  "Memory Card",
  "Strap",
  "Others",
];
export const CamAccessoriesCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// TV
export const TvDisplayType = [
  "CRT",
  "Liquid Crystal Display (LCD)",
  "Plasma Display Panels",
  "Organic Light-Emitting Diode (OLED) ",
  "Quantum Light Emitting Diode (QLED)",
  "4K TVs",
  "8K TVs",
  "Flat TVs",
  "Curved TVs",
];
export const TVCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];
export const TVBrand = [
  "LG",
  "Phillips",
  "videocon",
  "Samsung",
  "Onida",
  "Panasonic",
  "Sony",
  "BPL",
  "BenQ",
  "Others",
];
export const ScreenSize = [
  "Upto 23inch",
  "24inch-31inch",
  "32inch-39inch",
  "40icnh-47inch",
  "48inch-54inch",
  "55inch&Above",
];
export const Resolution = [
  "Full HD",
  "HD Ready",
  "Standard Definition(SD)",
  "Ultra HD(4K)",
  "WXGA",
];
export const HDMIPorts = ["1", "2", "3", "4 and Above"];

// Video games
export const VGBrand = [
  "Microsoft Xbox",
  "Nintendo Game boy",
  "Nintendo wii",
  "PC games",
  "SonyPSP",
  "Sony Play Statio",
  "Nokia N-gage",
  "others",
];
export const VGCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

// music system-home theater
export const MSBrand = [
  "Canon",
  "Nikon",
  "Panasonic",
  "Samsung",
  "Sony",
  "Olympus",
  "Flip ",
  "Casio",
  "Kodak",
  "Leica",
  "Others",
];
export const SpeakerType = [
  "Bluetooth Speakers",
  "Blueshelf speakers",
  "Home Theatre systems",
  "Outdoor Speakers",
  "Over Ear Head phones",
  "Sound bar speakers",
];
export const MSCondition = [
  "Brand New",
  "Gently Used",
  "Heavily Used",
  "Unboxed",
];

export const subcategoryRealEstateBuy = [
  "Buy-Residential",
  "Rent-Residential",
  "PG-Hostel",
];
export const subcategoryRealEstateRent = ["Buy-Commercial", "Rent-Commercial"];
export const All = [
  "apartment",
  "field",
  "building",
  "home",
  "PG-Hostel",
  "flats",
  "rentals",
  "properties",
  "houses",
  "BHKType",
  "price",
  "hostels",
  "mensPg",
  "mensFlatMates",
  "ladiesPg",
  "ladiesFlatMates",
];
export const ProductCondition = ["Good", "Excellent", "Fair"];
export const NoOfBathrooms = ["1", "2", "3", "4"];
export const ListedBy = ["Indivisual", "Dealer"];
export const Facing = ["east", "west", "north", "south"];

export const locationData = location1;

export const RealEsateAllDetails = {
  BuildUpArea: ["1sqr", "2sqr"],
  Flor: [
    "1 Flor",
    "2Flor",
    "3Flor",
    "4Flor",
    "5Flor",
    "6Flor",
    "7Flor",
    ">7Flor",
  ],
  ApartMentType: ["1bhk", "2bhk", "3bhk"],
  Availability: ["readyToMove", "UnderConstruction", "Within_15days"],
  FurnishedType: ["FullFurnished", "Semi Furnished", "None"],
  Property: ["New", "<1year", "<2Year", "<3year+"],
  Parking: ["Bike", "Car"],

  PowerBackup: ["yes", "No"],
  Amenity: {
    Gym: ["yes", "No"],
    Garden: ["yes", "No"],
    Pool: ["yes", "No"],
    Lift: ["yes", "No"],
  },
};
// export const stateMain = [
//   "Andaman & Nicobar Islands",
//   "Andhra Pradesh",
//   "Arunachal Pradesh",
//   "Assam",
//   "Bihar",
//   "Chandigarh",
//   "Chhattisgarh",
//   "Dadra & Nagar Haveli",
//   "Daman and Diu",
//   "Delhi",
//   "Goa",
//   "Gujarat",
//   "Haryana",
//   "Himachal Pradesh",
//   "Jammu & Kashmir",
//   "Jharkhand",
//   "Karnataka",
//   "Kerala",
//   "Lakshadweep",
//   "Madhya Pradesh",
//   "Maharashtra",
//   "Manipur",
//   "Meghalaya",
//   "Mizoram",
//   "Nagaland",
//   "Odisha",
//   "Puducherry",
//   "Punjab",
//   "Rajasthan",
//   "Sikkim",
//   "Tamil Nadu",
//   "Telangana",
//   "Tripura",
//   "Uttarkhand",
//   "Uttar Pradesh",
//   "West Bengal",
// ];

//all state and city getting map from herer

export const stateMain = {
  "Andaman and Nicobar Islands": [
    "Bamboo Flat",
    "Nicobar",
    "Port Blair",
    "South Andaman",
  ],
  "Andhra Pradesh": [
    "Addanki",
    "Adoni",
    "Akasahebpet",
    "Akividu",
    "Akkarampalle",
    "Amalapuram",
    "Amudalavalasa",
    "Anakapalle",
    "Anantapur",
    "Atmakur",
    "Attili",
    "Avanigadda",
    "Badvel",
    "Banganapalle",
    "Bapatla",
    "Betamcherla",
    "Bhattiprolu",
    "Bhimavaram",
    "Bhimunipatnam",
    "Bobbili",
    "Challapalle",
    "Chemmumiahpet",
    "Chilakalurupet",
    "Chinnachowk",
    "Chipurupalle",
    "Chirala",
    "Chittoor",
    "Chodavaram",
    "Cuddapah",
    "Cumbum",
    "Darsi",
    "Dharmavaram",
    "Dhone",
    "Diguvametta",
    "East Godavari",
    "Elamanchili",
    "Ellore",
    "Emmiganur",
    "Erraguntla",
    "Etikoppaka",
    "Gajuwaka",
    "Ganguvada",
    "Gannavaram",
    "Giddalur",
    "Gokavaram",
    "Gorantla",
    "Govindapuram,Chilakaluripet,Guntur",
    "Gudivada",
    "Gudlavalleru",
    "Gudur",
    "Guntakal Junction",
    "Guntur",
    "Hindupur",
    "Ichchapuram",
    "Jaggayyapeta",
    "Jammalamadugu",
    "Kadiri",
    "Kaikalur",
    "Kakinada",
    "Kalyandurg",
    "Kamalapuram",
    "Kandukur",
    "Kanigiri",
    "Kankipadu",
    "Kanuru",
    "Kavali",
    "Kolanukonda",
    "Kondapalle",
    "Korukollu",
    "Kosigi",
    "Kovvur",
    "Krishna",
    "Kuppam",
    "Kurnool",
    "Macherla",
    "Machilipatnam",
    "Madanapalle",
    "Madugula",
    "Mandapeta",
    "Mandasa",
    "Mangalagiri",
    "Markapur",
    "Nagari",
    "Nagireddipalli",
    "Nandigama",
    "Nandikotkur",
    "Nandyal",
    "Narasannapeta",
    "Narasapur",
    "Narasaraopet",
    "Narasingapuram",
    "Narayanavanam",
    "Narsipatnam",
    "Nayudupet",
    "Nellore",
    "Nidadavole",
    "Nuzvid",
    "Ongole",
    "Pakala",
    "Palakollu",
    "Palasa",
    "Palkonda",
    "Pallevada",
    "Palmaner",
    "Parlakimidi",
    "Parvatipuram",
    "Pavuluru",
    "Pedana",
    "pedda nakkalapalem",
    "Peddapuram",
    "Penugonda",
    "Penukonda",
    "Phirangipuram",
    "Pippara",
    "Pithapuram",
    "Polavaram",
    "Ponnur",
    "Ponnuru",
    "Prakasam",
    "Proddatur",
    "Pulivendla",
    "Punganuru",
    "Puttaparthi",
    "Puttur",
    "Rajahmundry",
    "Ramachandrapuram",
    "Ramanayyapeta",
    "Ramapuram",
    "Rampachodavaram",
    "Rayachoti",
    "Rayadrug",
    "Razam",
    "Razampeta",
    "Razole",
    "Renigunta",
    "Repalle",
    "Salur",
    "Samalkot",
    "Sattenapalle",
    "Singarayakonda",
    "Sompeta",
    "Srikakulam",
    "Srisailain",
    "Suluru",
    "Tadepalle",
    "Tadepallegudem",
    "Tadpatri",
    "Tanuku",
    "Tekkali",
    "Tirumala",
    "Tirupati",
    "Tuni",
    "Uravakonda",
    "vadlamuru",
    "Vadlapudi",
    "Venkatagiri",
    "Vepagunta",
    "Vetapalem",
    "Vijayawada",
    "Vinukonda",
    "Visakhapatnam",
    "Vizianagaram",
    "Vizianagaram District",
    "Vuyyuru",
    "West Godavari",
    "Yanam",
    "Yanamalakuduru",
    "Yarada",
  ],
  "Arunachal Pradesh": [
    "Along",
    "Anjaw",
    "Basar",
    "Bomdila",
    "Changlang",
    "Dibang Valley",
    "East Kameng",
    "East Siang",
    "Hayuliang",
    "Itanagar",
    "Khonsa",
    "Kurung Kumey",
    "Lohit District",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Margherita",
    "Naharlagun",
    "Pasighat",
    "Tawang",
    "Tezu",
    "Tirap",
    "Upper Siang",
    "Upper Subansiri",
    "West Kameng",
    "West Siang",
    "Ziro",
  ],
  Assam: [
    "Abhayapuri",
    "Amguri",
    "Badarpur",
    "Baksa",
    "Barpathar",
    "Barpeta",
    "Barpeta Road",
    "Basugaon",
    "Bihpuriagaon",
    "Bijni",
    "Bilasipara",
    "Bokajan",
    "Bokakhat",
    "Bongaigaon",
    "Cachar",
    "Chabua",
    "Chapar",
    "Chirang",
    "Darrang",
    "Dergaon",
    "Dhekiajuli",
    "Dhemaji",
    "Dhing",
    "Dhubri",
    "Dibrugarh",
    "Digboi",
    "Dima Hasao District",
    "Diphu",
    "Dispur",
    "Duliagaon",
    "Dum Duma",
    "Gauripur",
    "Goalpara",
    "Gohpur",
    "Golaghat",
    "Golakganj",
    "Goshaingaon",
    "Guwahati",
    "Haflong",
    "Hailakandi",
    "Hajo",
    "Hojai",
    "Howli",
    "Jogighopa",
    "Jorhat",
    "Kamrup",
    "Kamrup Metropolitan",
    "Karbi Anglong",
    "Karimganj",
    "Kharupatia",
    "Kokrajhar",
    "Lakhimpur",
    "Lakhipur",
    "Lala",
    "Lumding Railway Colony",
    "Mahur",
    "Maibong",
    "Makum",
    "Mangaldai",
    "Mariani",
    "Moranha",
    "Morigaon",
    "Nagaon",
    "Nahorkatiya",
    "Nalbari",
    "Namrup",
    "Nazira",
    "North Guwahati",
    "North Lakhimpur",
    "Numaligarh",
    "Palasbari",
    "Raha",
    "Rangapara",
    "Rangia",
    "Sapatgram",
    "Sarupathar",
    "Sibsagar",
    "Silapathar",
    "Silchar",
    "Soalkuchi",
    "Sonari",
    "Sonitpur",
    "Sorbhog",
    "Tezpur",
    "Tinsukia",
    "Titabar",
    "Udalguri",
  ],
  Bihar: [
    "Amarpur",
    "Araria",
    "Arrah",
    "Arwal",
    "Asarganj",
    "Aurangabad",
    "Bagaha",
    "Bahadurganj",
    "Bairagnia",
    "Baisi",
    "Bakhtiyarpur",
    "Bangaon",
    "Banka",
    "Banmankhi",
    "Bar Bigha",
    "Barauli",
    "Barh",
    "Barhiya",
    "Bariarpur",
    "Baruni",
    "Begusarai",
    "Belsand",
    "Bettiah",
    "Bhabhua",
    "Bhagalpur",
    "Bhagirathpur",
    "Bhawanipur",
    "Bhojpur",
    "Bihar Sharif",
    "Bihariganj",
    "Bikramganj",
    "Birpur",
    "Buddh Gaya",
    "Buxar",
    "Chakia",
    "Chapra",
    "Chhatapur",
    "Colgong",
    "Dalsingh Sarai",
    "Darbhanga",
    "Daudnagar",
    "Dehri",
    "Dhaka",
    "Dighwara",
    "Dinapore",
    "Dumra",
    "Dumraon",
    "Fatwa",
    "Forbesganj",
    "Gaya",
    "Ghoga",
    "Gopalganj",
    "Hajipur",
    "Hilsa",
    "Hisua",
    "Islampur",
    "Jagdispur",
    "Jahanabad",
    "Jamalpur",
    "Jamui",
    "Jaynagar",
    "Jehanabad",
    "Jha-Jha",
    "Jhanjharpur",
    "Jogbani",
    "Kaimur District",
    "Kasba",
    "Katihar",
    "Khagaria",
    "Khagaul",
    "Kharagpur",
    "Khusropur",
    "Kishanganj",
    "Koath",
    "Koelwar",
    "Lakhisarai",
    "Lalganj",
    "Luckeesarai",
    "Madhepura",
    "Madhipura",
    "Madhubani",
    "Maharajgani",
    "Mairwa",
    "Maner",
    "Manihari",
    "Marhaura",
    "Masaurhi Buzurg",
    "Mohiuddinnagar",
    "Mokameh",
    "Monghyr",
    "Mothihari",
    "Munger",
    "Murliganj",
    "Muzaffarpur",
    "Nabinagar",
    "Nalanda",
    "Nasriganj",
    "Naugachhia",
    "Nawada",
    "Nirmali",
    "Pashchim Champaran",
    "Patna",
    "Piro",
    "Pupri",
    "Purba Champaran",
    "Purnia",
    "Rafiganj",
    "Raghunathpur",
    "Rajgir",
    "Ramnagar",
    "Raxaul",
    "Revelganj",
    "Rohtas",
    "Rusera",
    "Sagauli",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Shahbazpur",
    "Shahpur",
    "Sheikhpura",
    "Sheohar",
    "Sherghati",
    "Silao",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Teghra",
    "Tekari",
    "Thakurganj",
    "Vaishali",
    "Waris Aliganj",
  ],
  Chandigarh: ["Chandigarh"],

  "Dadra and Nagar Haveli": [
    "Amli",
    "Dadra",
    "Dadra & Nagar Haveli",
    "Silvassa",
  ],
  "Daman and Diu": ["Daman", "Daman District", "Diu"],

  Delhi: [
    "Alipur",
    "Bawana",
    "Central Delhi",
    "Delhi",
    "Deoli",
    "East Delhi",
    "Karol Bagh",
    "Najafgarh",
    "Nangloi Jat",
    "Narela",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Pitampura",
    "Rohini",
    "South Delhi",
    "South West Delhi",
    "West Delhi",
  ],
  Goa: [
    "Aldona",
    "Arambol",
    "Baga",
    "Bambolim",
    "Bandora",
    "Benaulim",
    "Calangute",
    "Candolim",
    "Carapur",
    "Cavelossim",
    "Chicalim",
    "Chinchinim",
    "Colovale",
    "Colva",
    "Cortalim",
    "Cuncolim",
    "Curchorem",
    "Curti",
    "Davorlim",
    "Dicholi",
    "Goa Velha",
    "Guirim",
    "Jua",
    "Kankon",
    "Madgaon",
    "Mapuca",
    "Morjim",
    "Mormugao",
    "Navelim",
    "North Goa",
    "Palle",
    "Panaji",
    "Pernem",
    "Ponda",
    "Quepem",
    "Queula",
    "Raia",
    "Saligao",
    "Sancoale",
    "Sanguem",
    "Sanquelim",
    "Sanvordem",
    "Serula",
    "Solim",
    "South Goa",
    "Taleigao",
    "Vagator",
    "Valpoy",
    "Varca",
    "Vasco da Gama",
  ],
  Gujarat: [
    "Abrama",
    "Adalaj",
    "Ahmedabad",
    "Ahwa",
    "Amod",
    "Amreli",
    "Amroli",
    "Anand",
    "Anjar",
    "Ankleshwar",
    "Babra",
    "Bagasara",
    "Bagasra",
    "Banas Kantha",
    "Bantva",
    "Bardoli",
    "Bedi",
    "Bhachau",
    "Bhanvad",
    "Bharuch",
    "Bhavnagar",
    "Bhayavadar",
    "Bhuj",
    "Bilimora",
    "Bilkha",
    "Borsad",
    "Botad",
    "Chaklasi",
    "Chalala",
    "Chanasma",
    "Chhala",
    "Chhota Udepur",
    "Chikhli",
    "Chotila",
    "Dabhoi",
    "Dahegam",
    "Dahod",
    "Dakor",
    "Damnagar",
    "Dangs (India)",
    "Dayapar",
    "Delvada",
    "Delwada",
    "Devbhumi Dwarka",
    "Devgadh Bariya",
    "Dhandhuka",
    "Dhanera",
    "Dharampur",
    "Dhari",
    "Dhola",
    "Dholka",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Dhuwaran",
    "Disa",
    "Dohad",
    "Dungarpur",
    "Dwarka",
    "Gadhada",
    "Gandevi",
    "Gandhidham",
    "Gandhinagar",
    "Gariadhar",
    "Ghogha",
    "Gir Somnath",
    "Godhra",
    "Gondal",
    "Halol",
    "Halvad",
    "Hansot",
    "Harij",
    "Himatnagar",
    "Jalalpore",
    "Jalalpur",
    "Jambusar",
    "Jamnagar",
    "Jasdan",
    "Jetalsar",
    "Jetpur",
    "Jhulasan",
    "Jodhpur",
    "Jodia",
    "Jodiya Bandar",
    "Junagadh",
    "Kachchh",
    "Kadi",
    "Kadod",
    "Kalavad",
    "Kalol",
    "Kandla",
    "Kanodar",
    "Kapadvanj",
    "Karamsad",
    "Kathor",
    "Katpur",
    "Kavant",
    "Kawant",
    "Keshod",
    "Khambhalia",
    "Khambhat",
    "Kheda",
    "Khedbrahma",
    "Kheralu",
    "Kodinar",
    "Kosamba",
    "Kundla",
    "Kutch district",
    "Kutiyana",
    "Lakhtar",
    "Lalpur",
    "Lathi",
    "Limbdi",
    "Lunavada",
    "Mahemdavad",
    "Mahesana",
    "Mahudha",
    "Malpur",
    "Manavadar",
    "Mandal",
    "Mandvi",
    "Mandvi (Surat)",
    "Mangrol",
    "Mansa",
    "Meghraj",
    "Mehsana",
    "Mendarda",
    "Modasa",
    "Morbi",
    "Morva (Hadaf)",
    "Morwa",
    "Mundra",
    "Nadiad",
    "Naliya",
    "Narmada",
    "Naroda",
    "Navsari",
    "Okha",
    "Olpad",
    "Paddhari",
    "Padra",
    "Palanpur",
    "Palitana",
    "Paliyad",
    "Panch Mahals",
    "Panchmahal district",
    "Pardi",
    "Parnera",
    "Patan",
    "Pavi Jetpur",
    "Petlad",
    "Porbandar",
    "Radhanpur",
    "Rajkot",
    "Rajpipla",
    "Rajula",
    "Ranavav",
    "Rapar",
    "Roha",
    "Sabar Kantha",
    "Sachin",
    "Salaya",
    "Sanand",
    "Sankheda",
    "Sarkhej",
    "Savarkundla",
    "Sayla",
    "Shahpur",
    "Shivrajpur",
    "Siddhpur",
    "Sihor",
    "Sikka",
    "Sinor",
    "Sojitra",
    "Songadh",
    "Surat",
    "Surendranagar",
    "Talaja",
    "Tankara",
    "Tapi",
    "Than",
    "Thangadh",
    "Tharad",
    "Thasra",
    "The Dangs",
    "Umrala",
    "Umreth",
    "Un",
    "Una",
    "Unjha",
    "Upleta",
    "Utran",
    "Vadnagar",
    "Vadodara",
    "Vaghodia",
    "Vallabh Vidyanagar",
    "Vallabhipur",
    "Valsad",
    "Vansda",
    "Vapi",
    "Vartej",
    "Vasa",
    "Vaso",
    "Vejalpur",
    "Veraval",
    "Vijapur",
    "Vinchhiya",
    "Vinchia",
    "Virpur",
    "Visavadar",
    "Visnagar",
    "Vyara",
    "Wadhai",
    "Wadhwan",
    "Waghai",
    "Wankaner",
  ],
  Haryana: [
    "Ambala",
    "Asandh",
    "Ateli Mandi",
    "Bahadurgarh",
    "Bara Uchana",
    "Barwala",
    "Bawal",
    "Beri Khas",
    "Bhiwani",
    "Bilaspur",
    "Buriya",
    "Charkhi Dadri",
    "Chhachhrauli",
    "Dabwali",
    "Dharuhera",
    "Ellenabad",
    "Faridabad",
    "Faridabad District",
    "Farrukhnagar",
    "Fatehabad",
    "Fatehabad District",
    "Firozpur Jhirka",
    "Gharaunda",
    "Gohana",
    "Gorakhpur",
    "Gurgaon",
    "Hansi",
    "Hasanpur",
    "Hisar",
    "Hodal",
    "Inda Chhoi",
    "Indri",
    "Jagadhri",
    "Jakhal",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Kalanaur",
    "Kalanwali",
    "Kanina Khas",
    "Karnal",
    "Kharkhauda",
    "Kheri Sampla",
    "Kurukshetra",
    "Ladwa",
    "Loharu",
    "Maham",
    "Mahendragarh",
    "Mandholi Kalan",
    "Mustafabad",
    "Narayangarh",
    "Narnaul",
    "Narnaund",
    "Narwana",
    "Nilokheri",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Pataudi",
    "Pehowa",
    "Pinjaur",
    "Punahana",
    "Pundri",
    "Radaur",
    "Rania",
    "Ratia",
    "Rewari",
    "Rewari District",
    "Rohtak",
    "Safidon",
    "Samalkha",
    "Shadipur Julana",
    "Shahabad",
    "Sirsa",
    "Sohna",
    "Sonipat",
    "Taoru",
    "Thanesar",
    "Tohana",
    "Tosham",
    "Uklana",
    "Yamunanagar",
  ],
  "Himachal Pradesh": [
    // Himachal Pradesh
    "Arki",
    "Baddi",
    "Banjar",
    "Bilaspur",
    "Chamba",
    "Chaupal",
    "Chowari",
    "Chuari Khas",
    "Dagshai",
    "Dalhousie",
    "Daulatpur",
    "Dera Gopipur",
    "Dharamsala",
    "Gagret",
    "Ghumarwin",
    "Hamirpur",
    "Jawala Mukhi",
    "Jogindarnagar",
    "Jubbal",
    "Jutogh",
    "Kalka",
    "Kangar",
    "Kangra",
    "Kasauli",
    "Kinnaur",
    "Kotkhai",
    "Kotla",
    "Kulu",
    "Kyelang",
    "Lahul and Spiti",
    "Manali",
    "Mandi",
    "Nadaun",
    "Nagar",
    "Nagrota",
    "Nahan",
    "Nalagarh",
    "Palampur",
    "Pandoh",
    "Paonta Sahib",
    "Parwanoo",
    "Rajgarh",
    "Rampur",
    "Rohru",
    "Sabathu",
    "Santokhgarh",
    "Sarahan",
    "Sarka Ghat",
    "Seoni",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Sundarnagar",
    "Theog",
    "Tira Sujanpur",
    "Una",
    "Yol",
  ],
  "Jammu and Kashmir": [
    // Jammu and Kashmir

    "Akhnur",
    "Anantnag",
    "Awantipur",
    "Badgam",
    "Bandipore",
    "Bandipura",
    "Banihal",
    "Baramula",
    "Batoti",
    "Bhadarwah",
    "Bijbehara",
    "Bishnah",
    "Doda",
    "Gandarbal",
    "Ganderbal",
    "Gho Brahmanan de",
    "Hajan",
    "Hiranagar",
    "Jammu",
    "Jaurian",
    "Kathua",
    "Katra",
    "Khaur",
    "Kishtwar",
    "Kud",
    "Kulgam",
    "Kupwara",
    "Ladakh",
    "Magam",
    "Nawanshahr",
    "Noria",
    "Padam",
    "Pahlgam",
    "Parol",
    "Pattan",
    "Pulwama",
    "Punch",
    "Qazigund",
    "Rajaori",
    "Rajauri",
    "Ramban",
    "Ramgarh",
    "Ramnagar",
    "Riasi",
    "Samba",
    "Shupiyan",
    "Sopur",
    "Soyibug",
    "Srinagar",
    "Sumbal",
    "Thang",
    "Thanna Mandi",
    "Tral",
    "Tsrar Sharif",
    "Udhampur",
    "Uri",
  ],
  Jharkhand: [
    // Jharkhand

    "Bagra",
    "Barka Kana",
    "Barki Saria",
    "Barwadih",
    "Bhojudih",
    "Bokaro",
    "Bundu",
    "Chaibasa",
    "Chakradharpur",
    "Chakulia",
    "Chandil",
    "Chas",
    "Chatra",
    "Chiria",
    "Daltonganj",
    "Deogarh",
    "Dhanbad",
    "Dhanwar",
    "Dugda",
    "Dumka",
    "Garhwa",
    "Ghatsila",
    "Giridih",
    "Gobindpur",
    "Godda",
    "Gomoh",
    "Gopinathpur",
    "Gua",
    "Gumia",
    "Gumla",
    "Hazaribag",
    "Hazaribagh",
    "Hesla",
    "Husainabad",
    "Jagannathpur",
    "Jamadoba",
    "Jamshedpur",
    "Jamtara",
    "Jasidih",
    "Jharia",
    "Jugsalai",
    "Jumri Tilaiya",
    "Kalikapur",
    "Kandra",
    "Kanke",
    "Katras",
    "Kenduadih",
    "Kharsawan",
    "Khunti",
    "Kodarma",
    "Kuju",
    "Latehar",
    "Lohardaga",
    "Madhupur",
    "Malkera",
    "Manoharpur",
    "Mugma",
    "Mushabani",
    "Neturhat",
    "Nirsa",
    "Noamundi",
    "Pakur",
    "Palamu",
    "Pashchim Singhbhum",
    "patamda",
    "Pathardih",
    "Purba Singhbhum",
    "Ramgarh",
    "Ranchi",
    "Ray",
    "Sahibganj",
    "Saraikela",
    "Sarubera",
    "Sijua",
    "Simdega",
    "Sini",
    "Topchanchi",
  ],
  Karnataka: [
    "Afzalpur",
    "Ajjampur",
    "Aland",
    "Alnavar",
    "Alur",
    "Anekal",
    "Ankola",
    "Annigeri",
    "Arkalgud",
    "Arsikere",
    "Athni",
    "Aurad",
    "Badami",
    "Bagalkot",
    "Bagepalli",
    "Bail-Hongal",
    "Ballari",
    "Banavar",
    "Bengaluru",
    "Bangarapet",
    "Bannur",
    "Bantval",
    "Basavakalyan",
    "Basavana Bagevadi",
    "Belgaum",
    "Bellary",
    "Belluru",
    "Beltangadi",
    "Belur",
    "Bhadravati",
    "Bhalki",
    "Bhatkal",
    "Bidar",
    "Bijapur",
    "Bilgi",
    "Birur",
    "Byadgi",
    "Byndoor",
    "Canacona",
    "Challakere",
    "Chamrajnagar",
    "Channagiri",
    "Channapatna",
    "Channarayapatna",
    "Chik Ballapur",
    "Chikkaballapur",
    "Chikmagalur",
    "Chiknayakanhalli",
    "Chikodi",
    "Chincholi",
    "Chintamani",
    "Chitapur",
    "Chitradurga",
    "Closepet",
    "Coondapoor",
    "Dakshina Kannada",
    "Dandeli",
    "Davanagere",
    "Devanhalli",
    "Dharwad",
    "Dod Ballapur",
    "French Rocks",
    "Gadag",
    "Gadag-Betageri",
    "Gajendragarh",
    "Gangawati",
    "Gangolli",
    "Gokak",
    "Gokarna",
    "Goribidnur",
    "Gorur",
    "Gubbi",
    "Gudibanda",
    "Gulbarga",
    "Guledagudda",
    "Gundlupēt",
    "Gurmatkal",
    "Hadagalli",
    "Haliyal",
    "Hampi",
    "Hangal",
    "Harihar",
    "Harpanahalli",
    "Hassan",
    "Haveri",
    "Heggadadevankote",
    "Hirekerur",
    "Hiriyur",
    "Holalkere",
    "Hole Narsipur",
    "Homnabad",
    "Honavar",
    "Honnali",
    "Hosanagara",
    "Hosangadi",
    "Hosdurga",
    "Hoskote",
    "Hospet",
    "Hubli",
    "Hukeri",
    "Hungund",
    "Hunsur",
    "Ilkal",
    "Indi",
    "Jagalur",
    "Jamkhandi",
    "Jevargi",
    "Kadur",
    "Kalghatgi",
    "Kampli",
    "Kankanhalli",
    "Karkala",
    "Karwar",
    "Kavalur",
    "Kerur",
    "Khanapur",
    "Kodagu",
    "Kodigenahalli",
    "Kodlipet",
    "Kolar",
    "Kollegal",
    "Konanur",
    "Konnur",
    "Koppa",
    "Koppal",
    "Koratagere",
    "Kotturu",
    "Krishnarajpet",
    "Kudachi",
    "Kudligi",
    "Kumsi",
    "Kumta",
    "Kundgol",
    "Kunigal",
    "Kurgunta",
    "Kushalnagar",
    "Kushtagi",
    "Lakshmeshwar",
    "Lingsugur",
    "Londa",
    "Maddagiri",
    "Maddur",
    "Madikeri",
    "Magadi",
    "Mahalingpur",
    "Malavalli",
    "Malpe",
    "Malur",
    "Mandya",
    "Mangalore",
    "Manipal",
    "Manvi",
    "Mayakonda",
    "Melukote",
    "Mudbidri",
    "Muddebihal",
    "Mudgal",
    "Mudgere",
    "Mudhol",
    "Mulbagal",
    "Mulgund",
    "Mulki",
    "Mundargi",
    "Mundgod",
    "Munirabad",
    "Murudeshwara",
    "Mysore",
    "Nagamangala",
    "Nanjangud",
    "Narasimharajapura",
    "Naregal",
    "Nargund",
    "Navalgund",
    "Nelamangala",
    "Nyamti",
    "Pangala",
    "Pavugada",
    "Piriyapatna",
    "Ponnampet",
    "Puttur",
    "Rabkavi",
    "Raichur",
    "Ramanagara",
    "Ranibennur",
    "Raybag",
    "Robertsonpet",
    "Ron",
    "Sadalgi",
    "Sagar",
    "Sakleshpur",
    "Sandur",
    "Sanivarsante",
    "Sankeshwar",
    "Sargur",
    "Saundatti",
    "Savanur",
    "Seram",
    "Shahabad",
    "Shahpur",
    "Shiggaon",
    "Shikarpur",
    "Shimoga",
    "Shirhatti",
    "Shorapur",
    "Shrirangapattana",
    "Siddapur",
    "Sidlaghatta",
    "Sindgi",
    "Sindhnur",
    "Sira",
    "Sirsi",
    "Siruguppa",
    "Someshwar",
    "Somvarpet",
    "Sorab",
    "Sravana Belgola",
    "Sringeri",
    "Srinivaspur",
    "Sulya",
    "Suntikoppa",
    "Talikota",
    "Tarikere",
    "Tekkalakote",
    "Terdal",
    "Tiptur",
    "Tirthahalli",
    "Tirumakudal Narsipur",
    "Tumkur",
    "Turuvekere",
    "Udupi",
    "Ullal",
    "Uttar Kannada",
    "Vadigenhalli",
    "Virarajendrapet",
    "Wadi",
    "Yadgir",
    "Yelahanka",
    "Yelandur",
    "Yelbarga",
    "Yellapur",
  ],
  Kerala: [
    "Adur",
    "Alappuzha",
    "Aluva",
    "Alwaye",
    "Angamali",
    "Aroor",
    "Arukutti",
    "Attingal",
    "Avanoor",
    "Azhikkal",
    "Badagara",
    "Beypore",
    "Changanacheri",
    "Chēlakara",
    "Chengannur",
    "Cherpulassery",
    "Cherthala",
    "Chetwayi",
    "Chittur",
    "Cochin",
    "Dharmadam",
    "Edakkulam",
    "Elur",
    "Erattupetta",
    "Ernakulam",
    "Ferokh",
    "Guruvayur",
    "Idukki",
    "Iringal",
    "Irinjalakuda",
    "Kadakkavoor",
    "Kalamassery",
    "Kalavoor",
    "Kalpatta",
    "Kannangad",
    "Kannavam",
    "Kannur",
    "Kasaragod",
    "Kasaragod District",
    "Kattanam",
    "Kayankulam",
    "Kizhake Chalakudi",
    "Kodungallur",
    "Kollam",
    "Kotamangalam",
    "Kottayam",
    "Kovalam",
    "Kozhikode",
    "Kumbalam",
    "Kunnamangalam",
    "Kunnamkulam",
    "Kunnumma",
    "Kutiatodu",
    "Kuttampuzha",
    "Lalam",
    "Mahē",
    "Malappuram",
    "Manjeri",
    "Manjēshvar",
    "Mannarakkat",
    "Marayur",
    "Mattanur",
    "Mavelikara",
    "Mavoor",
    "Muluppilagadu",
    "Munnar",
    "Muvattupula",
    "Muvattupuzha",
    "Nadapuram",
    "Naduvannur",
    "Nedumangad",
    "Neyyattinkara",
    "Nilēshwar",
    "Ottappalam",
    "Palackattumala",
    "Palakkad district",
    "Palghat",
    "Panamaram",
    "Pappinisshēri",
    "Paravur Tekkumbhagam",
    "Pariyapuram",
    "Pathanamthitta",
    "Pattanamtitta",
    "Payyannur",
    "Perumbavoor",
    "Perumpavur",
    "Perya",
    "Piravam",
    "Ponmana",
    "Ponnani",
    "Punalur",
    "Ramamangalam",
    "Shertallai",
    "Shōranur",
    "Talipparamba",
    "Tellicherry",
    "Thanniyam",
    "Thiruvananthapuram",
    "Thrissur",
    "Thrissur District",
    "Tirur",
    "Tiruvalla",
    "Vaikam",
    "Varkala",
    "Vayalar",
    "Vettur",
    "Wayanad",
  ],
  Ladakh: ["Kargil", "Leh"],
  Lakshadweep: [
    // Lakshadweep

    "Kavaratti",
    "Lakshadweep",
  ],
  "Madhya Pradesh": [
    // Madhya Pradesh
    "Agar",
    "Ajaigarh",
    "Akodia",
    "Alampur",
    "Alirajpur",
    "Alot",
    "Amanganj",
    "Amarkantak",
    "Amarpatan",
    "Amarwara",
    "Ambah",
    "Amla",
    "Anjad",
    "Antri",
    "Anuppur",
    "Aron",
    "Ashoknagar",
    "Ashta",
    "Babai",
    "Badarwas",
    "Badnawar",
    "Bag",
    "Bagli",
    "Baihar",
    "Baikunthpur",
    "Bakshwaho",
    "Balaghat",
    "Baldeogarh",
    "Bamna",
    "Bamor Kalan",
    "Bamora",
    "Banda",
    "Barela",
    "Barghat",
    "Bargi",
    "Barhi",
    "Barwani",
    "Basoda",
    "Begamganj",
    "Beohari",
    "Berasia",
    "Betma",
    "Betul",
    "Betul Bazar",
    "Bhabhra",
    "Bhainsdehi",
    "Bhander",
    "Bhanpura",
    "Bhawaniganj",
    "Bhikangaon",
    "Bhind",
    "Bhitarwar",
    "Bhopal",
    "Biaora",
    "Bijawar",
    "Bijrauni",
    "Bodri",
    "Burhanpur",
    "Burhar",
    "Chanderi",
    "Chandia",
    "Chandla",
    "Chhatarpur",
    "Chhindwara",
    "Chichli",
    "Chorhat",
    "Daboh",
    "Dabra",
    "Damoh",
    "Datia",
    "Deori Khas",
    "Depalpur",
    "Dewas",
    "Dhamnod",
    "Dhana",
    "Dhar",
    "Dharampuri",
    "Dindori",
    "Etawa",
    "Gadarwara",
    "Garha Brahman",
    "Garhakota",
    "Gautampura",
    "Ghansor",
    "Gogapur",
    "Gohadi",
    "Govindgarh",
    "Guna",
    "Gurh",
    "Gwalior",
    "Harda",
    "Harda Khas",
    "Harpalpur",
    "Harrai",
    "Harsud",
    "Hatod",
    "Hatta",
    "Hindoria",
    "Hoshangabad",
    "Iawar",
    "Ichhawar",
    "Iklehra",
    "Indore",
    "Isagarh",
    "Itarsi",
    "Jabalpur",
    "Jaisinghnagar",
    "Jaithari",
    "Jamai",
    "Jaora",
    "Jatara",
    "Jawad",
    "Jhabua",
    "Jiran",
    "Jobat",
    "Kailaras",
    "Kaimori",
    "Kannod",
    "Kareli",
    "Karera",
    "Karrapur",
    "Kasrawad",
    "Katangi",
    "Katni",
    "Khachrod",
    "Khailar",
    "Khajuraho Group of Monuments",
    "Khamaria",
    "Khandwa",
    "Khandwa district",
    "Khargapur",
    "Khargone",
    "Khategaon",
    "Khilchipur",
    "Khirkiyan",
    "Khujner",
    "Khurai",
    "Kolaras",
    "Korwai",
    "Kotar",
    "Kothi",
    "Kotma",
    "Kotwa",
    "Kukshi",
    "Kumbhraj",
    "Lahar",
    "Lakhnadon",
    "Leteri",
    "Lodhikheda",
    "Machalpur",
    "Madhogarh",
    "Maheshwar",
    "Mahgawan",
    "Maihar",
    "Majholi",
    "Maksi",
    "Malhargarh",
    "Manasa",
    "Manawar",
    "Mandideep",
    "Mandla",
    "Mandleshwar",
    "Mandsaur",
    "Mangawan",
    "Manpur",
    "Mau",
    "Mauganj",
    "Mihona",
    "Mohgaon",
    "Morar",
    "Morena",
    "Multai",
    "Mundi",
    "Mungaoli",
    "Murwara",
    "Nagda",
    "Nagod",
    "Naigarhi",
    "Nainpur",
    "Namli",
    "Naraini",
    "Narayangarh",
    "Narsimhapur",
    "Narsinghgarh",
    "Narwar",
    "Nasrullahganj",
    "Neemuch",
    "Nepanagar",
    "Orchha",
    "Pachmarhi",
    "Palera",
    "Pali",
    "Panagar",
    "Panara",
    "Pandhana",
    "Pandhurna",
    "Panna",
    "Pansemal",
    "Parasia",
    "Patan",
    "Patharia",
    "Pawai",
    "Petlawad",
    "Piploda",
    "Pithampur",
    "Porsa",
    "Punasa",
    "Raghogarh",
    "Rahatgarh",
    "Raisen",
    "Rajgarh",
    "Rajnagar",
    "Rajpur",
    "Rampura",
    "Ranapur",
    "Ratangarh",
    "Ratlam",
    "Rehli",
    "Rehti",
    "Rewa",
    "Sabalgarh",
    "Sagar",
    "Sailana",
    "Sanawad",
    "Sanchi",
    "Sanwer",
    "Sarangpur",
    "Satna",
    "Satwas",
    "Saugor",
    "Sausar",
    "Sehore",
    "Sendhwa",
    "Seondha",
    "Seoni",
    "Seoni Malwa",
    "Shahdol",
    "Shahgarh",
    "Shahpur",
    "Shahpura",
    "Shajapur",
    "Shamgarh",
    "Sheopur",
    "Shivpuri",
    "Shujalpur",
    "Sidhi",
    "Sihora",
    "Simaria",
    "Singoli",
    "Singrauli",
    "Sirmaur",
    "Sironj",
    "Sitamau",
    "Sohagi",
    "Sohagpur",
    "Sultanpur",
    "Susner",
    "Tal",
    "Talen",
    "Tarana",
    "Tekanpur",
    "Tendukheda",
    "Teonthar",
    "Thandla",
    "Tikamgarh",
    "Tirodi",
    "Udaipura",
    "Ujjain",
    "Ukwa",
    "Umaria",
    "Umaria District",
    "Umri",
    "Unhel",
    "Vidisha",
    "Waraseoni",
  ],
  Maharashtra: [
    // Maharashtra

    "Achalpur",
    "Ahiri",
    "Ahmadnagar",
    "Ahmadpur",
    "Airoli",
    "Ajra",
    "Akalkot",
    "Akola",
    "Akot",
    "Alandi",
    "Alibag",
    "Allapalli",
    "Amalner",
    "Amarnath",
    "Ambad",
    "Ambajogai",
    "Amravati",
    "Amravati Division",
    "Anjangaon",
    "Anshing",
    "Arangaon",
    "Artist Village",
    "Arvi",
    "Ashta",
    "Ashti",
    "Aurangabad",
    "Ausa",
    "Badlapur",
    "Balapur",
    "Ballalpur",
    "Baramati",
    "Barsi",
    "Basmat",
    "Beed",
    "Bhandara",
    "Bhayandar",
    "Bhigvan",
    "Bhiwandi",
    "Bhor",
    "Bhudgaon",
    "Bhum",
    "Bhusaval",
    "Bid",
    "Biloli",
    "Boisar",
    "Borivli",
    "Buldana",
    "Chakan",
    "Chalisgaon",
    "Chanda",
    "Chandor",
    "Chandrapur",
    "Chandur",
    "Chandur Bazar",
    "Chicholi",
    "Chikhli",
    "Chinchani",
    "Chiplun",
    "Chopda",
    "Dabhol",
    "Dahanu",
    "Darwha",
    "Daryapur",
    "Dattapur",
    "Daulatabad",
    "Daund",
    "Dehu",
    "Deolali",
    "Deoli",
    "Deulgaon Raja",
    "Dharangaon",
    "Dharmabad",
    "Dharur",
    "Dhule",
    "Dhulia",
    "Diglur",
    "Digras",
    "Dombivli",
    "Dondaicha",
    "Dudhani",
    "Durgapur",
    "Erandol",
    "Faizpur",
    "Gadchiroli",
    "Gadhinglaj",
    "Gangakher",
    "Gangapur",
    "Gevrai",
    "Ghatanji",
    "Ghoti Budrukh",
    "Ghugus",
    "Gondiya",
    "Goregaon",
    "Guhagar",
    "Hadgaon",
    "Harnai",
    "Hinganghat",
    "Hingoli",
    "Hirapur Hamesha",
    "Ichalkaranji",
    "Igatpuri",
    "Indapur",
    "Jaisingpur",
    "Jalgaon",
    "Jalgaon Jamod",
    "Jalna",
    "Jawhar",
    "Jejuri",
    "Jintur",
    "Junnar",
    "Kagal",
    "Kalamb",
    "Kalamnuri",
    "Kalas",
    "Kalmeshwar",
    "Kalundri",
    "Kalyan",
    "Kamthi",
    "Kandri",
    "Kankauli",
    "Kannad",
    "Karad",
    "Karanja",
    "Karjat",
    "Karmala",
    "Kati",
    "Katol",
    "Khadki",
    "Khamgaon",
    "Khapa",
    "Kharakvasla",
    "Khed",
    "Khetia",
    "Khopoli",
    "Khuldabad",
    "Kinwat",
    "Kodoli",
    "Kolhapur",
    "Kondalwadi",
    "Kopargaon",
    "Koradi",
    "Koregaon",
    "Koynanagar",
    "Kudal",
    "Kurandvad",
    "Kurduvadi",
    "Lanja",
    "Lasalgaon",
    "Latur",
    "Lohogaon",
    "Lonar",
    "Lonavla",
    "Mahabaleshwar",
    "Mahad",
    "Maindargi",
    "Majalgaon",
    "Makhjan",
    "Malegaon",
    "Malkapur",
    "Malvan",
    "Manchar",
    "Mangrul Pir",
    "Manmad",
    "Manor",
    "Mansar",
    "Manwat",
    "Matheran",
    "Mehekar",
    "Mhasla",
    "Mhasvad",
    "Mohpa",
    "Moram",
    "Morsi",
    "Mowad",
    "Mudkhed",
    "Mukher",
    "Mul",
    "Mumbai",
    "Mumbai Suburban",
    "Murbad",
    "Murgud",
    "Murtajapur",
    "Murud",
    "Nagothana",
    "Nagpur",
    "Nagpur Division",
    "Naldurg",
    "Nanded",
    "Nandgaon",
    "Nandura Buzurg",
    "Nandurbar",
    "Nashik",
    "Nashik Division",
    "Navi Mumbai",
    "Neral",
    "Nilanga",
    "Nipani",
    "Osmanabad",
    "Ozar",
    "Pachora",
    "Paithan",
    "Palghar",
    "Panchgani",
    "Pandharpur",
    "Panhala",
    "Panvel",
    "Parbhani",
    "Parli Vaijnath",
    "Parola",
    "Partur",
    "Patan",
    "Pathardi",
    "Pathri",
    "Patur",
    "Pawni",
    "Pen",
    "Phaltan",
    "Pimpri",
    "Pipri",
    "Powai",
    "Pulgaon",
    "Pune",
    "Pune Division",
    "Purna",
    "Pusad",
    "Rahimatpur",
    "Rahuri",
    "Raigarh",
    "Rajapur",
    "Rajgurunagar",
    "Rajur",
    "Rajura",
    "Ramtek",
    "Ratnagiri",
    "Raver",
    "Revadanda",
    "Risod",
    "Roha",
    "Sangamner",
    "Sangli",
    "Sangola",
    "Saoner",
    "Sasvad",
    "Satana",
    "Satara",
    "Satara Division",
    "Savantvadi",
    "Savda",
    "Selu",
    "Shahada",
    "Shahapur",
    "Shegaon",
    "Shiraguppi",
    "Shirdi",
    "Shirgaon",
    "Shirpur",
    "Shirwal",
    "Shivaji Nagar",
    "Shrigonda",
    "Sillod",
    "Sindhudurg",
    "Sindi",
    "Sinnar",
    "Sirur",
    "Solapur",
    "Sonegaon",
    "Soygaon",
    "Srivardhan",
    "Surgana",
    "Talegaon Dabhade",
    "Taloda",
    "Tarapur",
    "Tasgaon",
    "Telhara",
    "Thane",
    "Trimbak",
    "Tuljapur",
    "Tumsar",
    "Udgir",
    "Ulhasnagar",
    "Umarga",
    "Umarkhed",
    "Umred",
    "Uran",
    "Vada",
    "Vaijapur",
    "Varangaon",
    "Vasind",
    "Vengurla",
    "Virar",
    "Vite",
    "Wadgaon",
    "Wai",
    "Wani",
    "Wardha",
    "Warora",
    "Warud",
    "Washim",
    "Yaval",
    "Yavatmal",
    "Yeola",
  ],
  Manipur: [
    // Manipur

    "Bishnupur",
    "Churachandpur",
    "Imphal",
    "Kakching",
    "Mayang Imphal",
    "Moirang",
    "Phek",
    "Senapati",
    "Tamenglong",
    "Thoubal",
    "Ukhrul",
    "Wangjing",
    "Yairipok",
  ],
  Meghalaya: [
    // Meghalaya

    "Cherrapunji",
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "Mairang",
    "Mankachar",
    "Nongpoh",
    "Nongstoin",
    "North Garo Hills",
    "Ri-Bhoi",
    "Shillong",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "Tura",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],
  Mizoram: [
    // Mizoram
    "Aizawl",
    "Champhai",
    "Darlawn",
    "Khawhai",
    "Kolasib",
    "Kolasib district",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "North Vanlaiphai",
    "Saiha",
    "Sairang",
    "Saitlaw",
    "Serchhip",
    "Thenzawl",
  ],
  Nagaland: [
    // Nagaland

    "Dimapur",
    "Kohima",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Tuensang District",
    "Wokha",
    "Zunheboto",
  ],
  Odisha: [
    // Odisha
    "Angul",
    "Angul District",
    "Asika",
    "Athagarh",
    "Bada Barabil",
    "Balangir",
    "Balasore",
    "Baleshwar",
    "Balimila",
    "Balugaon",
    "Banapur",
    "Banki",
    "Banposh",
    "Baragarh",
    "Barbil",
    "Bargarh",
    "Barpali",
    "Basudebpur",
    "Baud",
    "Baudh",
    "Belaguntha",
    "Bhadrak",
    "Bhadrakh",
    "Bhanjanagar",
    "Bhawanipatna",
    "Bhuban",
    "Bhubaneshwar",
    "Binka",
    "Birmitrapur",
    "Bolanikhodan",
    "Brahmapur",
    "Brajarajnagar",
    "Buguda",
    "Burla",
    "Champua",
    "Chandbali",
    "Chatrapur",
    "Chikitigarh",
    "Chittarkonda",
    "Cuttack",
    "Daitari",
    "Deogarh",
    "Dhenkanal",
    "Digapahandi",
    "Gajapati",
    "Ganjam",
    "Gopalpur",
    "Gudari",
    "Gunupur",
    "Hinjilikatu",
    "Hirakud",
    "Jagatsinghapur",
    "Jagatsinghpur",
    "Jajpur",
    "Jaleshwar",
    "Jatani",
    "Jeypore",
    "Jharsuguda",
    "Jharsuguda District",
    "Kaintragarh",
    "Kalahandi",
    "Kamakhyanagar",
    "Kandhamal",
    "Kantabanji",
    "Kantilo",
    "Kendrapara",
    "Kendraparha",
    "Kendujhar",
    "Kesinga",
    "Khallikot",
    "Kharhial",
    "Khordha",
    "Khurda",
    "Kiri Buru",
    "Kodala",
    "Konarka",
    "Koraput",
    "Kuchaiburi",
    "Kuchinda",
    "Malakanagiri",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nayagarh District",
    "Nilgiri",
    "Nimaparha",
    "Nowrangapur",
    "Nuapada",
    "Padampur",
    "Paradip Garh",
    "Patamundai",
    "Patnagarh",
    "Phulbani",
    "Pipili",
    "Polasara",
    "Puri",
    "Purushottampur",
    "Rambha",
    "Raurkela",
    "Rayagada",
    "Remuna",
    "Rengali",
    "Sambalpur",
    "Sonepur",
    "Sorada",
    "Soro",
    "Subarnapur",
    "Sundargarh",
    "Talcher",
    "Tarabha",
    "Titlagarh",
    "Udayagiri",
  ],
  Puducherry: [
    // Puducherry

    "Karaikal",
    "Mahe",
    "Puducherry",
    "Yanam",
  ],
  Punjab: [
    // Punjab

    "Abohar",
    "Adampur",
    "Ajitgarh",
    "Ajnala",
    "Akalgarh",
    "Alawalpur",
    "Amloh",
    "Amritsar",
    "Anandpur Sahib",
    "Badhni Kalan",
    "Bagha Purana",
    "Bakloh",
    "Balachor",
    "Banga",
    "Banur",
    "Barnala",
    "Batala",
    "Begowal",
    "Bhadaur",
    "Bhatinda",
    "Bhawanigarh",
    "Bhikhi",
    "Bhogpur",
    "Bholath",
    "Budhlada",
    "Chima",
    "Dasuya",
    "Dera Baba Nanak",
    "Dera Bassi",
    "Dhanaula",
    "Dhariwal",
    "Dhilwan",
    "Dhudi",
    "Dhuri",
    "Dina Nagar",
    "Dirba",
    "Doraha",
    "Faridkot",
    "Fatehgarh Churian",
    "Fatehgarh Sahib",
    "Fazilka",
    "Firozpur",
    "Firozpur District",
    "Gardhiwala",
    "Garhshankar",
    "Ghanaur",
    "Giddarbaha",
    "Gurdaspur",
    "Guru Har Sahai",
    "Hajipur",
    "Hariana",
    "Hoshiarpur",
    "Ishanpur",
    "Jagraon",
    "Jaito",
    "Jalalabad",
    "Jalandhar",
    "Jandiala",
    "Jandiala Guru",
    "Kalanaur",
    "Kapurthala",
    "Kartarpur",
    "Khamanon",
    "Khanna",
    "Kharar",
    "Khemkaran",
    "Kot Isa Khan",
    "Kotkapura",
    "Laungowal",
    "Ludhiana",
    "Machhiwara",
    "Majitha",
    "Makhu",
    "Malaut",
    "Malerkotla",
    "Mansa",
    "Maur Mandi",
    "Moga",
    "Mohali",
    "Morinda",
    "Mukerian",
    "Nabha",
    "Nakodar",
    "Nangal",
    "Nawanshahr",
    "Nurmahal",
    "Nurpur Kalan",
    "Pathankot",
    "Patiala",
    "Patti",
    "Phagwara",
    "Phillaur",
    "Qadian",
    "Rahon",
    "Raikot",
    "Rajasansi",
    "Rajpura",
    "Ram Das",
    "Rampura",
    "Rupnagar",
    "Samrala",
    "Sanaur",
    "Sangrur",
    "Sardulgarh",
    "Shahid Bhagat Singh Nagar",
    "Shahkot",
    "Sham Churasi",
    "Sirhind-Fategarh",
    "Sri Muktsar Sahib",
    "Sultanpur Lodhi",
    "Sunam",
    "Talwandi Bhai",
    "Talwara",
    "Tarn Taran Sahib",
    "Zira",
  ],
  Rajasthan: [
    // Rajasthan
    "Abhaneri",
    "Abu",
    "Abu Road",
    "Ajmer",
    "Aklera",
    "Alwar",
    "Amet",
    "Anta",
    "Anupgarh",
    "Asind",
    "Bagar",
    "Bakani",
    "Bali",
    "Balotra",
    "Bandikui",
    "Banswara",
    "Baran",
    "Bari",
    "Bari Sadri",
    "Barmer",
    "Basi",
    "Basni",
    "Baswa",
    "Bayana",
    "Beawar",
    "Begun",
    "Behror",
    "Bhadasar",
    "Bhadra",
    "Bharatpur",
    "Bhasawar",
    "Bhilwara",
    "Bhindar",
    "Bhinmal",
    "Bhiwadi",
    "Bhuma",
    "Bikaner",
    "Bilara",
    "Bissau",
    "Borkhera",
    "Bundi",
    "Chaksu",
    "Chechat",
    "Chhabra",
    "Chhapar",
    "Chhoti Sadri",
    "Chidawa",
    "Chittaurgarh",
    "Churu",
    "Dariba",
    "Dausa",
    "Deoli",
    "Deshnoke",
    "Devgarh",
    "Dhaulpur",
    "Didwana",
    "Dig",
    "Dungarpur",
    "Fatehpur",
    "Galiakot",
    "Ganganagar",
    "Gangapur",
    "Govindgarh",
    "Gulabpura",
    "Hanumangarh",
    "Hindaun",
    "Jahazpur",
    "Jaipur",
    "Jaisalmer",
    "Jaitaran",
    "Jalor",
    "Jalore",
    "Jhalawar",
    "Jhalrapatan",
    "Jhunjhunun",
    "Jobner",
    "Jodhpur",
    "Kaman",
    "Kanor",
    "Kapren",
    "Karanpur",
    "Karauli",
    "Kekri",
    "Keshorai Patan",
    "Khandela",
    "Khanpur",
    "Khetri",
    "Kishangarh",
    "Kota",
    "Kotputli",
    "Kuchaman",
    "Kuchera",
    "Kumher",
    "Kushalgarh",
    "Lachhmangarh Sikar",
    "Ladnun",
    "Lakheri",
    "Lalsot",
    "Losal",
    "Mahwah",
    "Makrana",
    "Malpura",
    "Mandal",
    "Mandalgarh",
    "Mandawar",
    "Mangrol",
    "Manohar Thana",
    "Manoharpur",
    "Meethari Marwar",
    "Merta",
    "Mundwa",
    "Nadbai",
    "Nagar",
    "Nagaur",
    "Nainwa",
    "Napasar",
    "Naraina",
    "Nasirabad",
    "Nathdwara",
    "Nawa",
    "Nawalgarh",
    "Neem ka Thana",
    "Nimaj",
    "Nimbahera",
    "Niwai",
    "Nohar",
    "Nokha",
    "Padampur",
    "Pali",
    "Partapur",
    "Parvatsar",
    "Phalodi",
    "Phulera",
    "Pilani",
    "Pilibangan",
    "Pindwara",
    "Pipar",
    "Pirawa",
    "Pokaran",
    "Pratapgarh",
    "Pushkar",
    "Raipur",
    "Raisinghnagar",
    "Rajakhera",
    "Rajaldesar",
    "Rajgarh",
    "Rajsamand",
    "Ramganj Mandi",
    "Ramgarh",
    "Rani",
    "Ratangarh",
    "Rawatbhata",
    "Rawatsar",
    "Ringas",
    "Sadri",
    "Salumbar",
    "Sambhar",
    "Samdari",
    "Sanchor",
    "Sangaria",
    "Sangod",
    "Sardarshahr",
    "Sarwar",
    "Sawai Madhopur",
    "Shahpura",
    "Sheoganj",
    "Sikar",
    "Sirohi",
    "Siwana",
    "Sojat",
    "Sri Dungargarh",
    "Sri Madhopur",
    "Sujangarh",
    "Suket",
    "Sunel",
    "Surajgarh",
    "Suratgarh",
    "Takhatgarh",
    "Taranagar",
    "Tijara",
    "Todabhim",
    "Todaraisingh",
    "Tonk",
    "Udaipur",
    "Udpura",
    "Uniara",
    "Wer",
  ],
  Sikkim: [
    // Sikkim

    "East District",
    "Gangtok",
    "Gyalshing",
    "Jorethang",
    "Mangan",
    "Namchi",
    "Naya Bazar",
    "North District",
    "Rangpo",
    "Singtam",
    "South District",
    "West District",
  ],
  "Tamil Nadu": [
    "Abiramam",
    "Adirampattinam",
    "Aduthurai",
    "Alagapuram",
    "Alandur",
    "Alanganallur",
    "Alangayam",
    "Alangudi",
    "Alangulam",
    "Alappakkam",
    "Alwa Tirunagari",
    "Ambasamudram",
    "Ambattur",
    "Ambur",
    "Ammapettai",
    "Anamalais",
    "Andippatti",
    "Annamalainagar",
    "Annavasal",
    "Annur",
    "Anthiyur",
    "Arakkonam",
    "Arantangi",
    "Arcot",
    "Arimalam",
    "Ariyalur",
    "Arni",
    "Arumbavur",
    "Arumuganeri",
    "Aruppukkottai",
    "Aruvankad",
    "Attayyampatti",
    "Attur",
    "Auroville",
    "Avadi",
    "Avinashi",
    "Ayakudi",
    "Ayyampettai",
    "Belur",
    "Bhavani",
    "Bodinayakkanur",
    "Chengam",
    "Chennai",
    "Chennimalai",
    "Chetput",
    "Chettipalaiyam",
    "Cheyyar",
    "Cheyyur",
    "Chidambaram",
    "Chingleput",
    "Chinna Salem",
    "Chinnamanur",
    "Chinnasekkadu",
    "Cholapuram",
    "Coimbatore",
    "Colachel",
    "Cuddalore",
    "Cumbum",
    "Denkanikota",
    "Desur",
    "Devadanappatti",
    "Devakottai",
    "Dhali",
    "Dharapuram",
    "Dharmapuri",
    "Dindigul",
    "Dusi",
    "Elayirampannai",
    "Elumalai",
    "Eral",
    "Eraniel",
    "Erode",
    "Erumaippatti",
    "Ettaiyapuram",
    "Gandhi Nagar",
    "Gangaikondan",
    "Gangavalli",
    "Gingee",
    "Gobichettipalayam",
    "Gudalur",
    "Gudiyatham",
    "Guduvancheri",
    "Gummidipundi",
    "Harur",
    "Hosur",
    "Idappadi",
    "Ilampillai",
    "Iluppur",
    "Injambakkam",
    "Irugur",
    "Jalakandapuram",
    "Jalarpet",
    "Jayamkondacholapuram",
    "Kadambur",
    "Kadayanallur",
    "Kalakkadu",
    "Kalavai",
    "Kallakkurichchi",
    "Kallidaikurichi",
    "Kallupatti",
    "Kalugumalai",
    "Kamuthi",
    "Kanadukattan",
    "Kancheepuram",
    "Kanchipuram",
    "Kangayam",
    "Kanniyakumari",
    "Karaikkudi",
    "Karamadai",
    "Karambakkudi",
    "Kariapatti",
    "Karumbakkam",
    "Karur",
    "Katpadi",
    "Kattivakkam",
    "Kattupputtur",
    "Kaveripatnam",
    "Kayalpattinam",
    "Kayattar",
    "Keelakarai",
    "Kelamangalam",
    "Kil Bhuvanagiri",
    "Kilvelur",
    "Kiranur",
    "Kodaikanal",
    "Kodumudi",
    "Kombai",
    "Konganapuram",
    "Koothanallur",
    "Koradachcheri",
    "Korampallam",
    "Kotagiri",
    "Kottaiyur",
    "Kovilpatti",
    "Krishnagiri",
    "Kulattur",
    "Kulittalai",
    "Kumaralingam",
    "Kumbakonam",
    "Kunnattur",
    "Kurinjippadi",
    "Kuttalam",
    "Kuzhithurai",
    "Lalgudi",
    "Madambakkam",
    "Madipakkam",
    "Madukkarai",
    "Madukkur",
    "Madurai",
    "Madurantakam",
    "Mallapuram",
    "Mallasamudram",
    "Mallur",
    "Manali",
    "Manalurpettai",
    "Manamadurai",
    "Manappakkam",
    "Manapparai",
    "Manavalakurichi",
    "Mandapam",
    "Mangalam",
    "Mannargudi",
    "Marakkanam",
    "Marandahalli",
    "Masinigudi",
    "Mattur",
    "Mayiladuthurai",
    "Melur",
    "Mettuppalaiyam",
    "Mettur",
    "Minjur",
    "Mohanur",
    "Mudukulattur",
    "Mulanur",
    "Musiri",
    "Muttupet",
    "Naduvattam",
    "Nagapattinam",
    "Nagercoil",
    "Namagiripettai",
    "Namakkal",
    "Nambiyur",
    "Nambutalai",
    "Nandambakkam",
    "Nangavalli",
    "Nangilickondan",
    "Nanguneri",
    "Nannilam",
    "Naravarikuppam",
    "Nattam",
    "Nattarasankottai",
    "Needamangalam",
    "Neelankarai",
    "Negapatam",
    "Nellikkuppam",
    "Nilakottai",
    "Nilgiris",
    "Odugattur",
    "Omalur",
    "Ooty",
    "Padmanabhapuram",
    "Palakkodu",
    "Palamedu",
    "Palani",
    "Palavakkam",
    "Palladam",
    "Pallappatti",
    "Pallattur",
    "Pallavaram",
    "Pallikondai",
    "Pallipattu",
    "Pallippatti",
    "Panruti",
    "Papanasam",
    "Papireddippatti",
    "Papparappatti",
    "Paramagudi",
    "Pattukkottai",
    "Pennadam",
    "Pennagaram",
    "Pennathur",
    "Peraiyur",
    "Perambalur",
    "Peranamallur",
    "Peranampattu",
    "Peravurani",
    "Periyakulam",
    "Periyanayakkanpalaiyam",
    "Periyanegamam",
    "Periyapatti",
    "Periyapattinam",
    "Perundurai",
    "Perungudi",
    "Perur",
    "Pollachi",
    "Polur",
    "Ponnamaravati",
    "Ponneri",
    "Poonamalle",
    "Porur",
    "Pudukkottai",
    "Puduppatti",
    "Pudur",
    "Puduvayal",
    "Puliyangudi",
    "Puliyur",
    "Pullambadi",
    "Punjai Puliyampatti",
    "Rajapalaiyam",
    "Ramanathapuram",
    "Rameswaram",
    "Rasipuram",
    "Saint Thomas Mount",
    "Salem",
    "Sathankulam",
    "Sathyamangalam",
    "Sattur",
    "Sayalkudi",
    "Seven Pagodas",
    "Sholinghur",
    "Singanallur",
    "Singapperumalkovil",
    "Sirkazhi",
    "Sirumugai",
    "Sivaganga",
    "Sivagiri",
    "Sivakasi",
    "Srimushnam",
    "Sriperumbudur",
    "Srivaikuntam",
    "Srivilliputhur",
    "Suchindram",
    "Sulur",
    "Surandai",
    "Swamimalai",
    "Tambaram",
    "Tanjore",
    "Taramangalam",
    "Tattayyangarpettai",
    "Thanjavur",
    "Tharangambadi",
    "Theni",
    "Thenkasi",
    "Thirukattupalli",
    "Thiruthani",
    "Thiruvaiyaru",
    "Thiruvallur",
    "Thiruvarur",
    "Thiruvidaimaruthur",
    "Thoothukudi",
    "Tindivanam",
    "Tinnanur",
    "Tiruchchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirukkoyilur",
    "Tirumullaivasal",
    "Tirunelveli",
    "Tirunelveli Kattabo",
    "Tiruppalaikudi",
    "Tirupparangunram",
    "Tiruppur",
    "Tiruppuvanam",
    "Tiruttangal",
    "Tiruvannamalai",
    "Tiruvottiyur",
    "Tisaiyanvilai",
    "Tondi",
    "Turaiyur",
    "Udangudi",
    "Udumalaippettai",
    "Uppiliyapuram",
    "Usilampatti",
    "Uttamapalaiyam",
    "Uttiramerur",
    "Ūttukkuli",
    "V.S.K.Valasai (Dindigul-Dist.)",
    "Vadakku Valliyur",
    "Vadakku Viravanallur",
    "Vadamadurai",
    "Vadippatti",
    "Valangaiman",
    "Valavanur",
    "Vallam",
    "Valparai",
    "Vandalur",
    "Vandavasi",
    "Vaniyambadi",
    "Vasudevanallur",
    "Vattalkundu",
    "Vedaraniyam",
    "Vedasandur",
    "Velankanni",
    "Vellanur",
    "Vellore",
    "Velur",
    "Vengavasal",
    "Vettaikkaranpudur",
    "Vettavalam",
    "Vijayapuri",
    "Vikravandi",
    "Vilattikulam",
    "Villupuram",
    "Viraganur",
    "Virudhunagar",
    "Vriddhachalam",
    "Walajapet",
    "Wallajahbad",
    "Wellington",
  ],
  Telangana: [
    // Telangana
    "Adilabad",
    "Alampur",
    "Andol",
    "Asifabad",
    "Balapur",
    "Banswada",
    "Bellampalli",
    "Bhadrachalam",
    "Bhadradri Kothagudem",
    "Bhaisa",
    "Bhongir",
    "Bodhan",
    "Chandur",
    "Chatakonda",
    "Dasnapur",
    "Devarkonda",
    "Dornakal",
    "Farrukhnagar",
    "Gaddi Annaram",
    "Gadwal",
    "Ghatkesar",
    "Gopalur",
    "Gudur",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jangoan",
    "Jayashankar Bhupalapally",
    "Jogulamba Gadwal",
    "Kagaznagar",
    "Kamareddi",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Kodar",
    "Koratla",
    "Kothapet",
    "Kottagudem",
    "Kottapalli",
    "Kukatpalli",
    "Kyathampalle",
    "Lakshettipet",
    "Lal Bahadur Nagar",
    "Mahabubabad",
    "Mahbubnagar",
    "Malkajgiri",
    "Mancheral",
    "Mandamarri",
    "Manthani",
    "Manuguru",
    "Medak",
    "Medchal",
    "Medchal Malkajgiri",
    "Mirialguda",
    "Nagar Karnul",
    "Nalgonda",
    "Narayanpet",
    "Narsingi",
    "Naspur",
    "Nirmal",
    "Nizamabad",
    "Paloncha",
    "Palwancha",
    "Patancheru",
    "Peddapalli",
    "Quthbullapur",
    "Rajanna Sircilla",
    "Ramagundam",
    "Ramgundam",
    "Rangareddi",
    "Sadaseopet",
    "Sangareddi",
    "Sathupalli",
    "Secunderabad",
    "Serilingampalle",
    "Siddipet",
    "Singapur",
    "Sirpur",
    "Sirsilla",
    "Sriramnagar",
    "Suriapet",
    "Tandur",
    "Uppal Kalan",
    "Vemalwada",
    "Vikarabad",
    "Wanparti",
    "Warangal",
    "Yellandu",
    "Zahirabad",
  ],
  Tripura: [
    // Tripura

    "Agartala",
    "Amarpur",
    "Ambasa",
    "Barjala",
    "Belonia",
    "Dhalai",
    "Dharmanagar",
    "Gomati",
    "Kailashahar",
    "Kamalpur",
    "Khowai",
    "North Tripura",
    "Ranir Bazar",
    "Sabrum",
    "Sonamura",
    "South Tripura",
    "Udaipur",
    "Unakoti",
    "West Tripura",
  ],
  "Uttar Pradesh": [
    // Uttar Pradesh

    "Achhnera",
    "Afzalgarh",
    "Agra",
    "Ahraura",
    "Ajodhya",
    "Akbarpur",
    "Aliganj",
    "Aligarh",
    "Allahabad",
    "Allahganj",
    "Amanpur",
    "Ambahta",
    "Ambedkar Nagar",
    "Amethi",
    "Amroha",
    "Anandnagar",
    "Antu",
    "Anupshahr",
    "Aonla",
    "Atarra",
    "Atrauli",
    "Atraulia",
    "Auraiya",
    "Auras",
    "Azamgarh",
    "Baberu",
    "Babina",
    "Babrala",
    "Babugarh",
    "Bachhraon",
    "Bachhrawan",
    "Baghpat",
    "Bah",
    "Baheri",
    "Bahjoi",
    "Bahraich",
    "Bahraigh",
    "Bahsuma",
    "Bahua",
    "Bajna",
    "Bakewar",
    "Baldev",
    "Ballia",
    "Balrampur",
    "Banat",
    "Banbasa",
    "Banda",
    "Bangarmau",
    "Bansdih",
    "Bansgaon",
    "Bansi",
    "Bara Banki",
    "Baragaon",
    "Baraut",
    "Bareilly",
    "Barkhera Kalan",
    "Barsana",
    "Basti",
    "Behat",
    "Bela",
    "Beniganj",
    "Beswan",
    "Bewar",
    "Bhadohi",
    "Bhagwantnagar",
    "Bharthana",
    "Bharwari",
    "Bhinga",
    "Bhongaon",
    "Bidhuna",
    "Bighapur Khurd",
    "Bijnor",
    "Bikapur",
    "Bilari",
    "Bilariaganj",
    "Bilaspur",
    "Bilgram",
    "Bilhaur",
    "Bilsanda",
    "Bilsi",
    "Bilthra",
    "Bindki",
    "Bisalpur",
    "Bisauli",
    "Bisenda Buzurg",
    "Bishunpur Urf Maharajganj",
    "Biswan",
    "Bithur",
    "Budaun",
    "Budhana",
    "Bulandshahr",
    "Captainganj",
    "Chail",
    "Chakia",
    "Chandauli",
    "Chandauli District",
    "Chandpur",
    "Chanduasi",
    "Charkhari",
    "Charthawal",
    "Chhaprauli",
    "Chharra",
    "Chhata",
    "Chhibramau",
    "Chhutmalpur",
    "Chillupar",
    "Chirgaon",
    "Chitrakoot",
    "Chopan",
    "Chunar",
    "Colonelganj",
    "Dadri",
    "Dalmau",
    "Dankaur",
    "Dasna",
    "Dataganj",
    "Daurala",
    "Dayal Bagh",
    "Deoband",
    "Deoranian",
    "Deoria",
    "Dewa",
    "Dhampur",
    "Dhanaura",
    "Dhaurahra",
    "Dibai",
    "Dohrighat",
    "Dostpur",
    "Dudhi",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farah",
    "Faridnagar",
    "Faridpur",
    "Farrukhabad",
    "Fatehabad",
    "Fatehganj West",
    "Fatehgarh",
    "Fatehpur",
    "Fatehpur Chaurasi",
    "Fatehpur Sikri",
    "Firozabad",
    "Fyzabad",
    "Gajraula",
    "Gangoh",
    "Ganj Dundwara",
    "Ganj Muradabad",
    "Garautha",
    "Garhi Pukhta",
    "Garhmuktesar",
    "Gautam Buddha Nagar",
    "Gawan",
    "Ghatampur",
    "Ghaziabad",
    "Ghazipur",
    "Ghiror",
    "Ghorawal",
    "Ghosi",
    "Gohand",
    "Gokul",
    "Gola Bazar",
    "Gola Gokarannath",
    "Gonda",
    "Gonda City",
    "Gopamau",
    "Gorakhpur",
    "Goshainganj",
    "Govardhan",
    "Greater Noida",
    "Gulaothi",
    "Gunnaur",
    "Gursahaiganj",
    "Gursarai",
    "Gyanpur",
    "Haldaur",
    "Hamirpur",
    "Handia",
    "Hapur",
    "Haraiya",
    "Hardoi",
    "Harduaganj",
    "Hasanpur",
    "Hastinapur",
    "Hata",
    "Hathras",
    "Iglas",
    "Ikauna",
    "Indergarh",
    "Islamnagar",
    "Itaunja",
    "Itimadpur",
    "Jagdishpur",
    "Jagnair",
    "Jahanabad",
    "Jahangirabad",
    "Jahangirpur",
    "Jainpur",
    "Jais",
    "Jalalabad",
    "Jalali",
    "Jalalpur",
    "Jalaun",
    "Jalesar",
    "Jansath",
    "Jarwal",
    "Jasrana",
    "Jaswantnagar",
    "Jaunpur",
    "Jewar",
    "Jhalu",
    "Jhansi",
    "Jhinjhak",
    "Jhinjhana",
    "Jhusi",
    "Jyotiba Phule Nagar",
    "Kabrai",
    "Kachhwa",
    "Kadaura",
    "Kadipur",
    "Kaimganj",
    "Kairana",
    "Kakori",
    "Kakrala",
    "Kalinagar",
    "Kalpi",
    "Kamalganj",
    "Kampil",
    "Kandhla",
    "Kannauj",
    "Kanpur",
    "Kanpur Dehat",
    "Kant",
    "Kanth",
    "Karari",
    "Karhal",
    "Kasganj",
    "Katra",
    "Kaushambi District",
    "Kemri",
    "Khada",
    "Khaga",
    "Khair",
    "Khairabad",
    "Khalilabad",
    "Khanpur",
    "Kharela",
    "Khargupur",
    "Kharkhauda",
    "Khatauli",
    "Khekra",
    "Kheri",
    "Khudaganj",
    "Khurja",
    "Khutar",
    "Kirakat",
    "Kiraoli",
    "Kiratpur",
    "Kishanpur",
    "Kishni",
    "Kithor",
    "Konch",
    "Kopaganj",
    "Kosi",
    "Kota",
    "Kotra",
    "Kulpahar",
    "Kunda",
    "Kundarkhi",
    "Kurara",
    "Kushinagar",
    "Laharpur",
    "Lakhimpur",
    "Lakhna",
    "Lalganj",
    "Lalitpur",
    "Lar",
    "Lawar Khas",
    "Loni",
    "Lucknow",
    "Lucknow District",
    "Machhlishahr",
    "Madhoganj",
    "Madhogarh",
    "Maghar",
    "Mahaban",
    "Maharajganj",
    "Mahmudabad",
    "Mahoba",
    "Maholi",
    "Mahroni",
    "Mailani",
    "Mainpuri",
    "Malihabad",
    "Mandawar",
    "Maniar",
    "Manikpur",
    "Manjhanpur",
    "Mankapur",
    "Marahra",
    "Mariahu",
    "Mataundh",
    "Mathura",
    "Mau",
    "Mau Aimma",
    "Maudaha",
    "Mauranwan",
    "Mawana",
    "Meerut",
    "Mehnagar",
    "Mehndawal",
    "Milak",
    "Miranpur",
    "Miranpur Katra",
    "Mirganj",
    "Mirzapur",
    "Misrikh",
    "Mohan",
    "Mohanpur",
    "Moradabad",
    "Moth",
    "Mubarakpur",
    "Mughal Sarai",
    "Muhammadabad",
    "Muradnagar",
    "Mursan",
    "Musafir-Khana",
    "Muzaffarnagar",
    "Nadigaon",
    "Nagina",
    "Nagram",
    "Najibabad",
    "Nakur",
    "Nanauta",
    "Nandgaon",
    "Nanpara",
    "Narauli",
    "Naraura",
    "Nautanwa",
    "Nawabganj",
    "Nichlaul",
    "Nihtaur",
    "Niwari",
    "Nizamabad",
    "Noida",
    "Nurpur",
    "Obra",
    "Orai",
    "Oran",
    "Pachperwa",
    "Padrauna",
    "Pahasu",
    "Pali",
    "Palia Kalan",
    "Parichha",
    "Parichhatgarh",
    "Parshadepur",
    "Patiali",
    "Patti",
    "Pawayan",
    "Phalauda",
    "Phaphund",
    "Phariha",
    "Phulpur",
    "Pihani",
    "Pilibhit",
    "Pilkhua",
    "Pinahat",
    "Pipraich",
    "Pratapgarh",
    "Pukhrayan",
    "Puranpur",
    "Purwa",
    "Rabupura",
    "Radhakund",
    "Raebareli",
    "Rajapur",
    "Ramkola",
    "Ramnagar",
    "Rampur",
    "Rampura",
    "Ranipur",
    "Rasra",
    "Rasulabad",
    "Rath",
    "Raya",
    "Renukut",
    "Reoti",
    "Richha",
    "Robertsganj",
    "Rudarpur",
    "Rura",
    "Sadabad",
    "Sadat",
    "Safipur",
    "Saharanpur",
    "Sahaspur",
    "Sahaswan",
    "Sahawar",
    "Saidpur",
    "Sakit",
    "Salon",
    "Sambhal",
    "Samthar",
    "Sandi",
    "Sandila",
    "Sant Kabir Nagar",
    "Sant Ravi Das Nagar",
    "Sarai Akil",
    "Sarai Ekdil",
    "Sarai Mir",
    "Sarauli",
    "Sardhana",
    "Sarila",
    "Sasni",
    "Satrikh",
    "Saurikh",
    "Sector",
    "Seohara",
    "Shahabad",
    "Shahganj",
    "Shahi",
    "Shahjahanpur",
    "Shahpur",
    "Shamli",
    "Shamsabad",
    "Shankargarh",
    "Shergarh",
    "Sherkot",
    "Shikarpur",
    "Shikohabad",
    "Shishgarh",
    "Shrawasti",
    "Siddharthnagar",
    "Sidhauli",
    "Sidhpura",
    "Sikandarabad",
    "Sikandarpur",
    "Sikandra",
    "Sikandra Rao",
    "Sirathu",
    "Sirsa",
    "Sirsaganj",
    "Sirsi",
    "Sisauli",
    "Siswa Bazar",
    "Sitapur",
    "Sonbhadra",
    "Soron",
    "Suar",
    "Sultanpur",
    "Surianwan",
    "Tajpur",
    "Talbahat",
    "Talgram",
    "Tanda",
    "Thakurdwara",
    "Thana Bhawan",
    "Tikaitnagar",
    "Tikri",
    "Tilhar",
    "Tindwari",
    "Titron",
    "Tori-Fatehpur",
    "Tulsipur",
    "Tundla",
    "Ugu",
    "Ujhani",
    "Ūn",
    "Unnao",
    "Usehat",
    "Utraula",
    "Varanasi",
    "Vrindavan",
    "Wazirganj",
    "Zafarabad",
    "Zaidpur",
    "Zamania",
  ],
  Uttarakhand: [
    // Uttarakhand
    "Almora",
    "Bageshwar",
    "Barkot",
    "Bazpur",
    "Bhim Tal",
    "Bhowali",
    "Birbhaddar",
    "Chakrata",
    "Chamoli",
    "Champawat",
    "Clement Town",
    "Dehradun",
    "Devaprayag",
    "Dharchula",
    "Doiwala",
    "Dugadda",
    "Dwarahat",
    "Garhwal",
    "Haldwani",
    "Harbatpur",
    "Haridwar",
    "Jaspur",
    "Joshimath",
    "Kaladhungi",
    "Kalagarh Project Colony",
    "Kashipur",
    "Khatima",
    "Kichha",
    "Kotdwara",
    "Laksar",
    "Lansdowne",
    "Lohaghat",
    "Manglaur",
    "Mussoorie",
    "Naini Tal",
    "Narendranagar",
    "Pauri",
    "Pipalkoti",
    "Pithoragarh",
    "Raipur",
    "Raiwala Bara",
    "Ramnagar",
    "Ranikhet",
    "Rishikesh",
    "Roorkee",
    "Rudraprayag",
    "Sitarganj",
    "Srinagar",
    "Sultanpur",
    "Tanakpur",
    "Tehri",
    "Tehri-Garhwal",
    "Udham Singh Nagar",
    "Uttarkashi",
    "Vikasnagar",
  ],
  "West Bengal": [
    "Ahmedpur",
    "Aistala",
    "Aknapur",
    "Alipurduar",
    "Amlagora",
    "Amta",
    "Amtala",
    "Andal",
    "Arambagh community development block",
    "Asansol",
    "Ashoknagar Kalyangarh",
    "Badkulla",
    "Baduria",
    "Bagdogra",
    "Bagnan",
    "Bagula",
    "Bahula",
    "Baidyabati",
    "Bakreswar",
    "Balarampur",
    "Bali Chak",
    "Bally",
    "Balurghat",
    "Bamangola community development block",
    "Baneswar",
    "Bangaon",
    "Bankra",
    "Bankura",
    "Bansberia",
    "Bansihari community development block",
    "Barabazar",
    "Baranagar",
    "Barasat",
    "Bardhaman",
    "Barjora",
    "Barrackpore",
    "Baruipur",
    "Basanti",
    "Basirhat",
    "Bawali",
    "Begampur",
    "Belda",
    "Beldanga",
    "Beliatore",
    "Berhampore",
    "Bhadreswar",
    "Bhandardaha",
    "Bhatpara",
    "Birbhum district",
    "Birpara",
    "Bishnupur",
    "Bolpur",
    "Budge Budge",
    "Canning",
    "Chakapara",
    "Chakdaha",
    "Champadanga",
    "Champahati",
    "Champdani",
    "Chandannagar",
    "Chandrakona",
    "Chittaranjan",
    "Churulia",
    "Contai",
    "Cooch Behar",
    "Cossimbazar",
    "Dakshin Dinajpur district",
    "Dalkola",
    "Dam Dam",
    "Darjeeling",
    "Daulatpur",
    "Debagram",
    "Debipur",
    "Dhaniakhali community development block",
    "Dhulagari",
    "Dhulian",
    "Dhupguri",
    "Diamond Harbour",
    "Digha",
    "Dinhata",
    "Domjur",
    "Dubrajpur",
    "Durgapur",
    "Egra",
    "Falakata",
    "Farakka",
    "Fort Gloster",
    "Gaighata community development block",
    "Gairkata",
    "Gangadharpur",
    "Gangarampur",
    "Garui",
    "Garulia",
    "Ghatal",
    "Giria",
    "Gobardanga",
    "Gobindapur",
    "Gopalpur",
    "Gopinathpur",
    "Gorubathan",
    "Gosaba",
    "Gosanimari",
    "Gurdaha",
    "Guskhara",
    "Habra",
    "Haldia",
    "Haldibari",
    "Halisahar",
    "Harindanga",
    "Haringhata",
    "Haripur",
    "Hasimara",
    "Hindusthan Cables Town",
    "Hooghly district",
    "Howrah",
    "Ichapur",
    "Indpur community development block",
    "Ingraj Bazar",
    "Islampur",
    "Jafarpur",
    "Jaigaon",
    "Jalpaiguri",
    "Jamuria",
    "Jangipur",
    "Jaynagar Majilpur",
    "Jejur",
    "Jhalida",
    "Jhargram",
    "Jhilimili",
    "Kakdwip",
    "Kalaikunda",
    "Kaliaganj",
    "Kalimpong",
    "Kalna",
    "Kalyani",
    "Kamarhati",
    "Kamarpukur",
    "Kanchrapara",
    "Kandi",
    "Karimpur",
    "Katwa",
    "Kenda",
    "Keshabpur",
    "Kharagpur",
    "Kharar",
    "Kharba",
    "Khardaha",
    "Khatra",
    "Kirnahar",
    "Kolkata",
    "Konnagar",
    "Krishnanagar",
    "Krishnapur",
    "Kshirpai",
    "Kulpi",
    "Kultali",
    "Kulti",
    "Kurseong",
    "Lalgarh",
    "Lalgola",
    "Loyabad",
    "Madanpur",
    "Madhyamgram",
    "Mahiari",
    "Mahishadal community development block",
    "Mainaguri",
    "Manikpara",
    "Masila",
    "Mathabhanga",
    "Matiali community development block",
    "Matigara community development block",
    "Medinipur",
    "Mejia community development block",
    "Memari",
    "Mirik",
    "Mohanpur community development block",
    "Monoharpur",
    "Muragacha",
    "Muri",
    "Murshidabad",
    "Nabadwip",
    "Nabagram",
    "Nadia district",
    "Nagarukhra",
    "Nagrakata",
    "Naihati",
    "Naksalbari",
    "Nalhati",
    "Nalpur",
    "Namkhana community development block",
    "Nandigram",
    "Nangi",
    "Nayagram community development block",
    "North 24 Parganas district",
    "Odlabari",
    "Paikpara",
    "Panagarh",
    "Panchla",
    "Panchmura",
    "Pandua",
    "Panihati",
    "Panskura",
    "Parbatipur",
    "Paschim Medinipur district",
    "Patiram",
    "Patrasaer",
    "Patuli",
    "Pujali",
    "Puncha community development block",
    "Purba Medinipur district",
    "Purulia",
    "Raghudebbati",
    "Raghunathpur",
    "Raiganj",
    "Rajmahal",
    "Rajnagar community development block",
    "Ramchandrapur",
    "Ramjibanpur",
    "Ramnagar",
    "Rampur Hat",
    "Ranaghat",
    "Raniganj",
    "Raypur",
    "Rishra",
    "Sahapur",
    "Sainthia",
    "Salanpur community development block",
    "Sankarpur",
    "Sankrail",
    "Santipur",
    "Santoshpur",
    "Santuri community development block",
    "Sarenga",
    "Serampore",
    "Serpur",
    "Shyamnagar, West Bengal",
    "Siliguri",
    "Singur",
    "Sodpur",
    "Solap",
    "Sonada",
    "Sonamukhi",
    "Sonarpur community development block",
    "South 24 Parganas district",
    "Srikhanda",
    "Srirampur",
    "Suri",
    "Swarupnagar community development block",
    "Takdah",
    "Taki",
    "Tamluk",
    "Tarakeswar",
    "Titagarh",
    "Tufanganj",
    "Tulin",
    "Uchalan",
    "Ula",
    "Uluberia",
    "Uttar Dinajpur district",
    "Uttarpara Kotrung",
  ],
};

export const Electronics = [
  "Air Conditioners - AC",
  "Air Coolers​",
  "Camera Accessories",
  "Cameras",
  "Gas Stove",
  "Induction Cook Tops​",
  "Laptops Or (Desktops - Computers)",
  "Microwave Ovens​",
  "Mixer - Grinder - Juicer",
  "Monitor Or Printers Or Scanners",
  "Music Systems - Home Theatre",
  "Refrigerators - Fridge",
  "TV",
  "Video Games - Consoles",
  "Washing Machines",
  "Water Heaters - Geysers",
  "Water Purifiers",
];

// export const Electronics=['Refrigerators/Fridge',"Music Systems - Home Theatre","TV","Gas Stove","Video Games - Consoles", "Cameras ","Camera Accessories","Microwave Ovens​","Induction Cook Tops​" , "Mixer / Grinder / Juicer","Water Purifiers","Laptops or (Desktops / Computers)","Monitor or Printers Or Scanners","Washing Machines","Water Heaters / Geysers","Air Coolers​","Air Conditioners / AC",'Dsktop/Computers','Household Appliances','Cameras','Gaming','Audio & Video','Headphones','Watches','Computeraccessories','Pendrive','Hometheater','Projectoraccessories','Cabels','Settopbox','MediaPlayers']

export const Furniture = [
  "Furniture For Home & Office",
  "Home Decor - Furnishings",
  "Household",
  "Kitchenware",
  "Painting",
];

// before it has all those category -->>> export const Furniture=["Furniture for Home &Office","Home Decor - Furnishings",'Household','Kitchenware','Painting','OfficeFurnitures','Kids Furniture','HomeDecor','SeatingFurnitures','LivingRoomFurnitures','Benches','Dining','BedroomFurnitures','StorageFurnitures','BathroomFurnitures','OutdoorFurnitures','KitchenFurnitures',]

export const Gadgets = [
  "Datacabels",
  "Chargers",
  "Laptops",
  "Keyboards",
  "Replacement",
  "Speaker",
  "Lenskit",
  "Headsets",
  "Powerbank ",
  "Batteries",
];
export const HomeAndLifeStyle = [
  [
    "Appliances",
    "Cleaning",
    "HomeDecore",
    "HomeFurnishing",
    "Improvement",
    "Lightning",
    "OutdoorFurniture",
    "Storage",
  ],
];
export const Education = ["College", "IT_Related", "School", "School Tution"];
export const Cars = ["Cars", "Spare Parts - Accessories"];
export const Bikes = ["Bikes", "Spare Parts - Accessories"];
export const Services = [
  "Home Repair",
  "Appliance Repair",
  "Packers and Movers",
  "Pest Control",
  "Home Cleaning",
  "Computer Repair",
  "Mobile & Tablet Repair",
];

//Cars
// export const Cars = [
//   "Audi",
//   "Bentley",
//   "BMW",
//   "Chevrolet",
//   "Fiat",
//   "Ford",
//   "Daewoo",
//   "Hindustan Motor",
//   "Maruti Suzuk",
//   "Hyundai",
//   "Honda",
//   "Toyota",
//   "Tata",
//   "Mercedes Benz",
//   "Mahindra",
//   "Mitsubishi",
//   "Nissan",
//   "Opel",
//   "Rolls Royce",
//   "Volkswagen",
//   "Volvo",
//   "Renault",
//   "Aston Martin",
//   "Ferrari",
//   "Jaguar",
//   "Lamborghini",
//   "Land Rover",
//   "Maserati",
//   "Mini",
//   "Porsche",
//   "Ashok Leyland",
//   "Bugatti",
//   "Caterham",
//   "Datsun",
//   "DC",
//   "ICML",
//   "Isuzu",
//   "Premier",
//   "Force Motors",
//   "San",
//   "Ssangyong",
//   "Eicher Polaris",
//   "Jeep",
//   "Lexus",
//   "Kia",
//   "MG Motors",
// ];

export const CarFueltype = [
  "CNG",
  "Diesel",
  "Electric",
  "Hybrid",
  "LPG",
  "Petrol",
];
export const YearOfRegistrationCar = [
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];
export const CarColor = [
  "Black",
  "Blue",
  "Golden",
  "Green",
  "Indigo",
  "Orange",
  "Red",
  "Silver",
  "Violet",
  "White",
  "Yellow",
];

// export const Jobs = ["Full Time", "Part Time", "Internships", "Work From Home"];
// export const Services = [
//   "Home Repair",
//   "Appliance Repair",
//   "Packers and Movers",
//   "Pest Control",
//   "Home Cleaning",
//   "Computer Repair",
//   "Mobile & Tablet Repair",
// ];

export const Health = ["Private", "Government", "Semi-Government"];
export const Travels = ["Private", "Government", "Semi-Government"];
export const Photography = ["Camera", "MobilePhotography"];
export const Salon = ["Parlour"];
// export const Mobiles=["RealMe","iPhone","OPPO","Xaomi","POCO","SAMSUNG"]
export const Mobiles = ["Mobile Phones", "Accessories", "Tablets"];

// export const Others=["Office_Space","Shop","Co_working"]

export const freePlanDetails = [
  "Free Ads",
  "7 Days Live",
  "Limited Response",
  // "No. of Top Ads : 0",
  // "No. of Featured Ads : 0",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const silverPlanDetails = [
  "5 Silver Ads",
  "15 Days Live",
  "Unlimited Response",
  // "No. of Top Ads : 2",
  // "No. of Featured Ads : 3",
  "30 Days Validity",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const goldPlanDetails = [
  "10 Gold Ads",
  "30 Days Live",
  "Unlimited Response",
  "90 Days Validity",
  // "No. of Top Ads : 5",
  // "No. of Featured Ads : 5",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const platinumPlanDetails = [
  "15 Platinum Ads",
  "60 Days Live",
  "Unlimited Response",
  "90 Days Validity",
  // "No. of Top Ads : 5",
  // "No. of Featured Ads : 5",
  "Tele Support",
  "Chat Support",
  "Dedicated RM",
  "Hola9's Website",
];
export const Free = {
  user: "",
  category: "Free",
  days: 7,
  regulars: 3,
  topAds: 0,
  featured: 0,
  teleSupport: 0,
  response: "Limited",
  chatSupport: 0,
  dedicatedRm: 0,
  hola9Website: 0,
};
export const Silver = {
  user: "",
  category: "Silver",
  days: 15,
  regulars: 5,
  topAds: 2,
  featured: 3,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 0,
  dedicatedRm: 0,
  hola9Website: 0,
};
export const Gold = {
  user: "",
  category: "Gold",
  days: 30,
  regulars: 10,
  topAds: 5,
  featured: 5,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 1,
  dedicatedRm: 1,
  hola9Website: 0,
};
export const Platinum = {
  user: "",
  category: "Platinum",
  days: 60,
  regulars: 10,
  topAds: 5,
  featured: 5,
  teleSupport: 1,
  response: "Unlimited",
  chatSupport: 1,
  dedicatedRm: 1,
  hola9Website: 1,
};

export const Category = [
  "RealEstate",
  "Furniture",
  "Pets",
  "Cars",
  "Bikes",
  "Electronics",
  "Services",
  // "Jobs",
  "Mobiles",
];
//business plan
export const businessPrimiumAds = ["50 ads", "100 ads", "150 ads"];
export const businessPrimiumValidity = ["3 months", "6 months", "12 months"];
export const adsFilter = [
  { ads: "50 ads", price: 15 },
  { ads: "100 ads", price: 25 },
  { ads: "150 ads", price: 35 },
];
export const validateFilter = [
  { days: "3 Months", price: 5 },
  { days: "9 Months", price: 10 },
  { days: "12 Months", price: 15 },
];
export const metroCity = [
  "Ahmedabad",
  "Bangalore",
  "Chennai",
  "Delhi",
  "Hyderabad",
  "Kolkata",
  "Mumbai",
  "Pune",
  "Surat",
];

export const color = ["red", "Green", "yellow"];
export const size = ["1kg", "2kg", "3kg"];
export const School = ["hhhh", "2kg", "3kg"];
export const College = ["hhhh", "2kg", "3kg"];
export const Year = ["hhhh", "2kg", "3kg"];
export const Engine = ["115cc", "125cc", "150cc", "180cc", "250cc"];

// subcategory

export const Pets = [
  "Pet Food",
  "Pet Shop",
  "Pet Clinics",
  "Pet Training & Grooming​​",
  "Pet Care - Accessories​",
];
export const oldPets = ["1Year", "2Year"];
export const PetCategory = ["dog", "cat", "cow"];
export const Breed = ["pavilean", "german"];
export const FurnitureSubCategory = [
  "Beds",
  "Chair&Seating",
  "Chests",
  "Desks",
  "Other",
];
export const educationSubCategory = [
  "Training",
  "Public Events & Activities",
  "School Visits& Academic Program",
  "College",
  "course",
];

//pets

// export const allSubcategory=[...Electronics,...Furniture,...Gadgets,...HomeAndLifeStyle,...Pets,...Education,...Mobiles,...All]
export const allSubcategory = [
  ...Electronics,
  ...Furniture,
  ...Pets,
  ...Mobiles,
  ...All,
];

export const Andhra_Pradesh = [
  "kurnool",
  "Visakhapatnam",
  "Vijayawada",
  "Guntur",
  "Nellore",
  "Kurnool",
  "Kakinada",
  "Rajahmundry",
  "Kadapa",
  "Tirupati",
  "Anantapuram",
  "Vizianagaram",
  "Eluru",
  "Nandyal",
  "Ongole",
  "Adoni",
  "	Madanapalle",
  "Machilipatnam",
  "Tenali",
  "Proddatur",
  "Chittoor",
  "Hindupur",
  "Srikakulam",
  "Bhimavaram",
  "Tadepalligudem",
  "Guntakal",
  "Dharmavaram",
  "Gudivada",
  "Narasaraopet",
  "Kadiri",
  "Tadipatri",
  "Mangalagiri",
  "Chilakaluripet",
];
