// import React, { useEffect, useState } from "react"
// import Search from '../../Base/Search/search';
import Searchnew from "../../Base/Search/Searchnew";
import "./homeSection.css";

import clapingCpl from "../../images/bro.png";
import any2 from "../../images/any2.jpg";
import any3 from "../../images/any3.jpg";
import any4 from "../../images/any4.jpg";
import logo from "../../images/nav_logo.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsChevronDown } from "react-icons/bs";
import City from "../../Ads/category/City";
import SearchForm from "../../Base/Search/SearchForm";
import { getExtraField } from "../../../store/ProductFilterSlice";
import { useDispatch } from "react-redux";
import { blue } from "@mui/material/colors";
import test from "../../images/test.png";
import line2 from "../../images/Line 2.png";
import line3 from "../../images/Line 3.png";
import line4 from "../../images/Line 4.png";
import line5 from "../../images/Line 5.png";
import line6 from "../../images/Line 6.png";
import Rectangleimg from "../../images/Rectangle 164.png"
import cuate from "../../images/cuate.png";
import pana from "../../images/pana.png"
import group22 from "../../images/Group 22 (1).png"
// import Hola9logo from '../images/logotext.png';

import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
// import "./Scroll.css";
import sofa from "../../images/sofa.png";
import ghar from "../../images/ghar.png";
import Dog from "../../images/Dog.png";
import bike from "../../images/bike.png";
import car from "../../images/car.png";
import electronics from "../../images/electronics.png";
import mobiles from "../../images/mobiles.png";
import Services from "../../images/Service1.jpg";
// import jobs from "../../images/jobs.png";
import { isMobile } from "react-device-detect";
// import { Services } from "../../env";

const HomeSection = () => {
  const dispatch = useDispatch();
  const handleClick = (category) => {
    const obj = { category, extrafiled: {} };
    dispatch(getExtraField(obj));
  };
  console.log(isMobile);

  const mobileStyle = {
    backgroundSize: "cover",
    borderRadius: "35px",
    width: "70px",
    height: "70px",
    marginLeft: "10px",
  };
  const desktopStyle = {
    backgroundSize: "cover",
    borderRadius: "20px",
    width: "130px",
    height: "100px",
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    // slidesToShow: 10,
    slidesToShow: 4,
    // slidesToScroll: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 8,
          slidesToShow: 5,
          // slidesToScroll: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 990,
        settings: {
          // slidesToShow: 8,
          slidesToShow: 4,
          // slidesToScroll: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          // slidesToShow: 8,
          slidesToShow: 3,
          // slidesToScroll: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <div className="d-lg-none hidden-md visible-xs-block visible-sm-block">
        <SearchForm />
      </div> */}
      <div className="mx-2 mt-1 hidden-sm hidden-xs visible-md-block visible-lg-block">
        <div
          id="carouselExampleFade"
          className="carousel"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner rounded">
            <div className="carousel-item active">
              <div className="row " style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #EAFFF4 100%) ', height: "auto" }}>
                <div className="col-12 col-md-5 mx-md-4  main-call ">
                  <div className="unlock-door">
                    <h1 className="">Unlock the Door to Your Next Adventure with Turn Keys.</h1>
                    <p>Turn Keys is not just a platform; it's a community of like-minded individuals sharing the joy of exploration and discovery. Your dream house swap is just a click away.</p>
                    <div>
                      <input type="text" placeholder="Enter City" className="form-control border-rounded" />
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-md-6  main-call-image">
                  <div className="clapingCpl">
                    <img src={clapingCpl} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "10px" }}>
        <div className="slider-container m-4 m-md-5 h-auto" style={{ marginBottom: "-40px", backgroundColor: "#FFFFFF", height: "auto" }}>
          <p
            className=" mt-4"
            style={{ fontSize: "15px" }}
          >
            Top trending home of the week!
          </p>
          <h4
            className="  "
            style={{ fontSize: "25px" }}
          >
            Have your eye on these most liked Home of the Week
          </h4>

          <Slider {...settings} className="  slider h-auto">
            <div className=" category_card">
              <Link to="/cat/RealEstate" title="Category RealEstates">
                <img
                  alt="imag"
                  src={ghar}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <div>
                  <p className='midile-box-button'>See This Property</p>
                </div>
              </Link>
            </div>

            <div className="category_card">
              <Link to="/cat/furniture" title="Category Furnitures">
                <img
                  alt="imag"
                  src={sofa}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>

            <div className="category_card">
              <Link to="/cat/Pets" title="Category Pets">
                <img
                  alt="imag"
                  src={Dog}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>

            <div className="category_card">
              <Link to="/cat/Bike" title="Category Bikes">
                <img
                  alt="imag"
                  src={bike}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>
            <div className="category_card">
              <Link to="/cat/Cars" title="Category Cars">
                <img
                  alt="imag"
                  src={car}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>
            <div className="category_card">
              <Link to="/cat/electronics" title="Category Electronics">
                <img
                  alt="imag"
                  src={electronics}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>

            <div className="category_card">
              <Link to="/cat/Mobiles" title="Category Mobiles">
                <img
                  alt="imag"
                  src={mobiles}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
                <p className='midile-box-button'>See This Property</p>
              </Link>
            </div>

            <div className="category_card">
              <Link to="/cat/Services" title="Category Services">
                <img
                  alt="imag"
                  src={Services}
                  style={isMobile ? mobileStyle : desktopStyle}
                  className="circleHover"
                />
              </Link>
            </div>
          </Slider>
        </div>
      </div>


      <div className="row border bg-dark mt-5 mb-5 ">
        <div className="col-5 test-img-box">
          <img src={test} className='test-img' />
        </div>
        <div className="col-4 test-midile-box">
          <p className='midile-box-para-top'>The smart way to Exchange Houses</p>
          <h2 className='midile-box-h2'>Ready to Swap <br /> Homes and <br /> Create Memories?</h2>
          <p className='midile-box-para-mid'>Become part of a community that values trust, diversity, and unforgettable experiences. Find your perfect match with detailed profiles and customizable search filters.</p>
          <p className='home-echange-btn'>I Want To Exchange My Home  </p>
        </div>
        <div className="col-3 test-right-box">
          <img src={line2} className='line-img-2' />
          <img src={line3} className='line-img-3' />
          <img src={line4} className='line-img-4' />
          <img src={line5} className='line-img-5' />
          <img src={line6} className='line-img-6' />
        </div>
      </div>
      {/* rectangle image section  */}
      <div className='row rectangle-box'>
        <div className="col-9 border">
          <img src={Rectangleimg} className='img-rentangle' alt="" />
        </div>
        <div className="col-3 border liked-property-box">
          <p className='liked-property'>Have a look on this most liked property in this month!</p>
          <p>Become part of a community that values trust, diversity, and unforgettable experiences. Find your perfect match with detailed profiles and customizable search .</p>
          <ul className='text-end'>
            <li>lorem ipsum date</li>
            <li>lorem ipsum</li>
            <li>pipsem</li>
            <li>pipsem-4</li>
          </ul>
          <p className='visit-this-property'>Visit this property now  </p>
        </div>
      </div>

      <div className=' setapart'>
        <h1>Features That Set Us Apart</h1>
        <div className="row  d-flex justify-content-center align-items-center">
          <div className="col-3  d-flex justify-content-center align-items-center">
            <img className='image-group-1' src={cuate} alt="" />
          </div>
          <div className="col-3  d-flex justify-content-center align-items-center">
            <img className='image-group' src={group22} alt="" />
          </div>
          <div className="col-3  d-flex justify-content-center align-items-center">
            <img className='image-group' src={pana} alt="" />
          </div>
        </div>
        <div className="row  d-flex justify-content-center align-items-center">
          <div className="col-3  ">
            <h4 className='text-center text-black'>Trusted and Secure</h4>
            <p className='text-center'>Your safety is our priority. Our rigorous identity verification process ensures that you can trust the Turn Keys community. Secure communication and payment systems provide peace of mind throughout the exchange process.</p>
          </div>
          <div className="col-3  ">
            <h4 className='text-center text-black'>Personalized Profiles</h4>
            <p className='text-center'>Craft detailed profiles that showcase your property and preferences. Use our customizable search filters to find the perfect match for your next house swap adventure.</p>
          </div>
          <div className="col-3  ">
            <h4 className='text-center text-black'>User-Friendly Onboarding</h4>
            <p className='text-center'>Our easy registration and identity verification process get you started in no time. Create your profile, add stunning photos, and begin exploring potential swaps effortlessly</p>
          </div>
        </div>
      </div>

      <div className='testimonial-box'>
        <p className='testimonialpara mb-4'>TESTIMONIALS</p>
        <h1 className='mb-5'>What Our Happy <br /> Customers Said.</h1>

        <div className="row gap-3 d-flex justify-content-center align-items-center">
          <div className="col-3 border p-4 test-sub-div">

            <p className='test-name'>Amit & Manjit</p>
            <p className='test-para'>"Turn Keys made our dream vacation a reality! Swapping homes with another family was not only cost-effective but also added a personal touch to our travels. We're now part of a community that values trust and shared experiences."</p>
            <div>
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i>
            </div>
          </div>
          <div className="col-3 border p-4 test-sub-div">
            <p className='test-name'>Ramesh & Binod</p>
            <p className='test-para'>"Our Turn Keys experience was fantastic. The platform's security measures gave us peace of mind, and connecting with fellow homeowners was seamless. We've made friends across borders, all thanks to Turn Keys!"</p>
            <div>
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star-half-stroke"></i>
            </div>
          </div>
          <div className="col-3 border p-4 test-sub-div">
            <p className='test-name'>Karan & Kumbh</p>
            <p className='test-para'>"As avid travelers, Turn Keys has become our go-to platform for unique accommodations. The personalized profiles helped us find the perfect match for our preferences. Our house swapping adventures have been nothing short of amazing."</p>
            <div>
              <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i class="fa-regular fa-star"></i>
            </div>
          </div>

        </div>
      </div>
      {/* <div
        className="d-lg-none hidden-md visible-xs-block visible-sm-block "
        style={{ marginTop: "-50px", marginBottom: "-5px" }}
      >
<h1>hello</h1>
      </div> */}
    </>
  );
};

export default HomeSection;
