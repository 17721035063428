import React from 'react'
// import PersonIcon from '@material-ui/icons/Person';
import { Person } from '@mui/icons-material';
// import ForumIcon from '@material-ui/icons/Forum';
import { Forum } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import { IconButton } from '@material-ui/core';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ArrowBack } from '@mui/icons-material';
import './Header.css'
import { Link, useNavigate } from 'react-router-dom';

function MatchHeader({ backButton }) {

    const navigate = useNavigate();

    return (
        <div className='header container-fluid'>
            {backButton ? (
                <IconButton
                    // onClick={() => history.replace(backButton)}
                    onClick={() => navigate(backButton)}
                >

                    <ArrowBack fontSize='large' />
                </IconButton>

            ) : (
                <IconButton>

                    <Person fontSize='large' />
                </IconButton>
            )}
            <Link to='/' className='fs-3 bold'>
                Rotate Keys
            </Link>
            <Link to='/chat'>
                <IconButton>
                    <Forum fontSize='large' />
                </IconButton>
            </Link>
        </div>
    )
}
// className='headerIcons'
export default MatchHeader