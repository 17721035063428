import React from "react";

import OrderPage from "./OrderPage";

import ListingEnquiries from "./ListingEnquiries";

import "./featu.css";

const Orders = () => {
  return (
    <div>
      <OrderPage />
     <ListingEnquiries />
    </div>
  );
};
export default Orders;
